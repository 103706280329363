// import React, {useEffect, useLayoutEffect, useState} from 'react';
// import PageWithSidebar from "../PageWithSidebar/PageWithSidebar";
// import CommonActivityModal from "../common/CommonActivityModal/CommonActivityModal";
// import ActivitiesGridForm from "../ActivitiesGridForm/ActivitiesGridForm";
// import PageTitle from "../common/PageTitle/PageTitle";
// import s from './OrganizationActivitiesGrid.module.scss'
// import {useAppDispatch, useAppSelector} from "../../app/hooks";
// import {
//     ActivitiesGridCheckIsPasswordNeededThunk,
//     ActivityPropertyType,
//     CreateNewActivityThunk,
//     GetActivitiesPropertiesThunk,
//     GetActivitiesRefLinksThunk,
//     GetActivitiesRequirements,
//     GetActivitiesSurfacesThunk,
//     onAddActivitiesReqsPropsSurfaces,
//     onChangeActivitiesGridColumnsPosition,
//     onChangeActivitiesGridColumnsWidth,
//     onChangeActivitiesGridMode,
//     onChangeActivitiesPropertiesSearchValue,
//     onChangeCurrentGridTab,
//     onCheckSurfaces,
//     onCheckSurfacesIds,
//     onDeleteCheckedProperty,
//     onDeleteCheckedReq,
//     onDeleteCheckedSurface,
//     onSetActivitiesCreationLocalSortModel,
//     onSetActivitiesCreationSortingString,
//     onSetActivitiesCurrentRow,
//     onSetActivitiesIsDataLoading,
//     onSetActivitiesIsPropsLoading,
//     onSetActivitiesIsSurfacesLoading,
//     onSetActivitiesProperties,
//     onSetActivitiesSurfacesUpdated,
//     onSetCurrentModalTab,
//     onSetIsDescriptionFieldError,
//     onSetIsSavingButtonDisabled,
//     selectActivitiesCheckedSurfaces,
//     selectActivitiesCheckedSurfacesIds,
//     selectActivitiesCreationCheckedReqIds,
//     selectActivitiesCreationGridHiddenColumns,
//     selectActivitiesCreationGridPropColumns,
//     selectActivitiesCreationGridPropRows,
//     selectActivitiesCreationGridReqColumns,
//     selectActivitiesCreationGridReqRows,
//     selectActivitiesCreationGridShownColumns,
//     selectActivitiesCreationGridSurfColumns,
//     selectActivitiesCreationGridSurfRows,
//     selectActivitiesCreationPropertiesSortModel,
//     selectActivitiesCreationReqsSortModel,
//     selectActivitiesCreationSurfacesSortModel,
//     selectActivitiesCurrentGridTab,
//     selectActivitiesCurrentRow,
//     selectActivitiesGetDataMode,
//     selectActivitiesIsDataLoading,
//     selectActivitiesIsPropsLoading,
//     selectActivitiesIsSurfacesLoading,
//     selectActivitiesKindNumberEntity,
//     selectActivitiesModalFormFields,
//     selectActivitiesPropertiesDataError,
//     selectActivitiesSearchValue,
//     selectActivitiesSurfacesDataError,
//     selectActivitiesTypeKind,
//     selectActivitiesTypeNumberEntity,
//     selectActivitiesTypes,
//     selectActivityEditMode,
//     selectActivityFormContacts,
//     selectActivityModalCurrentTab,
//     selectAllActivitiesEntitiesArray,
//     selectAllPropertiesShown,
//     selectAllSurfacesShown,
//     selectContactId,
//     selectCurrentActivitiesCheckedProperties,
//     selectCurrentActivitiesCheckedPropertiesIds,
//     selectCurrentActivityPropertiesAndSurfaces,
//     selectCurrentCompanyRef,
//     selectCurrentContactRef,
//     selectOrganizationId,
//     setActivitiesCreationGridHiddenColumns,
//     setActivitiesCreationGridShownColumns,
//     setActivitiesProperties,
//     setActivitiesPropertiesGridError, setActivitiesSurfaces,
//     setActivitiesSurfacesGridError,
//     setAllPropertiesShown,
//     setAllSurfacesShown,
//     UpdateExistingActivityThunk
// } from "../../store/activitesGrid";
// import {Button, Checkbox, Empty, Input, message, Select} from "antd";
// import commonS from "../RequirementsPage/CommonTableStyles.module.scss";
// import PropertiesSearchInput from "../PropertiesCommonComponents/PropertiesSearchInput/PropertiesSearchInput";
// import Box from "@mui/material/Box";
// import {DataGridPro, GridColumnMenu, GridRow, useGridApiRef} from "@mui/x-data-grid-pro";
// import {Lottie} from "@crello/react-lottie";
// import animatedLoader from "../../img/Loader.json";
// import {
//     onSetPropertiesGridLoaderMessage,
//     onSetReqGridHeight,
//     ResetAllGridConfigsThunk,
// } from "../../store/propertiesReducer";
// import {useNavigate} from "react-router";
// import {CreateAndUpdateActivityRequest, gridApi} from "../../app/api";
// import moment from "moment";
// import Tooltip from "../Tooltip/Tooltip";
// import {selectUserData} from "../../store/userReducer";
// import propertiesStyles from "../PropertiesPage/Properties.module.scss";
// import PropertiesGridFooter from "../PropertiesCommonComponents/PropertiesGridFooter/PropertiesGridFooter";
// import {FacebookCircularProgress} from "../AllPropertiesAddressForm/AllPropertiesAddressForm";
// import {ReactComponent as WarningActivityIcon} from "../../img/icons/alert-triangle.svg";
// import {
//     GetMapPropertiesStatusesThunk,
//     selectMapPropertiesStatuses
// } from "../../store/propertiesMap";
// import {customSortFunction, numericComparator} from "../../helpers/sortingDataHelper";
// import PropertiesViewOptions from "../PropertiesCommonComponents/PropertiesViewOptions/PropertiesViewOptions";
// import PropertiesHeaderButtons from "../PropertiesCommonComponents/PropertiesHeaderButtons/PropertiesHeaderButtons";
// import {selectCompany} from "../../store/companies";
// import PropertiesFilterButtons from "../PropertiesCommonComponents/PropertiesFilterButtons/PropertiesFilterButtons";
// import {
//     GetRequirementContactFunctionsThunk,
//     RequirementFunction,
//     selectRequirementFunctions
// } from "../../store/requirementsReducer";
//
//
// const OrganizationActivitiesGrid = () => {
//     return (
//         <PageWithSidebar>
//             <OrganizationActivitiesPageComponent1/>
//         </PageWithSidebar>
//     )
// }
//
// type TabName = "Properties" | "Surfaces" | "Reqs";
// type ActiveMode = {
//     gridType: TabName;
//     currentMode: string;
// }
//
//
// const OrganizationActivitiesPageComponent1 = () => {
//     const currentTab = useAppSelector(selectActivityModalCurrentTab)
//     const editMode = useAppSelector(selectActivityEditMode)
//     const currentRowValues = useAppSelector(selectActivitiesCurrentRow)
//     const [height, setHeight] = useState(window.innerHeight);
//     const [gridContentHeight, setGridContentHeight] = useState(height - 340);
//     const dispatch = useAppDispatch()
//     const [searchTerm, setSearchTerm] = useState('');
//     const [localSortModel, setLocalSortModel] = useState<any>({});
//     const modalFormFields = useAppSelector(selectActivitiesModalFormFields)
//     const navigate = useNavigate()
//     const isDataLoading = useAppSelector(selectActivitiesIsDataLoading)
//     const typeNumberEntity = useAppSelector(selectActivitiesTypeNumberEntity)
//     const kindNumberEntity = useAppSelector(selectActivitiesKindNumberEntity)
//     const contactId = useAppSelector(selectContactId)
//     const allActivitiesEntities = useAppSelector(selectAllActivitiesEntitiesArray)
//     const activitiesTypeKind = useAppSelector(selectActivitiesTypeKind)
//     const activitiesTypes = useAppSelector(selectActivitiesTypes)
//     const [loading, setIsLoading] = useState(false)
//     const userData = useAppSelector(selectUserData)
//     const currentGridTab = useAppSelector(selectActivitiesCurrentGridTab)
//     const reqColumns = useAppSelector(selectActivitiesCreationGridReqColumns)
//     const reqRows = useAppSelector(selectActivitiesCreationGridReqRows)
//     const propColumns = useAppSelector(selectActivitiesCreationGridPropColumns)
//     const propRows = useAppSelector(selectActivitiesCreationGridPropRows)
//     const surfColumns = useAppSelector(selectActivitiesCreationGridSurfColumns)
//     const surfRows = useAppSelector(selectActivitiesCreationGridSurfRows)
//     const gridColumns = currentGridTab === 'Reqs' ? reqColumns : currentGridTab === 'Properties' ? propColumns : surfColumns
//     const gridRows = currentGridTab === 'Reqs' ? reqRows : currentGridTab === 'Properties' ? propRows : surfRows
//     const checkedReqIds = useAppSelector(selectActivitiesCreationCheckedReqIds)
//     const propertiesAndSurfacesFromLinkData = useAppSelector(selectCurrentActivityPropertiesAndSurfaces)
//     const [popConfirmModalOpened, setPopConfirmModalOpened] = useState(false)
//     const isPropsLoading = useAppSelector(selectActivitiesIsPropsLoading)
//     const isSurfacesLoading = useAppSelector(selectActivitiesIsSurfacesLoading)
//     const organizationId = useAppSelector(selectOrganizationId)
//     const companyRefForEdit = useAppSelector(selectCurrentCompanyRef)
//     const companyRef = editMode ? companyRefForEdit : organizationId
//     const allPropertiesShown = useAppSelector(selectAllPropertiesShown)
//     const allSurfacesShown = useAppSelector(selectAllSurfacesShown)
//     const checkedSurfaceIds = useAppSelector(selectActivitiesCheckedSurfacesIds)
//     const checkedPropertiesIds = useAppSelector(selectCurrentActivitiesCheckedPropertiesIds)
//     const mapStatuses = useAppSelector(selectMapPropertiesStatuses)
//     const shownColumns = useAppSelector(selectActivitiesCreationGridShownColumns)
//     const hiddenColumns = useAppSelector(selectActivitiesCreationGridHiddenColumns)
//     const [currentStatus, setCurrentStatus] = useState(1)
//     const [isProcessing, setIsProcessing] = useState(false);
//     const [checked, setChecked] = useState<any>([]);
//     const [filtersShown, setFiltersShown] = useState(false)
//     const reqsSortModel = useAppSelector(selectActivitiesCreationReqsSortModel)
//     const propertiesSortModel = useAppSelector(selectActivitiesCreationPropertiesSortModel)
//     const surfacesSortModel = useAppSelector(selectActivitiesCreationSurfacesSortModel)
//     const sortModel = currentGridTab === 'Reqs' ? reqsSortModel : currentGridTab === 'Properties' ? propertiesSortModel : surfacesSortModel
//     const activitiesModes = useAppSelector(selectActivitiesGetDataMode)
//     const currentContactRef = useAppSelector(selectCurrentContactRef)
//     const reqFunctions = useAppSelector(selectRequirementFunctions)
//     const [activeMode, setActiveMode] = useState<ActiveMode | null>(null);
//     const [propertiesCurrentFunc, setPropertiesCurrentFunc] = useState<any>()
//     const [surfacesCurrentFunc, setSurfacesCurrentFunc] = useState<any>()
//     const propertiesSearchValue = useAppSelector(selectActivitiesSearchValue)
//     const activityPropertiesGridError = useAppSelector(selectActivitiesPropertiesDataError)
//     const activitySurfacesGridError = useAppSelector(selectActivitiesSurfacesDataError)
//
//     useEffect(() => {
//         if(currentGridTab === 'Reqs'){
//                 dispatch(GetActivitiesRequirements({
//                     p_agent_ref: Number(userData.user_ref),
//                     p_sort_order: null
//                 }))
//         }
//         dispatch(GetRequirementContactFunctionsThunk())
//        // else if(currentGridTab === 'Properties'){
//        //      if(activitiesModes[0].currentMode === 1){
//        //              dispatch(GetActivitiesPropertiesThunk({
//        //                  reqData: {
//        //                      p_agent_ref: Number(userData.user_ref),
//        //                      p_contact_ref: currentContactRef,
//        //                      p_sort_order: null,
//        //                      p_status_idx: Number(currentStatus),
//        //                      p_function_idx: propertiesCurrentFunc && propertiesCurrentFunc !== null && propertiesCurrentFunc !== 'All' ? Number(propertiesCurrentFunc) : null
//        //                  }, allPropertiesShown
//        //              }))
//        //          dispatch(GetRequirementContactFunctionsThunk())
//        //      }
//        //  }
//        //  else if(currentGridTab === 'Surfaces'){
//        //      if(activitiesModes[1].currentMode === 1){
//        //              dispatch(GetActivitiesSurfacesThunk({
//        //                  reqData: {
//        //                      p_agent_ref: Number(userData.user_ref),
//        //                      p_contact_ref: currentContactRef,
//        //                      p_sort_order: null,
//        //                      p_status_idx: currentStatus,
//        //                      p_function_idx: surfacesCurrentFunc && surfacesCurrentFunc !== null && surfacesCurrentFunc !== 'All' ? Number(surfacesCurrentFunc) : null
//        //                  }, allSurfacesShown
//        //              }))
//        //          dispatch(GetRequirementContactFunctionsThunk())
//        //      }
//        //      // else{
//        //      //     dispatch(GetActivitiesSurfacesThunk({
//        //      //         reqData: {
//        //      //             p_agent_ref: Number(userData.user_ref),
//        //      //             p_sort_order: null,
//        //      //             p_status_idx: currentStatus,
//        //      //         }, allSurfacesShown
//        //      //     }))
//        //      // }
//        //  }
//     }, [userData, currentGridTab, dispatch])
//
//     useEffect(() => {
//         dispatch(ActivitiesGridCheckIsPasswordNeededThunk({
//             list_code: 'activities',
//             operation: 'D',
//             password: 'act999'
//
//         }))
//     }, [dispatch])
//     let columns = gridColumns.map((g: any) => {
//         if (g.COL_NAME === "REQ_REF") {
//             return {
//                 field: g.COL_NAME,
//                 headerName: g.COL_TITLE,
//                 width: g.COL_WIDTH,
//                 dataType: g.COL_TYPE,
//                 col_pos: 1,
//                 col_value: g.COL_VALUE,
//                 col_options: g.COL_OPTIONS,
//             }
//         } else if (g.COL_NAME === "SURFACE_EXISTED") {
//             return {
//                 field: g.COL_NAME,
//                 headerName: g.COL_TITLE,
//                 width: 0,
//                 dataType: g.COL_TYPE,
//                 col_pos: 0,
//                 col_value: g.COL_VALUE,
//                 col_options: g.COL_OPTIONS,
//             }
//         } else {
//             return {
//                 field: g.COL_NAME,
//                 headerName: g.COL_TITLE,
//                 width: g.COL_NAME === 'CONTACT_LIST' ? 1 : g.COL_WIDTH,
//                 dataType: g.COL_TYPE,
//                 col_pos: g.COL_POS,
//                 col_value: g.COL_VALUE,
//                 col_options: g.COL_OPTIONS,
//             }
//         }
//
//     }).sort((a, b) => a.col_pos - b.col_pos)
//
//     const handleSearchInputChange = (event: any) => {
//         setSearchTerm(event.target.value);
//     };
//     const getRowClassName = (params: any) => {
//         return (params.id % 2 === 0) ? 'even-row' : 'odd-row';
//     };
//     const apiRef = useGridApiRef();
//
//
//     const debounce = (func: Function, wait: number) => {
//         let timeout: ReturnType<typeof setTimeout> | null;
//         return function (this: any, ...args: any[]) {
//             const context = this;
//             clearTimeout(timeout!);
//             timeout = setTimeout(() => func.apply(context, args), wait);
//         };
//     };
//
//     useLayoutEffect(() => {
//         const handleResize = debounce(function () {
//             setHeight(window.innerHeight);
//             const heightForPage = window.innerHeight;
//             setGridContentHeight(heightForPage - 340);
//             dispatch(onSetReqGridHeight(heightForPage))
//         }, 100);
//
//         window.addEventListener("resize", handleResize);
//         return () => window.removeEventListener("resize", handleResize);
//     }, []);
//
//
//     let sortedRows = customSortFunction(gridRows, sortModel);
//
//     const handleSortModelChange = (newModel: any) => {
//         const newLocalModel: any = {...localSortModel};
//         newModel.forEach((model: any) => {
//             newLocalModel[model.field] = model;
//         });
//         setLocalSortModel(newLocalModel);
//         const mergedArray = [...sortModel, ...newModel];
//
//         // Find the index of the matching field object in the merged array
//         const index = mergedArray.findIndex(item => item?.field === sortModel[0]?.field);
//
//         // Check if the sort value is different and update it
//         if (index !== -1 && mergedArray[index]?.sort !== newModel[0]?.sort) {
//             // eslint-disable-next-line
//             const updatedObject = {
//                 ...mergedArray[index],
//                 sort: newModel[0]?.sort
//             };
//             mergedArray[index] = updatedObject;
//         }
//
//         dispatch(onSetActivitiesCreationLocalSortModel({sort_model: mergedArray, gridType: currentGridTab}))
//     };
//
//
//     useEffect(() => {
//         if (editMode) {
//             dispatch(GetActivitiesRefLinksThunk(currentRowValues.REF!))
//         }
//     }, [dispatch, editMode, currentRowValues.REF!])
//     const onCloseModal = () => {
//         navigate('/activitiesGrid')
//     }
//
//     const onCreateNewActivity = (createWithoutProp?: boolean) => {
//         if (isProcessing) return;
//         setIsProcessing(true);
//         const kindNumberEntityForEdit = activitiesTypeKind.find((t) => t.VALUE === modalFormFields.kind)
//         const maxListLength = checkedPropertiesIds.length + checkedSurfaceIds.length + checkedReqIds.length;
//         const typeNumberEntityForEdit = activitiesTypes.find((t) => t.AE_LI_VALUE === modalFormFields.type)
//         const link_type_list: any = []
//         // const link_entity_list = !editMode ? [] : allActivitiesEntities.map((c: any) => c.link_entity_list).filter(item => !checkedPropertiesIds.includes(item) && !checkedSurfaceIds.includes(item) && !checkedReqIds.includes(item))
//         const link_entity_list: any = []
//         let link_ref_list = !editMode ? Array.from({length: maxListLength + 2}, () => null) : allActivitiesEntities.filter((i: any) => i.link_type_list !== 2 || i.link_type_list !== 3).map((c: any) => c.link_ref_list)
//         const orgAndContactIds = allActivitiesEntities.filter((c: any) => c.link_type_list === 3 || c.link_type_list === 2)
//         const refListEntityIds = allActivitiesEntities.filter((i: any) => i.link_type_list === 2 || i.link_type_list === 3).map((c: any) => c.link_ref_list)
//         for (let i = 0; i < maxListLength; i++) {
//             if (i < checkedReqIds.length) {
//                 link_type_list.unshift(1);
//             }
//             if (i < checkedPropertiesIds.length) {
//                 link_type_list.push(0);
//             }
//             if (i < checkedSurfaceIds.length) {
//                 link_type_list.push(14);
//             }
//         }
//         for (let i = 0; i < maxListLength; i++) {
//             if (i < checkedReqIds.length) {
//                 link_entity_list.unshift(checkedReqIds[i]);
//             }
//             if (i < checkedPropertiesIds.length) {
//                 link_entity_list.push(checkedPropertiesIds[i]);
//             }
//             if (i < checkedSurfaceIds.length) {
//                 link_entity_list.push(checkedSurfaceIds[i]);
//             }
//         }
//         if (!editMode) {
//             link_type_list.unshift(2, 3)
//             link_entity_list.unshift(organizationId, contactId)
//         } else {
//             const originalEntityList = allActivitiesEntities.filter((i: any) => i.link_type_list !== 2 || i.link_type_list !== 3).map(item => item.link_entity_list)
//             let newRefList: any = [];
//             link_entity_list.forEach((entity: any) => {
//                 const index = originalEntityList.indexOf(entity);
//                 if (index !== -1) {
//                     // If the entity exists in the original list, use its original ref_list value
//                     newRefList.push(link_ref_list[index]);
//                 } else {
//                     // If the entity is new (not in the original list), push null
//                     newRefList.push(null);
//                 }
//             });
//
//             // Assign the newly built list to refList
//             link_ref_list = newRefList;
//             // link_type_list.unshift(2, 3)
//             // orgNadCompanyId.forEach((id: number) => {
//             //     link_entity_list.unshift(id)
//             //     console.log(id)
//             // })
//             orgAndContactIds.forEach((orgOrContact: any) => {
//                 if(orgOrContact.link_type_list === 3){
//                     link_entity_list.unshift(orgOrContact.link_entity_list)
//                     link_type_list.unshift(3)
//                 }
//                 else if(orgOrContact.link_type_list === 2){
//                     link_entity_list.unshift(orgOrContact.link_entity_list)
//                     link_type_list.unshift(2)
//                 }
//                 })
//             console.log(refListEntityIds)
//             refListEntityIds.forEach((id: number) => {
//                 console.log(id)
//                 link_ref_list.unshift(id)
//             })
//         }
//         dispatch(onSetIsSavingButtonDisabled(true))
//         setIsLoading(true)
//         const req: CreateAndUpdateActivityRequest = {
//             date_begin: moment(modalFormFields.date_from),
//             date_end: moment(modalFormFields.date_till),
//             description: modalFormFields.description !== '' ? modalFormFields.description : '',
//             link_entity_list: link_entity_list,
//             link_ref_list: link_ref_list,
//             link_type_list: link_type_list,
//             priority: modalFormFields.urgency === 'low' ? 0 : modalFormFields.urgency === 'avg' ? 1 : 2,
//             remark: modalFormFields.remark !== '' ? modalFormFields.remark : '',
//             type: !editMode ? typeNumberEntity : typeNumberEntityForEdit?.AE_NO_INDEX,
//             type_kind: !editMode ? kindNumberEntity : kindNumberEntityForEdit?.CODE
//         }
//         setTimeout(() => {
//             const kindNumberEntityForEdit = activitiesTypeKind.find((t) => t.VALUE === modalFormFields.kind)
//             const isPropRequired = activitiesTypeKind && activitiesTypeKind.find((a: any) => Number(a.CODE!) === (!editMode ? Number(kindNumberEntity) : Number(kindNumberEntityForEdit?.CODE)))
//             if (isPropRequired?.PROP_REQUIRED === 0) {
//                 if (modalFormFields.description !== '') {
//                     dispatch(onSetIsSavingButtonDisabled(true))
//                     setIsLoading(true)
//                     if (!editMode) {
//                         dispatch(CreateNewActivityThunk({activityData: req}))
//                     } else {
//                         dispatch(UpdateExistingActivityThunk({requestData: req, reqRef: currentRowValues.REF!}))
//                     }
//                 } else {
//                     dispatch(onSetIsDescriptionFieldError(true))
//                     dispatch(onSetCurrentModalTab('General'))
//                     setTimeout(() => {
//                         dispatch(onSetIsSavingButtonDisabled(false))
//                         setIsLoading(false)
//                     }, 50)
//
//                 }
//             } else {
//                 if (!checkedPropertiesIds.length && !checkedSurfaceIds.length) {
//                     message.warn('You need to select at least one property or surface in order to create activity with this type', 10)
//                     setIsLoading(false)
//                 } else {
//                     if (modalFormFields.description !== '') {
//                         dispatch(onSetIsSavingButtonDisabled(true))
//                         setIsLoading(true)
//                         if (!editMode) {
//                             dispatch(CreateNewActivityThunk({activityData: req}))
//                         } else {
//                             dispatch(UpdateExistingActivityThunk({requestData: req, reqRef: currentRowValues.REF!}))
//                         }
//                     } else {
//                         dispatch(onSetIsDescriptionFieldError(true))
//                         dispatch(onSetCurrentModalTab('General'))
//                         setTimeout(() => {
//                             dispatch(onSetIsSavingButtonDisabled(false))
//                             setIsLoading(false)
//                         }, 50)
//                     }
//                 }
//             }
//             setIsProcessing(false);
//         }, 100);
//
//
//     }
//
//
//     const handlePageChange = (params: any, value: number) => {
//         setPage(value);
//         afterPaginationPageChangeScrollToTop();
//     }
//
//     const afterPaginationPageChangeScrollToTop = () => {
//         const gridApi = apiRef.current;
//         if (!gridApi) return;
//         gridApi.scrollToIndexes({rowIndex: 0});
//     };
//
//     function handleRowsPerPageChange(event: any) {
//         setRowsPerPage(event);
//     }
//
//     const onChangeGridTab = (gridType: 'Reqs' | 'Properties' | 'Surfaces') => {
//         setLocalSortModel({})
//         dispatch(onChangeCurrentGridTab(gridType))
//
//     }
//
//     const rows = sortedRows.reduce((acc, row) => {
//         const values = Object.values(row);
//         const useStartsWith = searchTerm.endsWith('%');
//         const processedSearchTerm = searchTerm.slice(0, -1).toLowerCase();
//         if (values.some(value => {
//             const stringValue = String(value).toLowerCase();
//             return useStartsWith ? stringValue.startsWith(processedSearchTerm) : stringValue.includes(searchTerm.toLowerCase());
//         })) {
//             acc.push(row);
//         }
//         return acc;
//     }, []);
//
//     const rowsPerPageOptions = [50, 100, 150, 200, 500];
//     const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[2]);
//     const [page, setPage] = React.useState(1);
//     const isActivityTypeKindWithRightValue = activitiesTypeKind && activitiesTypeKind.some((a: any) => a.VALUE! === currentRowValues.ACT_KIND || a.VALUE! === modalFormFields.kind)
//
//     let gridRowsPaginated = rows.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);
//
//     useEffect(() => {
//         if (propertiesAndSurfacesFromLinkData.length) {
//             const properties: ActivityPropertyType[] = []
//             const surfaces: ActivityPropertyType[] = []
//             propertiesAndSurfacesFromLinkData.forEach((obj: any) => {
//                 if (obj?.entityType === 'property') {
//                     const matchedItem = propRows.find((p: any) => Number(obj?.property_id) === Number(p.PROP_REF) && Number(obj?.req_id) === Number(p.REQ_REF))
//                     if (matchedItem) {
//                         properties.push({...matchedItem, entityListEntityId: obj.entityId})
//                     }
//                 } else {
//                     const matchedItem = surfRows.find((p: any) => Number(obj?.property_id) === Number(p.SURF_REF) && Number(obj?.req_id) === Number(p.REQ_REF))
//
//                     if (matchedItem) {
//                         surfaces.push({...matchedItem, entityListEntityId: obj.entityId})
//                     }
//                 }
//             })
//             dispatch(onSetActivitiesProperties(properties))
//             dispatch(onSetActivitiesSurfacesUpdated(surfaces))
//         }
//     }, [propertiesAndSurfacesFromLinkData, propRows, surfRows])
//
//
//     const onRowClick = (row: any) => {
//         if (row.row.REF !== null && !editMode && currentGridTab === 'Reqs') {
//             dispatch(onSetActivitiesCurrentRow(row.row))
//             navigate('/organizationActivitiesProperties')
//         }
//     }
//
//     const onSwitchChange = (value: boolean, type: 'Property' | 'Surface') => {
//         if (type === 'Property') {
//             dispatch(setAllPropertiesShown(value))
//         } else {
//             dispatch(setAllSurfacesShown(value))
//         }
//
//     }
//
//     const selectMapStatuses = mapStatuses.map((status: any) => ({
//         value: `${status.AE_NO_INDEX} `,
//         label: `${status.AE_LI_VALUE}`,
//     })).concat({value: `All`, label: `All`,})
//
//
//
//     const selectReqFunctions = reqFunctions.map((func: RequirementFunction) => ({
//         value: `${func.AE_NO_INDEX} `,
//         label: `${func.AE_LI_VALUE}`,
//     })).concat({value: `All`, label: `All`,})
//
//     useEffect(() => {
//         dispatch(GetMapPropertiesStatusesThunk())
//     }, [])
//
//     const handleStatusChange = (value: string) => {
//         setCurrentStatus(Number(value))
//         if(currentGridTab === 'Properties'){
//             if(propertiesSearchValue !== null && propertiesSearchValue !== ''){
//                 dispatch(GetActivitiesPropertiesThunk({
//                     reqData: {
//                         p_agent_ref: Number(userData.user_ref),
//                         p_sort_order: null,
//                         p_status_idx: Number(value),
//                         p_street_address: propertiesSearchValue!.toLowerCase().trim()
//                     }, allPropertiesShown
//                 }))
//             }
//         }
//         else if(currentGridTab === 'Surfaces'){
//             if(propertiesSearchValue !== null && propertiesSearchValue !== ''){
//                 dispatch(GetActivitiesSurfacesThunk({
//                     reqData: {
//                         p_agent_ref: Number(userData.user_ref),
//                         p_sort_order: null,
//                         p_status_idx: Number(value),
//                         p_street_address: propertiesSearchValue!.toLowerCase().trim()
//                     }, allSurfacesShown
//                 }))
//             }
//         }
//     }
//
//     const handleFunctionChange = (value: string) => {
//         if(currentGridTab === 'Properties'){
//             setPropertiesCurrentFunc(value)
//         }
//         else if(currentGridTab === 'Surfaces'){
//             setSurfacesCurrentFunc(value)
//         }
//     }
//
//     useEffect(() => {
//         const sortModelObject: any = {};
//         for (const item of sortModel) {
//             sortModelObject[item.field] = {
//                 field: item.field,
//                 sort: item.sort
//             };
//         }
//         setLocalSortModel(sortModelObject)
//     }, [sortModel])
//
//     // CONTACTS
//     // REQ_REFS
//     useEffect(() => {
//         if (columns.length) {
//             dispatch(setActivitiesCreationGridShownColumns({
//                 columns: columns.filter((c: any) => c?.width !== 0).map((c: any, index: number) => {
//                     if (c.field === 'ACTIVITY_EXISTED') {
//                         return {
//                             field: 'ACTIVITY_EXISTED',
//                             headerName: 'activity',
//                             width: 100,
//                             checked: false,
//                             id: index,
//                             dataType: "class java.lang.String",
//                             isFilterSelected: false,
//                             col_pos: 2,
//                             col_value: c.col_value,
//                             col_options: c.col_options,
//                             withSurface: c.withSurface,
//                             key: index,
//                         }
//                     } else if (c.field === 'SURFACE_EXISTED') {
//                         return {
//                             field: 'SURFACE_EXISTED',
//                             headerName: 'SURFACE_EXISTED',
//                             width: 0,
//                             checked: false,
//                             id: index,
//                             dataType: c.dataType,
//                             isFilterSelected: false,
//                             col_pos: -1,
//                             col_value: c.col_value,
//                             col_options: c.col_options,
//                             withSurface: c.withSurface,
//                             key: index
//                         }
//                     } else if (c.field === 'CONTACTS' && (allSurfacesShown || allPropertiesShown)) {
//                         return {
//                             field: 'CONTACTS',
//                             headerName: 'CONTACTS',
//                             width: 0,
//                             checked: false,
//                             id: index,
//                             dataType: c.dataType,
//                             isFilterSelected: false,
//                             col_pos: -1,
//                             col_value: c.col_value,
//                             col_options: c.col_options,
//                             withSurface: c.withSurface,
//                             key: index,
//                             hide: true
//                         }
//                     } else if (c.field === 'REQ_REFS' && (allSurfacesShown || allPropertiesShown)) {
//                         return {
//                             field: 'REQ_REFS',
//                             headerName: 'REQ_REFS',
//                             width: 0,
//                             checked: false,
//                             id: index,
//                             dataType: c.dataType,
//                             isFilterSelected: false,
//                             col_pos: -1,
//                             col_value: c.col_value,
//                             col_options: c.col_options,
//                             withSurface: c.withSurface,
//                             key: index,
//                             hide: true
//                         }
//                     } else if (c.field !== 'ACTIVITY_EXISTED') {
//                         if (c.dataType === 'class java.math.BigDecimal') {
//                             return {
//                                 field: c.field,
//                                 headerName: c.headerName,
//                                 width: c.width,
//                                 checked: false,
//                                 id: index,
//                                 dataType: c.dataType,
//                                 isFilterSelected: false,
//                                 col_pos: c.col_pos,
//                                 col_value: c.col_value,
//                                 col_options: c.col_options,
//                                 withSurface: c.withSurface,
//                                 key: index,
//                                 sortComparator: numericComparator
//                             }
//                         } else if (c.field === 'ADDR_LONGI' || c.field === 'ADDR_LATI') {
//                             return {
//                                 field: c.field,
//                                 headerName: c.headerName,
//                                 width: c.width,
//                                 checked: false,
//                                 id: index,
//                                 dataType: c.dataType,
//                                 isFilterSelected: false,
//                                 col_pos: columns[columns.length - 1],
//                                 col_value: c.col_value,
//                                 col_options: c.col_options,
//                                 withSurface: c.withSurface,
//                                 key: index
//                             }
//                         } else {
//                             return {
//                                 field: c.field,
//                                 headerName: c.headerName,
//                                 width: c.width,
//                                 checked: false,
//                                 id: index,
//                                 dataType: c.dataType,
//                                 isFilterSelected: false,
//                                 col_pos: c.col_pos,
//                                 col_value: c.col_value,
//                                 col_options: c.col_options,
//                                 withSurface: c.withSurface,
//                                 key: index,
//                             }
//                         }
//
//                     }
//
//                 })
//             }))
//             dispatch(setActivitiesCreationGridHiddenColumns({
//                 columns: columns.filter((c: any) => c?.width === 0).map((c: any, index: number) => {
//                     if (c.field === 'ACTIVITY_EXISTED') {
//                         return {
//                             field: 'ACTIVITY_EXISTED',
//                             headerName: 'activity',
//                             width: 0,
//                             checked: false,
//                             id: index,
//                             dataType: "class java.lang.String",
//                             isFilterSelected: false,
//                             col_pos: 1,
//                             col_value: c.col_value,
//                             col_options: c.col_options,
//                             withSurface: c.withSurface,
//                             key: index,
//                         }
//                     } else if (c.field === 'SURFACE_EXISTED') {
//                         return {
//                             field: 'SURFACE_EXISTED',
//                             headerName: 'SURFACE_EXISTED',
//                             width: 0,
//                             checked: false,
//                             id: index,
//                             dataType: c.dataType,
//                             isFilterSelected: false,
//                             col_pos: -1,
//                             col_value: c.col_value,
//                             col_options: c.col_options,
//                             withSurface: c.withSurface,
//                             key: index
//                         }
//                     } else if (c.field === 'CONTACTS' && (allSurfacesShown || allPropertiesShown)) {
//                         return {
//                             field: 'CONTACTS',
//                             headerName: 'CONTACTS',
//                             width: 0,
//                             checked: false,
//                             id: index,
//                             dataType: c.dataType,
//                             isFilterSelected: false,
//                             col_pos: -1,
//                             col_value: c.col_value,
//                             col_options: c.col_options,
//                             withSurface: c.withSurface,
//                             key: index,
//                             hide: true
//                         }
//                     } else if (c.field === 'REQ_REFS' && (allSurfacesShown || allPropertiesShown)) {
//                         return {
//                             field: 'REQ_REFS',
//                             headerName: 'REQ_REFS',
//                             width: 0,
//                             checked: false,
//                             id: index,
//                             dataType: c.dataType,
//                             isFilterSelected: false,
//                             col_pos: -1,
//                             col_value: c.col_value,
//                             col_options: c.col_options,
//                             withSurface: c.withSurface,
//                             key: index,
//                             hide: true
//                         }
//                     } else if (c.field === 'SURFACE_EXISTED') {
//                         return {
//                             field: 'SURFACE_EXISTED',
//                             headerName: 'SURFACE_EXISTED',
//                             width: 0,
//                             checked: false,
//                             id: index,
//                             dataType: c.dataType,
//                             isFilterSelected: false,
//                             col_pos: -1,
//                             col_value: c.col_value,
//                             col_options: c.col_options,
//                             withSurface: c.withSurface,
//                             key: index
//                         }
//                     } else if (c.dataType === 'class java.math.BigDecimal') {
//                         return {
//                             field: c.field,
//                             headerName: c.headerName,
//                             width: 0,
//                             checked: false,
//                             id: index,
//                             dataType: c.dataType,
//                             isFilterSelected: false,
//                             col_pos: c.col_pos,
//                             col_value: c.col_value,
//                             col_options: c.col_options,
//                             withSurface: c.withSurface,
//                             key: index,
//                             sortComparator: numericComparator
//                         }
//                     } else {
//                         return {
//                             field: c.field,
//                             headerName: c.headerName,
//                             width: 0,
//                             checked: false,
//                             id: index,
//                             dataType: c.dataType,
//                             isFilterSelected: false,
//                             col_pos: c.col_pos,
//                             col_value: c.col_value,
//                             col_options: c.col_options,
//                             withSurface: c.withSurface,
//                             key: index,
//                         }
//                     }
//                 })
//             }))
//             setChecked(columns.filter((c: any) => c?.width === 0).map((c: any) => c.field))
//         }
//
//         // eslint-disable-next-line
//     }, [gridColumns, dispatch])
//
//
//     const onResetConfigs = () => {
//         dispatch(setActivitiesCreationGridShownColumns({columns: []}))
//         dispatch(onSetActivitiesIsDataLoading(true))
//         setSearchTerm('')
//         setLocalSortModel({})
//         if (currentGridTab === 'Reqs') {
//             dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Reqs'}))
//             dispatch(onSetActivitiesCreationSortingString({sorting_string: null, gridType: 'Reqs'}))
//             dispatch(onSetActivitiesIsDataLoading(true))
//         } else if (currentGridTab === 'Properties') {
//             dispatch(onSetActivitiesCreationSortingString({sorting_string: null, gridType: 'Properties'}))
//             dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Properties'}))
//             dispatch(onSetActivitiesIsPropsLoading(true))
//         } else {
//             dispatch(onSetActivitiesCreationSortingString({sorting_string: null, gridType: 'Surfaces'}))
//             dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Surfaces'}))
//             dispatch(onSetActivitiesIsSurfacesLoading(true))
//         }
//         dispatch(ResetAllGridConfigsThunk({
//             p_agent_ref: Number(userData.user_ref),
//             p_row_req: null,
//             grid_type: currentGridTab === 'Reqs' ? 'ACTIVITY_REQ_DATA' : currentGridTab === 'Properties' ? 'ACTIVITY_PROP_DATA' : 'ACTIVITY_SURF_DATA'
//         }))
//             .then(() => {
//                 if (currentGridTab === 'Reqs') {
//                     if (companyRef) {
//                         dispatch(GetActivitiesRequirements({
//                             p_agent_ref: Number(userData.user_ref),
//                             p_company_ref: companyRef,
//                             p_sort_order: null
//                         }))
//
//                     } else {
//                         dispatch(GetActivitiesRequirements({
//                             p_agent_ref: Number(userData.user_ref),
//                             p_company_ref: null,
//                             p_sort_order: null
//                         }))
//                     }
//                 } else if (currentGridTab === 'Properties') {
//                     if (!allPropertiesShown && companyRef) {
//                         dispatch(GetActivitiesPropertiesThunk({
//                             reqData: {
//                                 p_agent_ref: Number(userData.user_ref),
//                                 p_company_ref: companyRef,
//                                 p_sort_order: null,
//                                 p_status_idx: Number(currentStatus)
//                             }, allPropertiesShown
//                         }))
//                     } else if (allPropertiesShown && allPropertiesShown !== null) {
//                         dispatch(GetActivitiesPropertiesThunk({
//                             reqData: {
//                                 p_agent_ref: Number(userData.user_ref),
//                                 p_company_ref: null,
//                                 p_sort_order: null,
//                                 p_status_idx: Number(currentStatus)
//                             }, allPropertiesShown
//                         }))
//
//                     }
//                 } else {
//
//                     if (!allSurfacesShown && companyRef) {
//                         dispatch(GetActivitiesSurfacesThunk({
//                             reqData: {
//                                 p_agent_ref: Number(userData.user_ref),
//                                 p_company_ref: companyRef,
//                                 p_sort_order: null,
//                                 p_status_idx: currentStatus
//                             }, allSurfacesShown
//                         }))
//                     } else if (allSurfacesShown && allSurfacesShown !== null) {
//                         dispatch(GetActivitiesSurfacesThunk({
//                             reqData: {
//                                 p_agent_ref: Number(userData.user_ref),
//                                 p_company_ref: null,
//                                 p_sort_order: null,
//                                 p_status_idx: currentStatus
//                             }, allSurfacesShown
//                         }))
//                     }
//
//
//                 }
//             })
//         dispatch(onSetPropertiesGridLoaderMessage('Reset data is in progress'))
//     }
//     const onSaveGridConfigs = () => {
//         // dispatch(onSetActivitiesIsDataLoading(true))
//         let hiddenColumnsResult;
//         let shownColumnsResult;
//         if (hiddenColumns.length) {
//             hiddenColumnsResult = hiddenColumns.map((yItem: any, index: number) => {
//                 let xMatch = gridColumns.find((xItem: any) => xItem.headerName === yItem.headerName);
//                 // @ts-ignore
//                 xMatch = {...xMatch, COL_WIDTH: 0, COL_POS: shownColumns.length + index}
//                 return xMatch ? {...yItem, ...xMatch} : yItem;
//             });
//         } else if (!hiddenColumns.length) {
//             hiddenColumnsResult = []
//         }
//         if (shownColumns.length) {
//             shownColumnsResult = shownColumns.map((yItem: any) => {
//                 let xMatch = gridColumns.find((xItem: any) => xItem.headerName === yItem.headerName);
//                 // @ts-ignore
//                 xMatch = {...xMatch, COL_WIDTH: yItem.width}
//                 return xMatch ? {...yItem, ...xMatch} : yItem;
//             });
//         } else if (!shownColumns.length) {
//             shownColumnsResult = shownColumns
//         }
//         const shownColumnsResultFinal = shownColumnsResult?.map((g: any, index: number) => {
//             return {
//                 COL_NAME: g.field,
//                 COL_OPTIONS: g.col_options,
//                 COL_POS: index,
//                 COL_TITLE: g.headerName,
//                 COL_TYPE: g.dataType,
//                 COL_VALUE: g.col_value,
//                 COL_WIDTH: g.COL_WIDTH
//             }
//         })
//         const hiddenColumnsResultFinal = hiddenColumnsResult?.map((g: any) => {
//             return {
//                 COL_NAME: g.field,
//                 COL_OPTIONS: g.col_options,
//                 COL_POS: g.COL_POS,
//                 COL_TITLE: g.headerName,
//                 COL_TYPE: g.dataType,
//                 COL_VALUE: g.col_value,
//                 COL_WIDTH: g.COL_WIDTH
//             }
//         })
//
//         function mergeArrays(hiddenColumns: any, shownColumns: any) {
//             const result = [];
//             for (const hiddenColumn of hiddenColumns) {
//                 const matchingObjS = shownColumns.find((objS: any) => objS.COL_NAME === hiddenColumn.COL_NAME);
//                 if (matchingObjS) {
//                     hiddenColumn.COL_WIDTH = matchingObjS.COL_WIDTH;
//                 }
//                 result.push(hiddenColumn);
//             }
//             for (const shownColumn of shownColumns) {
//                 if (!result.find(obj => obj.COL_NAME === shownColumn.COL_NAME)) {
//                     result.push(shownColumn);
//                 }
//             }
//             return result;
//         }
//
//         const sortConditionStrArray = Object.keys(localSortModel).map(key => {
//             // @ts-ignore
//             return {"field": localSortModel[key].field, "sort": localSortModel[key].sort};
//         });
//         const sortConditionStr = sortConditionStrArray?.map((item: any) => `${item.field} ${item.sort.toUpperCase()}`).join(', ');
//         const req = {
//             p_agent_ref: Number(userData.user_ref),
//             p_grid_columns: mergeArrays(hiddenColumnsResultFinal, shownColumnsResultFinal),
//             p_row_req: null,
//             p_sort_order: sortConditionStr && sortConditionStr !== '' ? sortConditionStr : currentGridTab === 'Reqs' ? 'REF DESC' : currentGridTab === 'Properties' ? 'PROP_REF DESC' : 'SURF_REF_DESC',
//             grid_type: currentGridTab === 'Reqs' ? 'ACTIVITY_REQ_DATA' : currentGridTab === 'Properties' ? 'ACTIVITY_PROP_DATA' : 'ACTIVITY_SURF_DATA',
//         }
//         gridApi.updateGridColumnData(req)
//             .then(() => {
//                 message.success('Grid configs were saved')
//                 // if (currentGridTab === 'Reqs') {
//                 //     if (companyRef) {
//                 //         dispatch(GetActivitiesRequirements({
//                 //             p_agent_ref: Number(userData.user_ref),
//                 //             p_company_ref: companyRef,
//                 //             p_sort_order: null
//                 //         }))
//                 //
//                 //     } else {
//                 //         dispatch(GetActivitiesRequirements({
//                 //             p_agent_ref: Number(userData.user_ref),
//                 //             p_company_ref: null,
//                 //             p_sort_order: null
//                 //         }))
//                 //     }
//                 // } else if (currentGridTab === 'Properties') {
//                 //     if (!allPropertiesShown && companyRef) {
//                 //         dispatch(GetActivitiesPropertiesThunk({
//                 //             reqData: {
//                 //                 p_agent_ref: Number(userData.user_ref),
//                 //                 p_company_ref: companyRef,
//                 //                 p_sort_order: null,
//                 //                 p_status_idx: Number(currentStatus)
//                 //             }, allPropertiesShown
//                 //         }))
//                 //     } else if (allPropertiesShown && allPropertiesShown !== null) {
//                 //         dispatch(GetActivitiesPropertiesThunk({
//                 //             reqData: {
//                 //                 p_agent_ref: Number(userData.user_ref),
//                 //                 p_company_ref: null,
//                 //                 p_sort_order: null,
//                 //                 p_status_idx: Number(currentStatus)
//                 //             }, allPropertiesShown
//                 //         }))
//                 //
//                 //     }
//                 // } else {
//                 //
//                 //     if (!allSurfacesShown && companyRef) {
//                 //         dispatch(GetActivitiesSurfacesThunk({
//                 //             reqData: {
//                 //                 p_agent_ref: Number(userData.user_ref),
//                 //                 p_company_ref: companyRef,
//                 //                 p_sort_order: null,
//                 //                 p_status_idx: currentStatus
//                 //             }, allSurfacesShown
//                 //         }))
//                 //     } else if (allSurfacesShown && allSurfacesShown !== null) {
//                 //         dispatch(GetActivitiesSurfacesThunk({
//                 //             reqData: {
//                 //                 p_agent_ref: Number(userData.user_ref),
//                 //                 p_company_ref: null,
//                 //                 p_sort_order: null,
//                 //                 p_status_idx: currentStatus
//                 //             }, allSurfacesShown
//                 //         }))
//                 //     }
//                 //
//                 //
//                 // }
//             })
//             .catch((e) => {
//                 console.log(e)
//             })
//         // setLocalSortModel({})
//         //
//         // if (currentGridTab === 'Reqs') {
//         //     dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Reqs'}))
//         //     dispatch(onSetActivitiesCreationSortingString({sorting_string: null, gridType: 'Reqs'}))
//         //     dispatch(onSetActivitiesIsDataLoading(true))
//         // } else if (currentGridTab === 'Properties') {
//         //     dispatch(onSetActivitiesCreationSortingString({sorting_string: null, gridType: 'Properties'}))
//         //     dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Properties'}))
//         //     dispatch(onSetActivitiesIsPropsLoading(true))
//         // } else {
//         //     dispatch(onSetActivitiesCreationSortingString({sorting_string: null, gridType: 'Surfaces'}))
//         //     dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Surfaces'}))
//         //     dispatch(onSetActivitiesIsSurfacesLoading(true))
//         // }
//         // dispatch(setActivitiesCreationGridShownColumns({columns: []}))
//         // dispatch(onSetPropertiesGridLoaderMessage('Saving data is in progress'))
//     }
//
//
//     const onSetFiltersShown = () => {
//         setFiltersShown(!filtersShown)
//     }
//
//     const handleFiltersShownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
//         event.stopPropagation(); // Prevent event bubbling to document click event listener
//         onSetFiltersShown();
//     };
//
//     const handleStateChange = (params: any) => {
//         // Access the grid state
//         dispatch(onChangeActivitiesGridColumnsPosition({columns: params.columns.all, isCreationGrid: true}))
//         dispatch(onChangeActivitiesGridColumnsWidth({columns: params.columns.lookup, isCreationGrid: true}))
//     };
//
//
//     const onResetSorting = () => {
//         // setLocalSortModel({})
//         dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: currentGridTab}))
//     }
//
//
//     const onChangeGridMode = (gridType: TabName, currentMode: string) => {
//         dispatch(setActivitiesPropertiesGridError(false))
//         dispatch(setActivitiesSurfacesGridError(false))
//         dispatch(onChangeActivitiesGridMode({gridType, currentMode}))
//         setActiveMode({ gridType, currentMode });
//     }
//
//
//     const onAddressChange = (value: string) => {
//         dispatch(onChangeActivitiesPropertiesSearchValue(value))
//     }
//
//     const onSearchAddress = () => {
//         if(currentGridTab === 'Properties'){
//             dispatch(setActivitiesProperties([]))
//             dispatch(GetActivitiesPropertiesThunk({
//                 reqData: {
//                     p_agent_ref: Number(userData.user_ref),
//                     p_sort_order: null,
//                     p_contact_ref: currentContactRef,
//                     p_status_idx: Number(currentStatus),
//                     p_street_address: propertiesSearchValue!.toLowerCase().trim(),
//                     p_function_idx: propertiesCurrentFunc && propertiesCurrentFunc !== null && propertiesCurrentFunc !== 'All' ? Number(propertiesCurrentFunc) : null,
//                 }, allPropertiesShown
//             }))
//         }
//         else if(currentGridTab === 'Surfaces'){
//             dispatch(setActivitiesSurfaces([]))
//             dispatch(GetActivitiesSurfacesThunk({
//                 reqData: {
//                     p_agent_ref: Number(userData.user_ref),
//                     p_sort_order: null,
//                     p_contact_ref: currentContactRef,
//                     p_status_idx: Number(currentStatus),
//                     p_street_address: propertiesSearchValue!.toLowerCase().trim(),
//                     p_function_idx: surfacesCurrentFunc && surfacesCurrentFunc !== null && surfacesCurrentFunc !== 'All' ? Number(surfacesCurrentFunc) : null,
//                 }, allSurfacesShown
//             }))
//         }
//
//     }
//
//     return (
//         <div>
//             <PageTitle backLinktext='Activities' isBackButton={true} backLink={'/activitiesGrid'}/>
//             <div className={s.inner} style={{
//                 width: '100%'
//             }}>
//                 <div
//                     className={s.inner__content}
//                     style={{
//                         marginTop: '0',
//                         overflow: 'hidden'
//                     }}
//                 >
//                     <div
//                         className={commonS.tableInner}
//                         style={{
//                             padding: '9px 0 16px 12px',
//                             width: '100%'
//                         }}
//                     >
//                         <div className={s.headerButtons}>
//                             <div style={{
//                                 display: 'flex',
//                                 justifyContent: 'space-between',
//                                 alignItems: 'center'
//                             }}>
//                                 {
//                                     currentGridTab === 'Reqs'
//                                         &&
//                                     <h1 className={s.tableTitle}>
//                                         {`List of Reqs for agent ${userData.email}`}
//                                     </h1>
//                                 }
//                                 {
//                                     currentGridTab === 'Properties'
//                                     &&
//                                     <h1 className={s.tableTitle}>
//                                         {
//                                             activitiesModes[0].currentMode === 1
//                                             ? `List of Properties for contact ${modalFormFields.contacts ? modalFormFields.contacts.split('|')[0] : ''}`
//                                                 : `List of Properties for agent ${userData.email}`
//                                         }
//                                     </h1>
//                                 }
//                                 {
//                                     currentGridTab === 'Surfaces'
//                                     &&
//                                     <h1 className={s.tableTitle}>
//                                         {
//                                             activitiesModes[1].currentMode === 1
//                                                 ? `List of Surfaces for contact ${modalFormFields.contacts ? modalFormFields.contacts.split('|')[0] : ''}`
//                                                 : `List of Surfaces for agent ${userData.email}`
//                                         }
//                                     </h1>
//                                 }
//                             </div>
//                             <div style={{
//                                 marginRight: '16px'
//                             }}>
//                                 <PropertiesHeaderButtons
//                                     onSetFiltersShown={handleFiltersShownClick}
//                                     onResetConfigs={onResetConfigs}
//                                     onSaveGridConfigs={onSaveGridConfigs}
//                                     isDisabledSaveBtn={false}
//                                     gridType={'Activities Grid/Create/Update'}
//                                     resetFilters={() => {
//                                     }}
//                                 />
//                             </div>
//                         </div>
//                         {
//                             currentGridTab === 'Properties' && activitiesModes[0].currentMode === 1
//                             &&
//                             <div
//                                 className={s.searchInner}
//                                 style={{
//                                     marginBottom: '15px'
//                                 }}
//                             >
//                                 <div className={s.inner__inputBox}>
//                                     <Input
//                                         onChange={(e) => onAddressChange(e.target.value)}
//                                         onPressEnter={onSearchAddress}
//                                         className={s.shownSearchInput}
//                                         placeholder={'Search by address'}
//                                         value={propertiesSearchValue !== null ? propertiesSearchValue : undefined}
//                                         prefix={
//                                             <svg
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                                 width="20"
//                                                 height="20"
//                                                 viewBox="0 0 20 20"
//                                                 fill="none"
//                                                 style={{
//                                                     marginRight: '8px'
//                                                 }}
//                                             >
//                                                 <path
//                                                     d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
//                                                     stroke="#667085" strokeWidth="1.66667" strokeLinecap="round"
//                                                     strokeLinejoin="round"/>
//                                             </svg>}
//                                     />
//                                 </div>
//
//                                 <button onClick={onSearchAddress}>Search</button>
//                             </div>
//                         }
//                         {
//                             currentGridTab === 'Surfaces' && activitiesModes[1].currentMode === 1
//                             &&
//                             <div
//                                 className={s.searchInner}
//                                 style={{
//                                     marginBottom: '15px'
//                                 }}
//                             >
//                                 <div className={s.inner__inputBox}>
//                                     <Input
//                                         onChange={(e) => onAddressChange(e.target.value)}
//                                         onPressEnter={onSearchAddress}
//                                         className={s.shownSearchInput}
//                                         placeholder={'Search by address'}
//                                         value={propertiesSearchValue !== null ? propertiesSearchValue : undefined}
//                                         prefix={
//                                             <svg
//                                                 xmlns="http://www.w3.org/2000/svg"
//                                                 width="20"
//                                                 height="20"
//                                                 viewBox="0 0 20 20"
//                                                 fill="none"
//                                                 style={{
//                                                     marginRight: '8px'
//                                                 }}
//                                             >
//                                                 <path
//                                                     d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
//                                                     stroke="#667085" strokeWidth="1.66667" strokeLinecap="round"
//                                                     strokeLinejoin="round"/>
//                                             </svg>}
//                                     />
//                                 </div>
//
//                                 <button onClick={onSearchAddress}>Search</button>
//                             </div>
//                         }
//
//                         <div style={{
//                             display: 'flex',
//                             alignItems: 'center',
//                             marginBottom: '10px'
//                         }}>
//                             <PropertiesSearchInput
//                                 searchTerm={searchTerm}
//                                 setSearchTerm={(searchTerm: string) => setSearchTerm(searchTerm)}
//                                 handleSearchInputChange={handleSearchInputChange}
//                                 isActivityReq={true}
//                             />
//                         </div>
//                         <div style={{
//                             display: 'flex',
//                             marginBottom: '25px'
//                         }}>
//                             <Select
//                                 style={{width: 105, marginRight: '12px'}}
//                                 className={'mapSelect'}
//                                 placeholder={'Status'}
//                                 suffixIcon={<svg width="14" height="12" viewBox="0 0 14 12" fill="none"
//                                                  xmlns="http://www.w3.org/2000/svg">
//                                     <path
//                                         d="M6.30026 8.3L3.70026 5.7C3.38359 5.38333 3.31292 5.021 3.48826 4.613C3.66292 4.20433 3.97526 4 4.42526 4H9.57526C10.0253 4 10.3376 4.20433 10.5123 4.613C10.6876 5.021 10.6169 5.38333 10.3003 5.7L7.70026 8.3C7.60026 8.4 7.49192 8.475 7.37526 8.525C7.25859 8.575 7.13359 8.6 7.00026 8.6C6.86692 8.6 6.74192 8.575 6.62526 8.525C6.50859 8.475 6.40026 8.4 6.30026 8.3Z"
//                                         fill={'#191559'}/>
//                                 </svg>}
//                                 onChange={handleStatusChange}
//                                 defaultValue={'Active'}
//                                 options={selectMapStatuses}
//                             />
//                             {
//                                 currentGridTab === 'Properties' && activitiesModes[0].currentMode === 1
//                                 &&
//                                 <Select
//                                     style={{width: 105, marginRight: '12px'}}
//                                     className={'mapSelect'}
//                                     placeholder={'Function'}
//                                     defaultValue={'Function'}
//                                     suffixIcon={<svg width="14" height="12" viewBox="0 0 14 12" fill="none"
//                                                      xmlns="http://www.w3.org/2000/svg">
//                                         <path
//                                             d="M6.30026 8.3L3.70026 5.7C3.38359 5.38333 3.31292 5.021 3.48826 4.613C3.66292 4.20433 3.97526 4 4.42526 4H9.57526C10.0253 4 10.3376 4.20433 10.5123 4.613C10.6876 5.021 10.6169 5.38333 10.3003 5.7L7.70026 8.3C7.60026 8.4 7.49192 8.475 7.37526 8.525C7.25859 8.575 7.13359 8.6 7.00026 8.6C6.86692 8.6 6.74192 8.575 6.62526 8.525C6.50859 8.475 6.40026 8.4 6.30026 8.3Z"
//                                             fill={'#191559'}/>
//                                     </svg>}
//                                     onChange={handleFunctionChange}
//                                     options={[
//                                         { label: 'Function', value: 'function', disabled: true },
//                                         ...selectReqFunctions
//                                     ]}
//                                 />
//                             }
//                             {
//                                 currentGridTab === 'Surfaces' && activitiesModes[1].currentMode === 1
//                                 &&
//                                 <Select
//                                     style={{width: 105, marginRight: '12px'}}
//                                     className={'mapSelect'}
//                                     placeholder={'Function'}
//                                     defaultValue={'Function'}
//                                     suffixIcon={<svg width="14" height="12" viewBox="0 0 14 12" fill="none"
//                                                      xmlns="http://www.w3.org/2000/svg">
//                                         <path
//                                             d="M6.30026 8.3L3.70026 5.7C3.38359 5.38333 3.31292 5.021 3.48826 4.613C3.66292 4.20433 3.97526 4 4.42526 4H9.57526C10.0253 4 10.3376 4.20433 10.5123 4.613C10.6876 5.021 10.6169 5.38333 10.3003 5.7L7.70026 8.3C7.60026 8.4 7.49192 8.475 7.37526 8.525C7.25859 8.575 7.13359 8.6 7.00026 8.6C6.86692 8.6 6.74192 8.575 6.62526 8.525C6.50859 8.475 6.40026 8.4 6.30026 8.3Z"
//                                             fill={'#191559'}/>
//                                     </svg>}
//                                     onChange={handleFunctionChange}
//                                     options={[
//                                         { label: 'Function', value: 'function', disabled: true },
//                                         ...selectReqFunctions
//                                     ]}
//                                 />
//                             }
//
//                         </div>
//                         <div style={{
//                             display: 'flex',
//                             justifyContent: 'space-between',
//                             paddingRight: '15px',
//                             alignItems: 'center'
//                         }}>
//                             <div className={s.tabButtons} style={{
//                                 width: 'fit-content',
//                             }}>
//                                 <button
//                                     className={currentGridTab === 'Reqs' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : s.tabButtons__button}
//                                     onClick={() => onChangeGridTab('Reqs')}
//                                     style={{position: "relative"}}
//                                 >
//                                     Reqs
//                                 </button>
//                                 {/*{*/}
//                                 {/*    isPropsLoading*/}
//                                 {/*        ?*/}
//                                 {/*        <Tooltip text="Please wait, the properties are loading"*/}
//                                 {/*                 classname={'activitiesTabsTooltip'}>*/}
//                                             <button
//                                                 className={currentGridTab === 'Properties' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : s.tabButtons__button}
//                                                 onClick={() => onChangeGridTab('Properties')}
//                                                 style={{position: "relative"}}
//                                                 // disabled={isPropsLoading}
//                                             >
//                                                 Properties
//                                                 {isPropsLoading ? (
//                                                         <div
//                                                             style={{
//                                                                 position: 'absolute',
//                                                                 top: '-9px',
//                                                                 right: '-15px',
//                                                                 bottom: 0,
//                                                                 display: 'flex',
//                                                                 alignItems: 'center',
//                                                                 justifyContent: 'center',
//                                                                 // background: 'rgba(255, 255, 255, 0.8)',
//                                                                 zIndex: 1000,
//                                                             }}
//                                                         >
//                                                             <FacebookCircularProgress size={15}/>
//                                                         </div>
//                                                     )
//                                                     :
//                                                     null
//                                                 }
//                                             </button>
//                                         {/*</Tooltip>*/}
//
//
//                                 {/*}*/}
//                                 <button
//                                     className={currentGridTab === 'Surfaces' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : s.tabButtons__button}
//                                     onClick={() => onChangeGridTab('Surfaces')}
//                                     style={{position: "relative"}}
//                                 >
//                                     Surfaces
//                                     {isSurfacesLoading
//                                     ?
//                                     <div
//                                         style={{
//                                             position: 'absolute',
//                                             top: '-9px',
//                                             right: '-15px',
//                                             bottom: 0,
//                                             display: 'flex',
//                                             alignItems: 'center',
//                                             justifyContent: 'center',
//                                             // background: 'rgba(255, 255, 255, 0.8)',
//                                             zIndex: 1000,
//                                         }}
//                                     >
//                                         <FacebookCircularProgress size={15}/>
//                                     </div>
//                                     :
//                                     null}
//                                 </button>
//                             </div>
//
//                             <PropertiesFilterButtons
//                                 gridType={'Activities Grid/Create/Update'}
//                                 shownColumns={shownColumns}
//                                 onResetFilters={() => {}}
//                                 onResetSorting={onResetSorting}
//                             />
//                         </div>
//                         {/*{*/}
//                         {/*    currentGridTab !== 'Reqs'*/}
//                         {/*    &&*/}
//                         {/*    <div className={s.modeButtons}>*/}
//                         {/*        <Tooltip text={currentGridTab === 'Properties'*/}
//                         {/*            ? 'List of properties with direct connection by Property Contacts without requirement connection'*/}
//                         {/*            : 'List of surfaces with connection by selected contact'*/}
//                         {/*        }*/}
//                         {/*                 classname={'activitiesModeTooltip'}>*/}
//
//                         {/*            <div*/}
//                         {/*                className={*/}
//                         {/*                    activitiesModes.find((a) => a.gridType === currentGridTab)?.currentMode === 1*/}
//                         {/*                        ? `${s.modeButtons__button} ${s.modeButtons__button_active}`*/}
//                         {/*                        : `${s.modeButtons__button}`*/}
//                         {/*                }*/}
//                         {/*                onClick={() => onChangeGridMode(currentGridTab as TabName, 'Function')}*/}
//                         {/*                style={{*/}
//                         {/*                    borderBottomLeftRadius: activitiesModes.find((a) => a.gridType === currentGridTab)?.currentMode === 1 ? '8px' : "0px",*/}
//                         {/*                    borderTopLeftRadius: activitiesModes.find((a) => a.gridType === currentGridTab)?.currentMode === 1 ? '8px' : "0px",*/}
//                         {/*                    height: '97%',*/}
//                         {/*                    borderRight: '1px solid #D0D5DD',*/}
//                         {/*                    marginTop: '1px'*/}
//                         {/*                }}*/}
//                         {/*            >*/}
//                         {/*                Function*/}
//                         {/*            </div>*/}
//                         {/*        </Tooltip>*/}
//                         {/*        <Tooltip text={currentGridTab === 'Properties'*/}
//                         {/*            ? 'List of properties with connection by agent'*/}
//                         {/*            : 'List of surfaces with connection by agent'*/}
//                         {/*        }*/}
//                         {/*                 classname={'activitiesModeTooltip'}>*/}
//
//                         {/*            <div*/}
//                         {/*                className={*/}
//                         {/*                    activitiesModes.find((a) => a.gridType === currentGridTab)?.currentMode === 2*/}
//                         {/*                        ? `${s.modeButtons__button} ${s.modeButtons__button_active}`*/}
//                         {/*                        : `${s.modeButtons__button}`*/}
//                         {/*                }*/}
//                         {/*                onClick={() => onChangeGridMode(currentGridTab as TabName, 'Agent')}*/}
//                         {/*                style={{*/}
//                         {/*                    borderBottomRightRadius: activitiesModes.find((a) => a.gridType === currentGridTab)?.currentMode === 2 ? '8px' : "0px",*/}
//                         {/*                    borderTopRightRadius: activitiesModes.find((a) => a.gridType === currentGridTab)?.currentMode === 2 ? '8px' : "0px",*/}
//                         {/*                    height: '97%',*/}
//                         {/*                    marginTop: '1px'*/}
//                         {/*                }}*/}
//                         {/*            >*/}
//                         {/*                Agent*/}
//                         {/*            </div>*/}
//                         {/*        </Tooltip>*/}
//                         {/*    </div>*/}
//                         {/*}*/}
//                         <Box sx={{position: 'relative', width: '100%'}}>
//                             <PropertiesViewOptions
//                                 checked={checked}
//                                 filtersShown={filtersShown}
//                                 setChecked={(checkedArray: string[]) => setChecked(checkedArray)}
//                                 setFilterShown={(isShown: boolean) => setFiltersShown(isShown)}
//                                 gridType={'Activities Grid/Create/Update'}
//                             />
//                             <>
//                                 {
//                                     currentGridTab === 'Reqs' && isDataLoading && !reqRows.length
//                                     ||
//                                     currentGridTab === 'Properties' && !propRows.length && isPropsLoading
//                                     ||
//                                     currentGridTab === 'Surfaces' && isSurfacesLoading && !surfRows.length
//                                         ?
//                                         <div style={{
//                                             height: gridContentHeight - 90,
//                                             width: '400px',
//                                             margin: '0 auto',
//                                             display: 'flex',
//                                             flexDirection: 'column',
//                                             justifyContent: 'center'
//                                         }}>
//                                             <div style={{
//                                                 height: '300px',
//                                                 width: '100%',
//                                                 textAlign: 'center'
//                                             }}>
//                                                 {/* @ts-ignore */}
//                                                 <Lottie config={{animationData: animatedLoader, loop: true, height: '60px !important'}}
//                                                 />
//                                             </div>
//                                         </div>
//                                         :
//
//                                         gridRows.length
//                                             ?
//                                             <DataGridPro
//                                                 getRowClassName={getRowClassName}
//                                                 rows={gridRowsPaginated}
//                                                 columns={shownColumns.map((column: any, index: number) => ({
//                                                     ...column,
//                                                     renderCell: (params: any) =>
//                                                         <CustomCell field={column.field}
//                                                                     value={params.value}
//                                                                     params={params}
//                                                                     properties={gridRowsPaginated}
//                                                                     key={index}
//                                                                     dataType={column.dataType}
//
//                                                         />
//                                                 }))}
//                                                 apiRef={apiRef}
//                                                 autoHeight={true}
//                                                 rowHeight={44}
//                                                 getRowId={e => e.id}
//                                                 sortModel={Object.values(localSortModel)}
//                                                 sortingOrder={['desc', 'asc']}
//                                                 onSortModelChange={handleSortModelChange}
//                                                 onRowDoubleClick={onRowClick}
//                                                 disableColumnSelector={true}
//                                                 onStateChange={handleStateChange}
//                                                 {...gridRowsPaginated}
//                                                 sx={{
//                                                     borderRight: 'none',
//                                                     borderLeft: 'none',
//                                                     borderBottom: 'none',
//                                                     borderRadius: '0px',
//                                                     // minHeight: '600px',
//                                                     // height: height > 950 ? `${gridContentHeight}px !important` : height > 900 ? `${gridContentHeight - 150}px !important` : `${gridContentHeight + 150}px !important`,
//                                                     "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
//                                                         opacity: 0
//                                                     },
//                                                     "& .MuiDataGrid-cellContent": {
//                                                         fontFamily: 'Inter, sans-serif',
//                                                         fontWeight: 500,
//                                                         fontSize: '14px',
//                                                         lineHeight: '20px',
//                                                         color: '#111827'
//                                                     },
//                                                     "& .MuiDataGrid-columnHeaderTitle": {
//                                                         fontFamily: 'Inter, sans-serif',
//                                                         fontWeight: 600,
//                                                         color: '#6B7280',
//                                                         fontSize: '12px',
//                                                         lineHeight: '20px',
//                                                     },
//                                                     "& .MuiDataGrid-row:hover": {
//                                                         backgroundColor: 'rgba(23, 85, 231, 0.12)'
//                                                     },
//                                                     "& .MuiDataGrid-row.Mui-selected": {
//                                                         backgroundColor: 'rgba(23, 85, 231, 0.12)'
//                                                     },
//                                                     "& .MuiDataGrid-row.Mui-selected:hover": {
//                                                         backgroundColor: 'rgba(23, 85, 231, 0.12)'
//                                                     },
//                                                     "& .MuiDataGrid-cell:focus": {
//                                                         outline: 'none'
//                                                     },
//                                                     "& .MuiDataGrid-toolbarContainer": {
//                                                         color: '#454545',
//                                                         position: 'absolute',
//                                                         top: '-50px',
//                                                         right: '0px'
//                                                     },
//                                                     "& .MuiTablePagination-displayedRows": {
//                                                         margin: '0',
//                                                         fontFamily: 'Inter, sans-serif !important',
//                                                         fontWeight: 400,
//                                                         color: '#6B7280',
//                                                         fontSize: '12px',
//                                                         lineHeight: '20px',
//                                                     },
//                                                     "& .MuiPagination-root": {
//                                                         display: 'flex',
//                                                         justifyContent: 'center',
//                                                         padding: '15px 0px'
//                                                     },
//                                                     "& .MuiButtonBase-root.Mui-selected": {
//                                                         backgroundColor: '#eff1f3',
//                                                         borderRadius: '0px'
//                                                     },
//                                                     "& .MuiButtonBase-root.MuiPaginationItem-root": {
//                                                         width: '40px',
//                                                         height: '40px',
//                                                         margin: 0,
//                                                         borderRight: '1px solid #D0D5DD',
//                                                         borderLeft: '1px solid #D0D5DD',
//                                                         borderTop: 'none',
//                                                         borderBottom: 'none',
//                                                         fontFamily: 'Inter, sans-serif !important',
//                                                         fontWeight: 500,
//                                                         color: '#1D2939',
//                                                         fontSize: '14px',
//                                                         lineHeight: '20px',
//                                                         borderRadius: '0px'
//                                                     },
//                                                     "& .MuiPagination-ul": {
//                                                         border: '1px solid #D0D5DD',
//                                                         borderRadius: '8px'
//                                                     },
//                                                     "& .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-previousNext": {
//                                                         borderRadius: '0px',
//                                                         border: 'none'
//                                                     },
//                                                     "& .MuiDataGrid-columnHeaders": {
//                                                         backgroundColor: '#FCFDFE'
//                                                     },
//                                                     "& .MuiDivider-root": {
//                                                         display: 'none',
//                                                         height: '0',
//                                                         width: '0',
//                                                         opacity: '0 !important',
//                                                         borderColor: '#fff '
//                                                     },
//                                                     "& .MuiBox-root": {
//                                                         height: 'auto'
//                                                     },
//                                                     "& .MuiDataGrid-cell": {
//                                                         borderBottom: 'none'
//                                                     },
//                                                     "& .MuiDataGrid-virtualScroller": {
//                                                         overflowY: 'scroll !important',
//                                                         overflowX: 'scroll',
//                                                         height: `${gridContentHeight - 220}px !important`,
//                                                         minHeight: 'auto',
//                                                     },
//                                                     "& .MuiDataGrid-virtualScrollerContent": {},
//                                                 }}
//                                                 components={{
//                                                     Footer: () =>
//                                                         <PropertiesGridFooter
//                                                             rows={gridRows}
//                                                             rowsPerPageOptions={rowsPerPageOptions}
//                                                             handlePageChange={handlePageChange}
//                                                             handleRowsPerPageChange={handleRowsPerPageChange}
//                                                             rowsPerPage={rowsPerPage}
//                                                             page={page}
//                                                             activityCreationGridType={currentGridTab}
//                                                         />,
//                                                     ColumnMenu: (props) => {
//                                                         return (
//                                                             <GridColumnMenu
//                                                                 hideMenu={() => {
//                                                                 }}
//                                                                 currentColumn={
//                                                                     {
//                                                                         field: props.currentColumn.field,
//                                                                         filterable: false,
//                                                                         hideable: false,
//                                                                     }
//                                                                 }
//                                                                 open={true}
//                                                             />
//                                                         )
//                                                     },
//                                                     Row: CustomGridRow,
//                                                 }}
//                                             />
//                                             :
//                                             <div style={{
//                                                 height: `${gridContentHeight - 90}px`,
//                                                 display: 'flex',
//                                                 alignItems: 'center',
//                                                 justifyContent: 'center'
//                                             }}>
//                                                 {
//                                                         currentGridTab === 'Properties' && activityPropertiesGridError && activitiesModes[0].currentMode === 1
//                                                             ?
//                                                             <Empty description={'No data found, please try again with another address'}/>
//                                                             :
//                                                         currentGridTab === 'Properties' && activitiesModes[0].currentMode === 1 && !activityPropertiesGridError
//                                                             ?
//                                                             <Empty description={'Start by entering an address and press Search to display properties'}/>
//                                                             :
//                                                             currentGridTab === 'Surfaces' && activitySurfacesGridError && activitiesModes[1].currentMode === 1
//                                                                 ?
//                                                                 <Empty description={'No data found, please try again with another address'}/>
//                                                                 :
//                                                             currentGridTab === 'Surfaces' && activitiesModes[1].currentMode === 1  && !activitySurfacesGridError
//                                                             ?
//                                                             <Empty description={'Start by entering an address and press Search to display properties'}/>
//                                                                 :
//                                                                 <Empty/>
//
//                                                 }
//
//                                             </div>
//                                 }
//                             </>
//                         </Box>
//
//                     </div>
//
//                 </div>
//                 <CommonActivityModal
//                     modalTitle={`Activity ${editMode ? currentRowValues.REF! : ''}`}
//                     gridType={"Activity"}
//                     height={gridContentHeight}
//                     onCloseModal={onCloseModal}
//                     width={'650px'}
//                 >
//                     <div>
//                         <div className={s.tabButtons}>
//                             <button
//                                 onClick={() => dispatch(onSetCurrentModalTab('General'))}
//                                 className={currentTab === 'General' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : s.tabButtons__button}
//                             >
//                                 General
//                             </button>
//                             {
//                                 checkedReqIds.length || checkedPropertiesIds.length || checkedSurfaceIds.length
//                                     ?
//                                     <button
//                                         onClick={() => dispatch(onSetCurrentModalTab('Existing Links'))}
//                                         className={currentTab === 'Existing Links' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : s.tabButtons__button}
//                                     >
//                                         Record links
//                                     </button>
//                                     :
//                                     null
//                             }
//                             {
//                                 currentRowValues.WE_TYPE_POSITION !== null
//                                 &&
//                                 <p className={s.badge}>{currentRowValues.WE_TYPE_POSITION}</p>
//                             }
//                         </div>
//                         {
//                             currentTab === 'General'
//                                 ?
//                                 <ActivitiesGridForm withButtons={false} editMode={editMode} maxWidthInputs={true}/>
//                                 :
//                                 checkedReqIds.length || checkedPropertiesIds.length || checkedSurfaceIds.length
//                                     ?
//                                     <div>
//                                         <>
//                                             {checkedReqIds.length
//                                                 ?
//                                                 <div className={propertiesStyles.messageInner__properties}>
//                                                     <p>Requirements:</p>
//                                                     <div className={propertiesStyles.messageInner__propertiesInner}>
//                                                         {checkedReqIds.map((p: number) => {
//                                                             return (
//                                                                 <div className={propertiesStyles.messageInner__property}
//                                                                      key={p}>
//
//
//                                                                     <span>{p}</span>
//                                                                     <button
//                                                                         onClick={() => dispatch(onDeleteCheckedReq(p))}
//                                                                         type={'button'}
//                                                                         disabled={currentRowValues?.ACT_TYPE === 'Documentation'}
//                                                                         style={{
//                                                                             cursor: currentRowValues?.ACT_TYPE === 'Documentation' ? 'not-allowed' : 'pointer'
//                                                                         }}
//                                                                     >
//                                                                         <svg width="12" height="12" viewBox="0 0 12 12"
//                                                                              fill="none"
//                                                                              xmlns="http://www.w3.org/2000/svg">
//                                                                             <g clipPath="url(#clip0_1387_36685)">
//                                                                                 <path
//                                                                                     d="M6.46967 5.99974L9.233 3.2364C9.28761 3.17264 9.31614 3.09061 9.3129 3.00672C9.30966 2.92283 9.27488 2.84325 9.21552 2.78388C9.15616 2.72452 9.07658 2.68974 8.99268 2.6865C8.90879 2.68326 8.82677 2.71179 8.763 2.7664L5.99967 5.52974L3.23633 2.76307C3.17356 2.7003 3.08843 2.66504 2.99967 2.66504C2.9109 2.66504 2.82577 2.7003 2.763 2.76307C2.70023 2.82584 2.66497 2.91097 2.66497 2.99974C2.66497 3.0885 2.70023 3.17364 2.763 3.2364L5.52967 5.99974L2.763 8.76307C2.7281 8.79295 2.69976 8.82972 2.67976 8.87108C2.65975 8.91243 2.64851 8.95748 2.64673 9.00338C2.64496 9.04929 2.65269 9.09507 2.66945 9.13784C2.68621 9.18062 2.71163 9.21947 2.74412 9.25195C2.7766 9.28444 2.81545 9.30986 2.85822 9.32661C2.901 9.34337 2.94678 9.35111 2.99268 9.34933C3.03859 9.34756 3.08363 9.33632 3.12499 9.31631C3.16634 9.2963 3.20312 9.26796 3.233 9.23307L5.99967 6.46974L8.763 9.23307C8.82677 9.28768 8.90879 9.31621 8.99268 9.31297C9.07658 9.30973 9.15616 9.27496 9.21552 9.21559C9.27488 9.15623 9.30966 9.07665 9.3129 8.99275C9.31614 8.90886 9.28761 8.82684 9.233 8.76307L6.46967 5.99974Z"
//                                                                                     fill={'rgba(0, 0, 0, 0.54)'}/>
//                                                                             </g>
//                                                                             <defs>
//                                                                                 <clipPath id="clip0_1387_36685">
//                                                                                     <rect width="12" height="12"
//                                                                                           fill="white"/>
//                                                                                 </clipPath>
//                                                                             </defs>
//                                                                         </svg>
//
//                                                                     </button>
//                                                                 </div>
//                                                             )
//                                                         })}
//                                                     </div>
//                                                 </div>
//                                                 :
//                                                 null}
//                                         </>
//                                         <>
//                                             {checkedPropertiesIds.length
//                                                 ?
//                                                 <div className={propertiesStyles.messageInner__properties}>
//                                                     <p>Properties:</p>
//                                                     <div className={propertiesStyles.messageInner__propertiesInner}>
//                                                         {checkedPropertiesIds.map((p: number) => {
//                                                             return (
//                                                                 <div className={propertiesStyles.messageInner__property}
//                                                                      key={p}>
//
//
//                                                                     <span>{p}</span>
//                                                                     <button
//                                                                         onClick={() => dispatch(onDeleteCheckedProperty({
//                                                                             prop_id: p,
//                                                                             withoutReq: false
//                                                                         }))}
//                                                                         type={'button'}
//                                                                         disabled={currentRowValues?.ACT_TYPE === 'Documentation'}
//                                                                         style={{
//                                                                             cursor: currentRowValues?.ACT_TYPE === 'Documentation' ? 'not-allowed' : 'pointer'
//                                                                         }}
//                                                                     >
//                                                                         <svg width="12" height="12" viewBox="0 0 12 12"
//                                                                              fill="none"
//                                                                              xmlns="http://www.w3.org/2000/svg">
//                                                                             <g clipPath="url(#clip0_1387_36685)">
//                                                                                 <path
//                                                                                     d="M6.46967 5.99974L9.233 3.2364C9.28761 3.17264 9.31614 3.09061 9.3129 3.00672C9.30966 2.92283 9.27488 2.84325 9.21552 2.78388C9.15616 2.72452 9.07658 2.68974 8.99268 2.6865C8.90879 2.68326 8.82677 2.71179 8.763 2.7664L5.99967 5.52974L3.23633 2.76307C3.17356 2.7003 3.08843 2.66504 2.99967 2.66504C2.9109 2.66504 2.82577 2.7003 2.763 2.76307C2.70023 2.82584 2.66497 2.91097 2.66497 2.99974C2.66497 3.0885 2.70023 3.17364 2.763 3.2364L5.52967 5.99974L2.763 8.76307C2.7281 8.79295 2.69976 8.82972 2.67976 8.87108C2.65975 8.91243 2.64851 8.95748 2.64673 9.00338C2.64496 9.04929 2.65269 9.09507 2.66945 9.13784C2.68621 9.18062 2.71163 9.21947 2.74412 9.25195C2.7766 9.28444 2.81545 9.30986 2.85822 9.32661C2.901 9.34337 2.94678 9.35111 2.99268 9.34933C3.03859 9.34756 3.08363 9.33632 3.12499 9.31631C3.16634 9.2963 3.20312 9.26796 3.233 9.23307L5.99967 6.46974L8.763 9.23307C8.82677 9.28768 8.90879 9.31621 8.99268 9.31297C9.07658 9.30973 9.15616 9.27496 9.21552 9.21559C9.27488 9.15623 9.30966 9.07665 9.3129 8.99275C9.31614 8.90886 9.28761 8.82684 9.233 8.76307L6.46967 5.99974Z"
//                                                                                     fill={'rgba(0, 0, 0, 0.54)'}/>
//                                                                             </g>
//                                                                             <defs>
//                                                                                 <clipPath id="clip0_1387_36685">
//                                                                                     <rect width="12" height="12"
//                                                                                           fill="white"/>
//                                                                                 </clipPath>
//                                                                             </defs>
//                                                                         </svg>
//
//                                                                     </button>
//                                                                 </div>
//                                                             )
//                                                         })}
//                                                     </div>
//                                                 </div>
//                                                 :
//                                                 null}
//                                         </>
//                                         <>
//                                             {checkedSurfaceIds.length
//                                                 ?
//                                                 <div className={propertiesStyles.messageInner__properties}>
//                                                     <p>Surfaces:</p>
//                                                     <div className={propertiesStyles.messageInner__propertiesInner}>
//                                                         {checkedSurfaceIds.map((p: any) => {
//                                                             return (
//                                                                 <div className={propertiesStyles.messageInner__property}
//                                                                      key={p}>
//
//
//                                                                     <span>{p}</span>
//                                                                     <button
//                                                                         onClick={() => dispatch(onDeleteCheckedSurface({
//                                                                             surface_id: p,
//                                                                         }))}
//                                                                         type={'button'}
//                                                                         disabled={currentRowValues?.ACT_TYPE === 'Documentation'}
//                                                                         style={{
//                                                                             cursor: currentRowValues?.ACT_TYPE === 'Documentation' ? 'not-allowed' : 'pointer'
//                                                                         }}
//                                                                     >
//                                                                         <svg width="12" height="12" viewBox="0 0 12 12"
//                                                                              fill="none"
//                                                                              xmlns="http://www.w3.org/2000/svg">
//                                                                             <g clipPath="url(#clip0_1387_36685)">
//                                                                                 <path
//                                                                                     d="M6.46967 5.99974L9.233 3.2364C9.28761 3.17264 9.31614 3.09061 9.3129 3.00672C9.30966 2.92283 9.27488 2.84325 9.21552 2.78388C9.15616 2.72452 9.07658 2.68974 8.99268 2.6865C8.90879 2.68326 8.82677 2.71179 8.763 2.7664L5.99967 5.52974L3.23633 2.76307C3.17356 2.7003 3.08843 2.66504 2.99967 2.66504C2.9109 2.66504 2.82577 2.7003 2.763 2.76307C2.70023 2.82584 2.66497 2.91097 2.66497 2.99974C2.66497 3.0885 2.70023 3.17364 2.763 3.2364L5.52967 5.99974L2.763 8.76307C2.7281 8.79295 2.69976 8.82972 2.67976 8.87108C2.65975 8.91243 2.64851 8.95748 2.64673 9.00338C2.64496 9.04929 2.65269 9.09507 2.66945 9.13784C2.68621 9.18062 2.71163 9.21947 2.74412 9.25195C2.7766 9.28444 2.81545 9.30986 2.85822 9.32661C2.901 9.34337 2.94678 9.35111 2.99268 9.34933C3.03859 9.34756 3.08363 9.33632 3.12499 9.31631C3.16634 9.2963 3.20312 9.26796 3.233 9.23307L5.99967 6.46974L8.763 9.23307C8.82677 9.28768 8.90879 9.31621 8.99268 9.31297C9.07658 9.30973 9.15616 9.27496 9.21552 9.21559C9.27488 9.15623 9.30966 9.07665 9.3129 8.99275C9.31614 8.90886 9.28761 8.82684 9.233 8.76307L6.46967 5.99974Z"
//                                                                                     fill={'rgba(0, 0, 0, 0.54)'}/>
//                                                                             </g>
//                                                                             <defs>
//                                                                                 <clipPath id="clip0_1387_36685">
//                                                                                     <rect width="12" height="12"
//                                                                                           fill="white"/>
//                                                                                 </clipPath>
//                                                                             </defs>
//                                                                         </svg>
//
//                                                                     </button>
//                                                                 </div>
//                                                             )
//                                                         })}
//                                                     </div>
//                                                 </div>
//                                                 :
//                                                 null}
//                                         </>
//                                     </div>
//                                     :
//                                     <div className={s.reqGridText}>
//                                         Add the Reqs / Properties / Surfaces to the activity.
//                                     </div>
//                         }
//                     </div>
//
//
//                     <>
//                         {
//                             editMode
//                                 ?
//                                 <div className={s.form__buttons}>
//                                     <Button
//                                         type='default'
//                                         style={{
//                                             width: currentRowValues.ACT_TYPE !== 'Documentation' ? '295px' : '100%',
//                                             height: '44px',
//                                         }}
//                                         onClick={onCloseModal}
//                                     >
//                                         Cancel
//                                     </Button>
//                                     {
//                                         modalFormFields.domain === ''
//                                         || modalFormFields.domain === null
//                                         || modalFormFields.type === ''
//                                         || modalFormFields.type === null
//                                         || modalFormFields.kind === ''
//                                         || modalFormFields.kind === null
//                                         || !isActivityTypeKindWithRightValue
//                                             ?
//                                             <Tooltip
//                                                 text={isActivityTypeKindWithRightValue && modalFormFields.domain === ''
//                                                 || modalFormFields.domain === null
//                                                 || modalFormFields.type === ''
//                                                 || modalFormFields.type === null
//                                                 || modalFormFields.kind === ''
//                                                 || modalFormFields.kind === null
//                                                     ? `Please select the domain , activity type and activity kind in order to save changes.`
//                                                     : `Please change your activity kind to the one that is correct with the system kinds`}
//                                                 classname={'addressButtonsTooltip'}>
//                                                 <Button
//                                                     type='primary'
//                                                     htmlType='submit'
//                                                     style={{
//                                                         height: '44px',
//                                                         backgroundColor: 'rgba(7, 7, 250, 0.26)',
//                                                         border: '1px solid transparent',
//                                                         width: '295px',
//                                                         color: "#FFF",
//                                                         display: currentRowValues.ACT_TYPE !== 'Documentation' ? 'flex' : 'none',
//                                                         alignItems: 'center',
//                                                         justifyContent: 'center',
//
//                                                     }}
//                                                     onClick={() => onCreateNewActivity(false)}
//                                                     onDoubleClick={() => setIsLoading(true)}
//                                                     disabled={
//                                                         modalFormFields.domain === ''
//                                                         || modalFormFields.domain === null
//                                                         || modalFormFields.type === ''
//                                                         || modalFormFields.type === null
//                                                         || modalFormFields.kind === ''
//                                                         || modalFormFields.kind === null
//                                                         || !isActivityTypeKindWithRightValue
//                                                     }
//                                                     loading={loading}
//                                                 >
//                                                     Save
//                                                 </Button>
//                                             </Tooltip>
//                                             :
//                                             <Button
//                                                 type='primary'
//                                                 htmlType='submit'
//                                                 style={{
//                                                     height: '44px',
//                                                     backgroundColor: '#0707FA',
//                                                     color: '#fff',
//                                                     border: '1px solid transparent',
//                                                     width: '295px',
//                                                     display: currentRowValues.ACT_TYPE !== 'Documentation' ? 'flex' : 'none',
//                                                     alignItems: 'center',
//                                                     justifyContent: 'center',
//                                                     marginLeft: '15px'
//
//                                                 }}
//                                                 onClick={() => onCreateNewActivity(false)}
//                                                 // onDoubleClick={() => setIsLoading(true)}
//                                                 // loading={loading}
//                                             >
//                                                 Save
//                                             </Button>
//                                     }
//
//                                 </div>
//                                 :
//
//                                 <Button
//                                     type='primary'
//                                     htmlType='submit'
//                                     style={{
//                                         height: '40px',
//                                         backgroundColor: '#0707FA',
//                                         border: '1px solid #0707FA',
//                                         display: 'flex',
//                                         alignItems: 'center',
//                                         justifyContent: 'center',
//                                         marginTop: '10px'
//
//                                     }}
//                                     onClick={() => onCreateNewActivity(false)}
//                                     // onDoubleClick={() => setIsLoading(true)}
//                                     // loading={loading}
//                                 >
//                                     Add
//                                 </Button>
//
//
//                         }
//
//                     </>
//
//
//                 </CommonActivityModal>
//             </div>
//             {
//                 popConfirmModalOpened
//                 &&
//                 <div className={s.popConfirm}>
//                     <div className={s.popConfirm__content} style={{
//                         height: 'auto'
//                     }}>
//                         <div className={s.popConfirm__warningIcon}>
//                             <WarningActivityIcon/>
//                         </div>
//                         <p className={s.popConfirm__deleteTitle}>You have selected req without properties/surfaces</p>
//                         <p className={s.popConfirm__deleteText}>Are you sure you want to save this activity? You have
//                             requirements without chosen properties or surfaces for them, you can go back
//                             and select them or save as it is.</p>
//                         <div className={s.popConfirm__deleteBtns}>
//                             <Button
//                                 type='primary'
//                                 htmlType='submit'
//                                 style={{
//                                     height: '44px',
//                                     backgroundColor: '#1755E7',
//                                     color: '#fff',
//                                     width: '170px',
//                                     border: '1px solid #1755E7',
//                                     display: 'flex',
//                                     alignItems: 'center',
//                                     justifyContent: 'center',
//
//                                 }}
//
//                                 onClick={() => setPopConfirmModalOpened(false)}
//                             >
//                                 Cancel
//                             </Button>
//                             <Button
//                                 type='default'
//                                 htmlType='submit'
//                                 style={{
//                                     width: '170px',
//                                     height: '44px',
//                                 }}
//                                 onClick={() => onCreateNewActivity(true)}
//                             >
//                                 {!editMode ? 'Add' : 'Save'}
//                             </Button>
//
//                         </div>
//                     </div>
//                 </div>
//             }
//         </div>
//     );
// };
//
//
// const CustomCell = React.memo(({
//                                    field,
//                                    value,
//                                    params,
//                                    properties,
//                                    dataType,
//
//                                }: any) => {
//     const dispatch = useAppDispatch()
//     const checkedReqIds = useAppSelector(selectActivitiesCreationCheckedReqIds)
//     const [disabledCheckbox, setDisabledCheckbox] = useState(false)
//     const currentRowValues = useAppSelector(selectActivitiesCurrentRow)
//     const checkedProperties = useAppSelector(selectCurrentActivitiesCheckedProperties)
//     const checkedSurfaces = useAppSelector(selectActivitiesCheckedSurfaces)
//     const currentGridTab = useAppSelector(selectActivitiesCurrentGridTab)
//     const checkedSurfaceIds = useAppSelector(selectActivitiesCheckedSurfacesIds)
//     const checkedPropertiesIds = useAppSelector(selectCurrentActivitiesCheckedPropertiesIds)
//     if (field === 'REF') {
//         const handleCheckProperties = (value: any) => {
//             dispatch(onAddActivitiesReqsPropsSurfaces({
//                 gridTypeByField: params.field,
//                 gridRow: params.row,
//                 entityId: params.formattedValue,
//                 isChecked: value.target.checked,
//                 contactListId: params.row.CONTACT_LIST
//             }))
//             setDisabledCheckbox(true)
//             dispatch(onSetCurrentModalTab('Existing Links'))
//             setTimeout(() => {
//                 setDisabledCheckbox(false)
//             }, 50)
//         }
//
//         return (
//             <div>
//                 <div style={{
//                     display: 'flex',
//                     justifyContent: 'space-between',
//                     width: '100%',
//                     alignItems: 'center',
//                 }}>
//                     <div style={{
//                         display: 'flex',
//                         alignItems: 'center'
//                     }}>
//                         {
//                             field === 'REF' && currentGridTab === 'Reqs'
//                             &&
//                             <>
//                                 <Checkbox
//                                     onChange={handleCheckProperties}
//                                     name={value}
//                                     checked={checkedReqIds.includes(value)}
//                                     disabled={disabledCheckbox || currentRowValues.ACT_TYPE === 'Documentation'}
//                                     style={{
//                                         marginRight: '8px'
//                                     }}
//                                 />
//                                 <div>{value}</div>
//                             </>
//
//                         }
//
//                     </div>
//                 </div>
//             </div>
//
//         )
//     } else if (field === 'PROP_REF') {
//
//
//         const handleCheckProperties = (value: any) => {
//             dispatch(onAddActivitiesReqsPropsSurfaces({
//                 gridTypeByField: params.field,
//                 gridRow: params.row,
//                 entityId: params.formattedValue,
//                 isChecked: value.target.checked,
//                 contactListId: params.row.CONTACT_LIST
//             }))
//             setDisabledCheckbox(true)
//             dispatch(onSetCurrentModalTab('Existing Links'))
//             setTimeout(() => {
//                 setDisabledCheckbox(false)
//             }, 50)
//         }
//
//
//         return (
//             <div>
//                 <div style={{
//                     display: 'flex',
//                     justifyContent: 'space-between',
//                     width: '100%',
//                     alignItems: 'center',
//                 }}>
//                     <div style={{
//                         display: 'flex',
//                         alignItems: 'center'
//                     }}>
//                         {
//                             field === 'PROP_REF' && currentGridTab === 'Properties'
//                             &&
//                             checkedProperties.some((obj: any) => obj?.PROP_REF === value && obj?.REQ_REF !== params.row.REQ_REF)
//                                 ?
//                                 <>
//                                     <Tooltip
//                                         text="You can pick only one property with the same property id from different reqs"
//                                         classname={'activitiesCheckboxTooltip'}>
//                                         <Checkbox
//                                             onChange={handleCheckProperties}
//                                             name={value}
//                                             // checked={isPropertyInObjectsArray(value, params.row.REQ_REF)}
//                                             checked={checkedPropertiesIds.includes(value)}
//                                             disabled={true}
//                                             style={{
//                                                 marginRight: '8px'
//                                             }}
//                                         />
//                                         <div>{value}</div>
//                                     </Tooltip>
//                                 </>
//
//                                 :
//                                 field === 'PROP_REF' && currentGridTab === 'Properties'
//                                     ?
//                                     <>
//                                         <Checkbox
//                                             onChange={handleCheckProperties}
//                                             name={value}
//                                             // checked={isPropertyInObjectsArray(value, params.row.REQ_REF)}
//                                             checked={checkedPropertiesIds.includes(value)}
//                                             disabled={
//                                                 disabledCheckbox
//                                                 || currentRowValues.ACT_TYPE === 'Documentation'}
//                                             style={{
//                                                 marginRight: '8px'
//                                             }}
//                                         />
//                                         <div>{value}</div>
//                                     </>
//                                     :
//                                     <div>{value}</div>
//
//                         }
//                     </div>
//                 </div>
//             </div>
//
//         )
//     } else if (field === 'SURF_REF') {
//
//
//         const handleCheckProperties = (value: any) => {
//             dispatch(onAddActivitiesReqsPropsSurfaces({
//                 gridTypeByField: params.field,
//                 gridRow: params.row,
//                 entityId: params.formattedValue,
//                 isChecked: value.target.checked,
//                 contactListId: params.row.CONTACT_LIST
//             }))
//             setDisabledCheckbox(true)
//             dispatch(onSetCurrentModalTab('Existing Links'))
//             setTimeout(() => {
//                 setDisabledCheckbox(false)
//             }, 50)
//         }
//
//         return (
//             <div>
//                 <div style={{
//                     display: 'flex',
//                     justifyContent: 'space-between',
//                     width: '100%',
//                     alignItems: 'center',
//                 }}>
//                     <div style={{
//                         display: 'flex',
//                         alignItems: 'center'
//                     }}>
//                         {
//                             field === 'SURF_REF' && currentGridTab === 'Surfaces'
//                             &&
//                             checkedSurfaces.some((obj: any) => obj?.SURF_REF === value && obj?.REQ_REF !== params.row.REQ_REF)
//                                 ?
//                                 <>
//                                     <Tooltip
//                                         text="You can pick only one surface with the same property id from different reqs"
//                                         classname={'activitiesCheckboxTooltip'}>
//                                         <Checkbox
//                                             onChange={handleCheckProperties}
//                                             name={value}
//                                             // checked={isSurfaceInObjectsArray(value, params.row.REQ_REF)}
//                                             checked={checkedSurfaceIds.includes(value)}
//                                             disabled={true}
//                                             style={{
//                                                 marginRight: '8px'
//                                             }}
//                                         />
//                                         <div>{value}</div>
//                                     </Tooltip>
//                                 </>
//
//                                 :
//                                 field === 'SURF_REF' && currentGridTab === 'Surfaces'
//                                     ?
//                                     <>
//                                         <Checkbox
//                                             onChange={handleCheckProperties}
//                                             name={value}
//                                             checked={checkedSurfaceIds.includes(value)}
//                                             disabled={disabledCheckbox
//                                                 || currentRowValues.ACT_TYPE === 'Documentation'}
//                                             style={{
//                                                 marginRight: '8px'
//                                             }}
//                                         />
//                                         <div>{value}</div>
//                                     </>
//                                     :
//                                     <div>{value}</div>
//
//                         }
//                     </div>
//                 </div>
//             </div>
//
//         )
//     } else {
//         if (dataType === 'class java.math.BigDecimal' && field !== 'PROP_REF' && field !== 'SURF_REF' && field !== 'REQ_REF') {
//             const europeanString = value !== null ? value?.toLocaleString('de-DE') : '';
//             return <div>{europeanString}</div>;
//         } else {
//             return <div>{value}</div>;
//         }
//
//     }
// });
//
//
// const CustomGridRow = React.memo((props: any) => {
//     const dispatch = useAppDispatch()
//     const {row} = props;
//     const checkedSurfacesIds = useAppSelector(selectActivitiesCheckedSurfacesIds)
//     const [disabledCheckbox, setDisabledCheckbox] = useState(false)
//     const currentRowValues = useAppSelector(selectActivitiesCurrentRow)
//     const handleCheckSurfaces = (value: any) => {
//         setDisabledCheckbox(true)
//         dispatch(onSetCurrentModalTab('Existing Links'))
//         if (value.target.checked === true) {
//             dispatch(onCheckSurfacesIds({
//                 surfaceId: Number(value.target.value.VALUE_01),
//                 isChecked: value.target.checked
//             }))
//             dispatch(onCheckSurfaces({surface: value.target.value, isChecked: value.target.checked}))
//         } else {
//             dispatch(onCheckSurfacesIds({
//                 surfaceId: Number(value.target.value.VALUE_01),
//                 isChecked: value.target.checked
//             }))
//             dispatch(onCheckSurfaces({surface: value.target.value, isChecked: value.target.checked}))
//         }
//         setTimeout(() => {
//             setDisabledCheckbox(false)
//         }, 50)
//     }
//
//     return (
//         <div className={propertiesStyles.customRow}>
//             <GridRow {...props} />
//             {
//                 row.isOpened
//                     ?
//                     <div className={propertiesStyles.surfaceInner}>
//                         {row.surfaces.map((surf: any, index: number) => {
//                             return (
//                                 <div key={index} style={{
//                                     display: 'flex',
//                                     alignItems: 'center'
//                                 }}>
//                                     <Checkbox
//                                         onChange={handleCheckSurfaces}
//                                         name={row.PROP_ID}
//                                         value={surf}
//                                         checked={checkedSurfacesIds.includes(Number(surf.VALUE_01))}
//                                         style={{
//                                             marginRight: '8px'
//                                         }}
//                                         disabled={disabledCheckbox || currentRowValues.ACT_TYPE === 'Documentation'}
//                                     />
//                                     <span
//                                         className={propertiesStyles.surfaceList}>REF {surf?.VALUE_01} | {surf?.LABEL_02} {surf?.VALUE_02} | {surf?.LABEL_03} {surf?.VALUE_03} | {surf?.LABEL_04} {surf?.VALUE_04} | {surf?.LABEL_05} {surf?.VALUE_05} | {surf?.LABEL_06} {surf?.VALUE_06}</span>
//                                 </div>
//                             )
//                         })}
//
//                     </div>
//                     :
//                     null
//             }
//         </div>
//     );
// });
//
//
// export default OrganizationActivitiesGrid;

import React, {useEffect, useLayoutEffect, useState} from 'react';
import PageWithSidebar from "../PageWithSidebar/PageWithSidebar";
import CommonActivityModal from "../common/CommonActivityModal/CommonActivityModal";
import ActivitiesGridForm from "../ActivitiesGridForm/ActivitiesGridForm";
import PageTitle from "../common/PageTitle/PageTitle";
import s from './OrganizationActivitiesGrid.module.scss'
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    ActivitiesGetDataMode,
    ActivitiesGridCheckIsPasswordNeededThunk,
    ActivityPropertyType,
    CreateNewActivityThunk,
    GetActivitiesPropertiesThunk,
    GetActivitiesRefLinksThunk,
    GetActivitiesRequirements,
    GetActivitiesSurfacesThunk,
    onAddActivitiesReqsPropsSurfaces,
    onChangeActivitiesGridColumnsPosition,
    onChangeActivitiesGridColumnsWidth,
    onChangeActivitiesGridMode,
    onChangeActivitiesPropertiesSearchValue,
    onChangeCurrentGridTab,
    onCheckSurfaces,
    onCheckSurfacesIds,
    onDeleteCheckedProperty,
    onDeleteCheckedReq,
    onDeleteCheckedSurface,
    onSetActivitiesCreationLocalSortModel,
    onSetActivitiesCreationSortingString,
    onSetActivitiesCurrentRow,
    onSetActivitiesIsDataLoading,
    onSetActivitiesIsPropsLoading,
    onSetActivitiesIsSurfacesLoading,
    onSetActivitiesProperties,
    onSetActivitiesSurfacesUpdated,
    onSetCurrentModalTab,
    onSetIsDescriptionFieldError,
    onSetIsSavingButtonDisabled,
    selectActivitiesCheckedSurfaces,
    selectActivitiesCheckedSurfacesIds,
    selectActivitiesCreationCheckedReqIds,
    selectActivitiesCreationGridHiddenColumns,
    selectActivitiesCreationGridPropColumns,
    selectActivitiesCreationGridPropRows,
    selectActivitiesCreationGridReqColumns,
    selectActivitiesCreationGridReqRows,
    selectActivitiesCreationGridShownColumns,
    selectActivitiesCreationGridSurfColumns,
    selectActivitiesCreationGridSurfRows,
    selectActivitiesCreationPropertiesSortModel,
    selectActivitiesCreationReqsSortModel,
    selectActivitiesCreationSurfacesSortModel,
    selectActivitiesCurrentGridTab,
    selectActivitiesCurrentRow,
    selectActivitiesGetDataMode,
    selectActivitiesIsDataLoading,
    selectActivitiesIsPropsLoading,
    selectActivitiesIsSurfacesLoading,
    selectActivitiesKindNumberEntity,
    selectActivitiesModalFormFields,
    selectActivitiesPropertiesDataError,
    selectActivitiesSearchValue,
    selectActivitiesSurfacesDataError,
    selectActivitiesTypeKind,
    selectActivitiesTypeNumberEntity,
    selectActivitiesTypes,
    selectActivityEditMode,
    selectActivityFormContacts,
    selectActivityModalCurrentTab,
    selectAllActivitiesEntitiesArray,
    selectAllPropertiesShown,
    selectAllSurfacesShown,
    selectContactId,
    selectCurrentActivitiesCheckedProperties,
    selectCurrentActivitiesCheckedPropertiesIds,
    selectCurrentActivityPropertiesAndSurfaces,
    selectCurrentCompanyRef,
    selectCurrentContactRef,
    selectOrganizationId,
    setActivitiesCreationGridHiddenColumns,
    setActivitiesCreationGridShownColumns,
    setActivitiesProperties,
    setActivitiesPropertiesGridError, setActivitiesSurfaces,
    setActivitiesSurfacesGridError,
    setAllPropertiesShown,
    setAllSurfacesShown,
    UpdateExistingActivityThunk
} from "../../store/activitesGrid";
import {Button, Checkbox, Empty, Input, message, Select, Switch} from "antd";
import commonS from "../RequirementsPage/CommonTableStyles.module.scss";
import PropertiesSearchInput from "../PropertiesCommonComponents/PropertiesSearchInput/PropertiesSearchInput";
import Box from "@mui/material/Box";
import {DataGridPro, GridColumnMenu, GridRow, useGridApiRef} from "@mui/x-data-grid-pro";
import {Lottie} from "@crello/react-lottie";
import animatedLoader from "../../img/Loader.json";
import {
    onSetContactPropertiesSearchedAddress,
    onSetLocalSortModel,
    onSetPropertiesGridLoaderMessage,
    onSetReqGridHeight,
    ResetAllGridConfigsThunk,
} from "../../store/propertiesReducer";
import {useNavigate} from "react-router";
import {CreateAndUpdateActivityRequest, gridApi} from "../../app/api";
import moment from "moment";
import Tooltip from "../Tooltip/Tooltip";
import {selectUserData} from "../../store/userReducer";
import propertiesStyles from "../PropertiesPage/Properties.module.scss";
import PropertiesGridFooter from "../PropertiesCommonComponents/PropertiesGridFooter/PropertiesGridFooter";
import {FacebookCircularProgress} from "../AllPropertiesAddressForm/AllPropertiesAddressForm";
import {ReactComponent as WarningActivityIcon} from "../../img/icons/alert-triangle.svg";
import {
    GetMapPropertiesStatusesThunk,
    selectMapPropertiesStatuses
} from "../../store/propertiesMap";
import {customSortFunction, numericComparator} from "../../helpers/sortingDataHelper";
import PropertiesViewOptions from "../PropertiesCommonComponents/PropertiesViewOptions/PropertiesViewOptions";
import PropertiesHeaderButtons from "../PropertiesCommonComponents/PropertiesHeaderButtons/PropertiesHeaderButtons";
import {selectCompany} from "../../store/companies";
import PropertiesFilterButtons from "../PropertiesCommonComponents/PropertiesFilterButtons/PropertiesFilterButtons";
import {
    GetRequirementContactFunctionsThunk,
    RequirementFunction,
    selectRequirementFunctions
} from "../../store/requirementsReducer";


const OrganizationActivitiesGrid = () => {
    return (
        <PageWithSidebar>
            <OrganizationActivitiesPageComponent1/>
        </PageWithSidebar>
    )
}

type TabName = "Properties" | "Surfaces" | "Reqs";
type ActiveMode = {
    gridType: TabName;
    currentMode: string;
}


const OrganizationActivitiesPageComponent1 = () => {
    const currentTab = useAppSelector(selectActivityModalCurrentTab)
    const editMode = useAppSelector(selectActivityEditMode)
    const currentRowValues = useAppSelector(selectActivitiesCurrentRow)
    const [height, setHeight] = useState(window.innerHeight);
    const [gridContentHeight, setGridContentHeight] = useState(height - 340);
    const dispatch = useAppDispatch()
    const [searchTerm, setSearchTerm] = useState('');
    const [localSortModel, setLocalSortModel] = useState<any>({});
    const modalFormFields = useAppSelector(selectActivitiesModalFormFields)
    const navigate = useNavigate()
    const isDataLoading = useAppSelector(selectActivitiesIsDataLoading)
    const typeNumberEntity = useAppSelector(selectActivitiesTypeNumberEntity)
    const kindNumberEntity = useAppSelector(selectActivitiesKindNumberEntity)
    const contactId = useAppSelector(selectContactId)
    const allActivitiesEntities = useAppSelector(selectAllActivitiesEntitiesArray)
    const activitiesTypeKind = useAppSelector(selectActivitiesTypeKind)
    const activitiesTypes = useAppSelector(selectActivitiesTypes)
    const [loading, setIsLoading] = useState(false)
    const userData = useAppSelector(selectUserData)
    const currentGridTab = useAppSelector(selectActivitiesCurrentGridTab)
    const reqColumns = useAppSelector(selectActivitiesCreationGridReqColumns)
    const reqRows = useAppSelector(selectActivitiesCreationGridReqRows)
    const propColumns = useAppSelector(selectActivitiesCreationGridPropColumns)
    const propRows = useAppSelector(selectActivitiesCreationGridPropRows)
    const surfColumns = useAppSelector(selectActivitiesCreationGridSurfColumns)
    const surfRows = useAppSelector(selectActivitiesCreationGridSurfRows)
    const gridColumns = currentGridTab === 'Reqs' ? reqColumns : currentGridTab === 'Properties' ? propColumns : surfColumns
    const gridRows = currentGridTab === 'Reqs' ? reqRows : currentGridTab === 'Properties' ? propRows : surfRows
    const checkedReqIds = useAppSelector(selectActivitiesCreationCheckedReqIds)
    const propertiesAndSurfacesFromLinkData = useAppSelector(selectCurrentActivityPropertiesAndSurfaces)
    const [popConfirmModalOpened, setPopConfirmModalOpened] = useState(false)
    const isPropsLoading = useAppSelector(selectActivitiesIsPropsLoading)
    const isSurfacesLoading = useAppSelector(selectActivitiesIsSurfacesLoading)
    const organizationId = useAppSelector(selectOrganizationId)
    const companyRefForEdit = useAppSelector(selectCurrentCompanyRef)
    const companyRef = editMode ? companyRefForEdit : organizationId
    const allPropertiesShown = useAppSelector(selectAllPropertiesShown)
    const allSurfacesShown = useAppSelector(selectAllSurfacesShown)
    const checkedSurfaceIds = useAppSelector(selectActivitiesCheckedSurfacesIds)
    const checkedPropertiesIds = useAppSelector(selectCurrentActivitiesCheckedPropertiesIds)
    const mapStatuses = useAppSelector(selectMapPropertiesStatuses)
    const shownColumns = useAppSelector(selectActivitiesCreationGridShownColumns)
    const hiddenColumns = useAppSelector(selectActivitiesCreationGridHiddenColumns)
    const [currentStatus, setCurrentStatus] = useState(1)
    const [isProcessing, setIsProcessing] = useState(false);
    const [checked, setChecked] = useState<any>([]);
    const [filtersShown, setFiltersShown] = useState(false)
    const reqsSortModel = useAppSelector(selectActivitiesCreationReqsSortModel)
    const propertiesSortModel = useAppSelector(selectActivitiesCreationPropertiesSortModel)
    const surfacesSortModel = useAppSelector(selectActivitiesCreationSurfacesSortModel)
    const sortModel = currentGridTab === 'Reqs' ? reqsSortModel : currentGridTab === 'Properties' ? propertiesSortModel : surfacesSortModel
    const companyName = useAppSelector(selectCompany)
    const formContacts = useAppSelector(selectActivityFormContacts)
    const activitiesModes = useAppSelector(selectActivitiesGetDataMode)
    const currentContactRef = useAppSelector(selectCurrentContactRef)
    const reqFunctions = useAppSelector(selectRequirementFunctions)
    const [activeMode, setActiveMode] = useState<ActiveMode | null>(null);
    const [propertiesCurrentFunc, setPropertiesCurrentFunc] = useState<any>()
    const [surfacesCurrentFunc, setSurfacesCurrentFunc] = useState<any>()
    const propertiesSearchValue = useAppSelector(selectActivitiesSearchValue)
    const activityPropertiesGridError = useAppSelector(selectActivitiesPropertiesDataError)
    const activitySurfacesGridError = useAppSelector(selectActivitiesSurfacesDataError)
    console.log(activitiesModes)

    useEffect(() => {
        if(currentGridTab === 'Reqs'){
            dispatch(GetActivitiesRequirements({
                p_agent_ref: Number(userData.user_ref),
                p_sort_order: null
            }))
        }
        else if(currentGridTab === 'Properties'){
            if(activitiesModes[0].currentMode === 1){
                dispatch(GetActivitiesPropertiesThunk({
                    reqData: {
                        p_agent_ref: Number(userData.user_ref),
                        p_contact_ref: currentContactRef,
                        p_sort_order: null,
                        p_status_idx: Number(currentStatus),
                        p_function_idx: propertiesCurrentFunc && propertiesCurrentFunc !== null && propertiesCurrentFunc !== 'All' ? Number(propertiesCurrentFunc) : null
                    }, allPropertiesShown
                }))
                dispatch(GetRequirementContactFunctionsThunk())
            }
        }
        else if(currentGridTab === 'Surfaces'){
            if(activitiesModes[1].currentMode === 1){
                dispatch(GetActivitiesSurfacesThunk({
                    reqData: {
                        p_agent_ref: Number(userData.user_ref),
                        p_contact_ref: currentContactRef,
                        p_sort_order: null,
                        p_status_idx: currentStatus,
                        p_function_idx: surfacesCurrentFunc && surfacesCurrentFunc !== null && surfacesCurrentFunc !== 'All' ? Number(surfacesCurrentFunc) : null
                    }, allSurfacesShown
                }))
                dispatch(GetRequirementContactFunctionsThunk())
            }
            // else{
            //     dispatch(GetActivitiesSurfacesThunk({
            //         reqData: {
            //             p_agent_ref: Number(userData.user_ref),
            //             p_sort_order: null,
            //             p_status_idx: currentStatus,
            //         }, allSurfacesShown
            //     }))
            // }
        }
    }, [companyRef, currentStatus, userData, activitiesModes, currentGridTab, propertiesCurrentFunc, surfacesCurrentFunc])

    useEffect(() => {
        dispatch(ActivitiesGridCheckIsPasswordNeededThunk({
            list_code: 'activities',
            operation: 'D',
            password: 'act999'

        }))
    }, [dispatch])
    let columns = gridColumns.map((g: any) => {
        if (g.COL_NAME === "REQ_REF") {
            return {
                field: g.COL_NAME,
                headerName: g.COL_TITLE,
                width: g.COL_WIDTH,
                dataType: g.COL_TYPE,
                col_pos: 1,
                col_value: g.COL_VALUE,
                col_options: g.COL_OPTIONS,
            }
        } else if (g.COL_NAME === "SURFACE_EXISTED") {
            return {
                field: g.COL_NAME,
                headerName: g.COL_TITLE,
                width: 0,
                dataType: g.COL_TYPE,
                col_pos: 0,
                col_value: g.COL_VALUE,
                col_options: g.COL_OPTIONS,
            }
        } else {
            return {
                field: g.COL_NAME,
                headerName: g.COL_TITLE,
                width: g.COL_NAME === 'CONTACT_LIST' ? 1 : g.COL_WIDTH,
                dataType: g.COL_TYPE,
                col_pos: g.COL_POS,
                col_value: g.COL_VALUE,
                col_options: g.COL_OPTIONS,
            }
        }

    }).sort((a, b) => a.col_pos - b.col_pos)

    const handleSearchInputChange = (event: any) => {
        setSearchTerm(event.target.value);
    };
    const getRowClassName = (params: any) => {
        return (params.id % 2 === 0) ? 'even-row' : 'odd-row';
    };
    const apiRef = useGridApiRef();


    const debounce = (func: Function, wait: number) => {
        let timeout: ReturnType<typeof setTimeout> | null;
        return function (this: any, ...args: any[]) {
            const context = this;
            clearTimeout(timeout!);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    };

    useLayoutEffect(() => {
        const handleResize = debounce(function () {
            setHeight(window.innerHeight);
            const heightForPage = window.innerHeight;
            setGridContentHeight(heightForPage - 340);
            dispatch(onSetReqGridHeight(heightForPage))
        }, 100);

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    let sortedRows = customSortFunction(gridRows, sortModel);

    const handleSortModelChange = (newModel: any) => {
        const newLocalModel: any = {...localSortModel};
        newModel.forEach((model: any) => {
            newLocalModel[model.field] = model;
        });
        setLocalSortModel(newLocalModel);
        const mergedArray = [...sortModel, ...newModel];

        // Find the index of the matching field object in the merged array
        const index = mergedArray.findIndex(item => item?.field === sortModel[0]?.field);

        // Check if the sort value is different and update it
        if (index !== -1 && mergedArray[index]?.sort !== newModel[0]?.sort) {
            // eslint-disable-next-line
            const updatedObject = {
                ...mergedArray[index],
                sort: newModel[0]?.sort
            };
            mergedArray[index] = updatedObject;
        }

        dispatch(onSetActivitiesCreationLocalSortModel({sort_model: mergedArray, gridType: currentGridTab}))
    };


    useEffect(() => {
        if (editMode) {
            dispatch(GetActivitiesRefLinksThunk(currentRowValues.REF!))
        }
    }, [dispatch, editMode, currentRowValues.REF!])
    const onCloseModal = () => {
        navigate('/activitiesGrid')
    }

    const onCreateNewActivity = (createWithoutProp?: boolean) => {
        if (isProcessing) return;
        setIsProcessing(true);
        const kindNumberEntityForEdit = activitiesTypeKind.find((t) => t.VALUE === modalFormFields.kind)
        const maxListLength = checkedPropertiesIds.length + checkedSurfaceIds.length + checkedReqIds.length;
        const typeNumberEntityForEdit = activitiesTypes.find((t) => t.AE_LI_VALUE === modalFormFields.type)
        const link_type_list: any = []
        // const link_entity_list = !editMode ? [] : allActivitiesEntities.map((c: any) => c.link_entity_list).filter(item => !checkedPropertiesIds.includes(item) && !checkedSurfaceIds.includes(item) && !checkedReqIds.includes(item))
        const link_entity_list: any = []
        let link_ref_list = !editMode ? Array.from({length: maxListLength + 2}, () => null) : allActivitiesEntities.filter((i: any) => i.link_type_list !== 2 || i.link_type_list !== 3).map((c: any) => c.link_ref_list)
        console.log(allActivitiesEntities)
        const orgAndContactIds = allActivitiesEntities.filter((c: any) => c.link_type_list === 3 || c.link_type_list === 2)
        const orgNadCompanyId = allActivitiesEntities.map((c: any) => c.link_entity_list).filter(item => !checkedPropertiesIds.includes(item) && !checkedSurfaceIds.includes(item) && !checkedReqIds.includes(item))
        const refListEntityIds = allActivitiesEntities.filter((i: any) => i.link_type_list === 2 || i.link_type_list === 3).map((c: any) => c.link_ref_list)
        console.log(orgAndContactIds)
        for (let i = 0; i < maxListLength; i++) {
            if (i < checkedReqIds.length) {
                link_type_list.unshift(1);
            }
            if (i < checkedPropertiesIds.length) {
                link_type_list.push(0);
            }
            if (i < checkedSurfaceIds.length) {
                link_type_list.push(14);
            }
        }
        for (let i = 0; i < maxListLength; i++) {
            if (i < checkedReqIds.length) {
                link_entity_list.unshift(checkedReqIds[i]);
            }
            if (i < checkedPropertiesIds.length) {
                link_entity_list.push(checkedPropertiesIds[i]);
            }
            if (i < checkedSurfaceIds.length) {
                link_entity_list.push(checkedSurfaceIds[i]);
            }
        }
        if (!editMode) {
            link_type_list.unshift(2, 3)
            link_entity_list.unshift(organizationId, contactId)
        } else {
            const originalEntityList = allActivitiesEntities.filter((i: any) => i.link_type_list !== 2 || i.link_type_list !== 3).map(item => item.link_entity_list)
            let newRefList: any = [];
            link_entity_list.forEach((entity: any) => {
                const index = originalEntityList.indexOf(entity);
                if (index !== -1) {
                    // If the entity exists in the original list, use its original ref_list value
                    newRefList.push(link_ref_list[index]);
                } else {
                    // If the entity is new (not in the original list), push null
                    newRefList.push(null);
                }
            });

            // Assign the newly built list to refList
            link_ref_list = newRefList;
            // link_type_list.unshift(2, 3)
            // orgNadCompanyId.forEach((id: number) => {
            //     link_entity_list.unshift(id)
            //     console.log(id)
            // })
            orgAndContactIds.forEach((orgOrContact: any) => {
                if(orgOrContact.link_type_list === 3){
                    link_entity_list.unshift(orgOrContact.link_entity_list)
                    link_type_list.unshift(3)
                }
                else if(orgOrContact.link_type_list === 2){
                    link_entity_list.unshift(orgOrContact.link_entity_list)
                    link_type_list.unshift(2)
                }
            })
            console.log(refListEntityIds)
            refListEntityIds.forEach((id: number) => {
                console.log(id)
                link_ref_list.unshift(id)
            })
        }
        dispatch(onSetIsSavingButtonDisabled(true))
        setIsLoading(true)
        const req: CreateAndUpdateActivityRequest = {
            date_begin: moment(modalFormFields.date_from),
            date_end: moment(modalFormFields.date_till),
            description: modalFormFields.description !== '' ? modalFormFields.description : '',
            link_entity_list: link_entity_list,
            link_ref_list: link_ref_list,
            link_type_list: link_type_list,
            priority: modalFormFields.urgency === 'low' ? 0 : modalFormFields.urgency === 'avg' ? 1 : 2,
            remark: modalFormFields.remark !== '' ? modalFormFields.remark : '',
            type: !editMode ? typeNumberEntity : typeNumberEntityForEdit?.AE_NO_INDEX,
            type_kind: !editMode ? kindNumberEntity : kindNumberEntityForEdit?.CODE
        }
        setTimeout(() => {
            const kindNumberEntityForEdit = activitiesTypeKind.find((t) => t.VALUE === modalFormFields.kind)
            const isPropRequired = activitiesTypeKind && activitiesTypeKind.find((a: any) => Number(a.CODE!) === (!editMode ? Number(kindNumberEntity) : Number(kindNumberEntityForEdit?.CODE)))
            if (isPropRequired?.PROP_REQUIRED === 0) {
                if (modalFormFields.description !== '') {
                    dispatch(onSetIsSavingButtonDisabled(true))
                    setIsLoading(true)
                    if (!editMode) {
                        dispatch(CreateNewActivityThunk({activityData: req}))
                    } else {
                        dispatch(UpdateExistingActivityThunk({requestData: req, reqRef: currentRowValues.REF!}))
                    }
                } else {
                    dispatch(onSetIsDescriptionFieldError(true))
                    dispatch(onSetCurrentModalTab('General'))
                    setTimeout(() => {
                        dispatch(onSetIsSavingButtonDisabled(false))
                        setIsLoading(false)
                    }, 50)

                }
            } else {
                if (!checkedPropertiesIds.length && !checkedSurfaceIds.length) {
                    message.warn('You need to select at least one property or surface in order to create activity with this type', 10)
                    setIsLoading(false)
                } else {
                    if (modalFormFields.description !== '') {
                        dispatch(onSetIsSavingButtonDisabled(true))
                        setIsLoading(true)
                        if (!editMode) {
                            dispatch(CreateNewActivityThunk({activityData: req}))
                        } else {
                            dispatch(UpdateExistingActivityThunk({requestData: req, reqRef: currentRowValues.REF!}))
                        }
                    } else {
                        dispatch(onSetIsDescriptionFieldError(true))
                        dispatch(onSetCurrentModalTab('General'))
                        setTimeout(() => {
                            dispatch(onSetIsSavingButtonDisabled(false))
                            setIsLoading(false)
                        }, 50)
                    }
                }
            }
            setIsProcessing(false);
        }, 100);


    }


    const handlePageChange = (params: any, value: number) => {
        setPage(value);
        afterPaginationPageChangeScrollToTop();
    }

    const afterPaginationPageChangeScrollToTop = () => {
        const gridApi = apiRef.current;
        if (!gridApi) return;
        gridApi.scrollToIndexes({rowIndex: 0});
    };

    function handleRowsPerPageChange(event: any) {
        setRowsPerPage(event);
    }

    const onChangeGridTab = (gridType: 'Reqs' | 'Properties' | 'Surfaces') => {
        setLocalSortModel({})
        dispatch(onChangeCurrentGridTab(gridType))

    }

    const rows = sortedRows.reduce((acc, row) => {
        const values = Object.values(row);
        const useStartsWith = searchTerm.endsWith('%');
        const processedSearchTerm = searchTerm.slice(0, -1).toLowerCase();
        if (values.some(value => {
            const stringValue = String(value).toLowerCase();
            return useStartsWith ? stringValue.startsWith(processedSearchTerm) : stringValue.includes(searchTerm.toLowerCase());
        })) {
            acc.push(row);
        }
        return acc;
    }, []);

    const rowsPerPageOptions = [50, 100, 150, 200, 500];
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[2]);
    const [page, setPage] = React.useState(1);
    const isActivityTypeKindWithRightValue = activitiesTypeKind && activitiesTypeKind.some((a: any) => a.VALUE! === currentRowValues.ACT_KIND || a.VALUE! === modalFormFields.kind)

    let gridRowsPaginated = rows.slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage);

    useEffect(() => {
        if (propertiesAndSurfacesFromLinkData.length) {
            const properties: ActivityPropertyType[] = []
            const surfaces: ActivityPropertyType[] = []
            propertiesAndSurfacesFromLinkData.forEach((obj: any) => {
                if (obj?.entityType === 'property') {
                    const matchedItem = propRows.find((p: any) => Number(obj?.property_id) === Number(p.PROP_REF) && Number(obj?.req_id) === Number(p.REQ_REF))
                    if (matchedItem) {
                        properties.push({...matchedItem, entityListEntityId: obj.entityId})
                    }
                } else {
                    const matchedItem = surfRows.find((p: any) => Number(obj?.property_id) === Number(p.SURF_REF) && Number(obj?.req_id) === Number(p.REQ_REF))

                    if (matchedItem) {
                        surfaces.push({...matchedItem, entityListEntityId: obj.entityId})
                    }
                }
            })
            dispatch(onSetActivitiesProperties(properties))
            dispatch(onSetActivitiesSurfacesUpdated(surfaces))
        }
    }, [propertiesAndSurfacesFromLinkData, propRows, surfRows])


    const onRowClick = (row: any) => {
        if (row.row.REF !== null && !editMode && currentGridTab === 'Reqs') {
            dispatch(onSetActivitiesCurrentRow(row.row))
            navigate('/organizationActivitiesProperties')
        }
    }

    const onSwitchChange = (value: boolean, type: 'Property' | 'Surface') => {
        if (type === 'Property') {
            dispatch(setAllPropertiesShown(value))
        } else {
            dispatch(setAllSurfacesShown(value))
        }

    }

    const selectMapStatuses = mapStatuses.map((status: any) => ({
        value: `${status.AE_NO_INDEX} `,
        label: `${status.AE_LI_VALUE}`,
    })).concat({value: `All`, label: `All`,})



    const selectReqFunctions = reqFunctions.map((func: RequirementFunction) => ({
        value: `${func.AE_NO_INDEX} `,
        label: `${func.AE_LI_VALUE}`,
    })).concat({value: `All`, label: `All`,})

    useEffect(() => {
        dispatch(GetMapPropertiesStatusesThunk())
    }, [])

    const handleStatusChange = (value: string) => {
        setCurrentStatus(Number(value))
        if(currentGridTab === 'Properties'){
            if(propertiesSearchValue !== null && propertiesSearchValue !== ''){
                dispatch(GetActivitiesPropertiesThunk({
                    reqData: {
                        p_agent_ref: Number(userData.user_ref),
                        p_sort_order: null,
                        p_status_idx: Number(value),
                        p_street_address: propertiesSearchValue
                    }, allPropertiesShown
                }))
            }
        }
        else if(currentGridTab === 'Surfaces'){
            if(propertiesSearchValue !== null && propertiesSearchValue !== ''){
                dispatch(GetActivitiesSurfacesThunk({
                    reqData: {
                        p_agent_ref: Number(userData.user_ref),
                        p_sort_order: null,
                        p_status_idx: Number(value),
                        p_street_address: propertiesSearchValue
                    }, allSurfacesShown
                }))
            }
        }
    }

    const handleFunctionChange = (value: string) => {
        if(currentGridTab === 'Properties'){
            setPropertiesCurrentFunc(value)
        }
        else if(currentGridTab === 'Surfaces'){
            setSurfacesCurrentFunc(value)
        }
    }
    console.log(propertiesCurrentFunc)
    useEffect(() => {
        const sortModelObject: any = {};
        for (const item of sortModel) {
            sortModelObject[item.field] = {
                field: item.field,
                sort: item.sort
            };
        }
        setLocalSortModel(sortModelObject)
    }, [sortModel])

    // CONTACTS
    // REQ_REFS
    useEffect(() => {
        if (columns.length) {
            dispatch(setActivitiesCreationGridShownColumns({
                columns: columns.filter((c: any) => c?.width !== 0).map((c: any, index: number) => {
                    if (c.field === 'ACTIVITY_EXISTED') {
                        return {
                            field: 'ACTIVITY_EXISTED',
                            headerName: 'activity',
                            width: 100,
                            checked: false,
                            id: index,
                            dataType: "class java.lang.String",
                            isFilterSelected: false,
                            col_pos: 2,
                            col_value: c.col_value,
                            col_options: c.col_options,
                            withSurface: c.withSurface,
                            key: index,
                        }
                    } else if (c.field === 'SURFACE_EXISTED') {
                        return {
                            field: 'SURFACE_EXISTED',
                            headerName: 'SURFACE_EXISTED',
                            width: 0,
                            checked: false,
                            id: index,
                            dataType: c.dataType,
                            isFilterSelected: false,
                            col_pos: -1,
                            col_value: c.col_value,
                            col_options: c.col_options,
                            withSurface: c.withSurface,
                            key: index
                        }
                    } else if (c.field === 'CONTACTS' && (allSurfacesShown || allPropertiesShown)) {
                        return {
                            field: 'CONTACTS',
                            headerName: 'CONTACTS',
                            width: 0,
                            checked: false,
                            id: index,
                            dataType: c.dataType,
                            isFilterSelected: false,
                            col_pos: -1,
                            col_value: c.col_value,
                            col_options: c.col_options,
                            withSurface: c.withSurface,
                            key: index,
                            hide: true
                        }
                    } else if (c.field === 'REQ_REFS' && (allSurfacesShown || allPropertiesShown)) {
                        return {
                            field: 'REQ_REFS',
                            headerName: 'REQ_REFS',
                            width: 0,
                            checked: false,
                            id: index,
                            dataType: c.dataType,
                            isFilterSelected: false,
                            col_pos: -1,
                            col_value: c.col_value,
                            col_options: c.col_options,
                            withSurface: c.withSurface,
                            key: index,
                            hide: true
                        }
                    } else if (c.field !== 'ACTIVITY_EXISTED') {
                        if (c.dataType === 'class java.math.BigDecimal') {
                            return {
                                field: c.field,
                                headerName: c.headerName,
                                width: c.width,
                                checked: false,
                                id: index,
                                dataType: c.dataType,
                                isFilterSelected: false,
                                col_pos: c.col_pos,
                                col_value: c.col_value,
                                col_options: c.col_options,
                                withSurface: c.withSurface,
                                key: index,
                                sortComparator: numericComparator
                            }
                        } else if (c.field === 'ADDR_LONGI' || c.field === 'ADDR_LATI') {
                            return {
                                field: c.field,
                                headerName: c.headerName,
                                width: c.width,
                                checked: false,
                                id: index,
                                dataType: c.dataType,
                                isFilterSelected: false,
                                col_pos: columns[columns.length - 1],
                                col_value: c.col_value,
                                col_options: c.col_options,
                                withSurface: c.withSurface,
                                key: index
                            }
                        } else {
                            return {
                                field: c.field,
                                headerName: c.headerName,
                                width: c.width,
                                checked: false,
                                id: index,
                                dataType: c.dataType,
                                isFilterSelected: false,
                                col_pos: c.col_pos,
                                col_value: c.col_value,
                                col_options: c.col_options,
                                withSurface: c.withSurface,
                                key: index,
                            }
                        }

                    }

                })
            }))
            dispatch(setActivitiesCreationGridHiddenColumns({
                columns: columns.filter((c: any) => c?.width === 0).map((c: any, index: number) => {
                    if (c.field === 'ACTIVITY_EXISTED') {
                        return {
                            field: 'ACTIVITY_EXISTED',
                            headerName: 'activity',
                            width: 0,
                            checked: false,
                            id: index,
                            dataType: "class java.lang.String",
                            isFilterSelected: false,
                            col_pos: 1,
                            col_value: c.col_value,
                            col_options: c.col_options,
                            withSurface: c.withSurface,
                            key: index,
                        }
                    } else if (c.field === 'SURFACE_EXISTED') {
                        return {
                            field: 'SURFACE_EXISTED',
                            headerName: 'SURFACE_EXISTED',
                            width: 0,
                            checked: false,
                            id: index,
                            dataType: c.dataType,
                            isFilterSelected: false,
                            col_pos: -1,
                            col_value: c.col_value,
                            col_options: c.col_options,
                            withSurface: c.withSurface,
                            key: index
                        }
                    } else if (c.field === 'CONTACTS' && (allSurfacesShown || allPropertiesShown)) {
                        return {
                            field: 'CONTACTS',
                            headerName: 'CONTACTS',
                            width: 0,
                            checked: false,
                            id: index,
                            dataType: c.dataType,
                            isFilterSelected: false,
                            col_pos: -1,
                            col_value: c.col_value,
                            col_options: c.col_options,
                            withSurface: c.withSurface,
                            key: index,
                            hide: true
                        }
                    } else if (c.field === 'REQ_REFS' && (allSurfacesShown || allPropertiesShown)) {
                        return {
                            field: 'REQ_REFS',
                            headerName: 'REQ_REFS',
                            width: 0,
                            checked: false,
                            id: index,
                            dataType: c.dataType,
                            isFilterSelected: false,
                            col_pos: -1,
                            col_value: c.col_value,
                            col_options: c.col_options,
                            withSurface: c.withSurface,
                            key: index,
                            hide: true
                        }
                    } else if (c.field === 'SURFACE_EXISTED') {
                        return {
                            field: 'SURFACE_EXISTED',
                            headerName: 'SURFACE_EXISTED',
                            width: 0,
                            checked: false,
                            id: index,
                            dataType: c.dataType,
                            isFilterSelected: false,
                            col_pos: -1,
                            col_value: c.col_value,
                            col_options: c.col_options,
                            withSurface: c.withSurface,
                            key: index
                        }
                    } else if (c.dataType === 'class java.math.BigDecimal') {
                        return {
                            field: c.field,
                            headerName: c.headerName,
                            width: 0,
                            checked: false,
                            id: index,
                            dataType: c.dataType,
                            isFilterSelected: false,
                            col_pos: c.col_pos,
                            col_value: c.col_value,
                            col_options: c.col_options,
                            withSurface: c.withSurface,
                            key: index,
                            sortComparator: numericComparator
                        }
                    } else {
                        return {
                            field: c.field,
                            headerName: c.headerName,
                            width: 0,
                            checked: false,
                            id: index,
                            dataType: c.dataType,
                            isFilterSelected: false,
                            col_pos: c.col_pos,
                            col_value: c.col_value,
                            col_options: c.col_options,
                            withSurface: c.withSurface,
                            key: index,
                        }
                    }
                })
            }))
            setChecked(columns.filter((c: any) => c?.width === 0).map((c: any) => c.field))
        }

        // eslint-disable-next-line
    }, [gridColumns, dispatch])


    const onResetConfigs = () => {
        dispatch(setActivitiesCreationGridShownColumns({columns: []}))
        dispatch(onSetActivitiesIsDataLoading(true))
        setSearchTerm('')
        setLocalSortModel({})
        if (currentGridTab === 'Reqs') {
            dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Reqs'}))
            dispatch(onSetActivitiesCreationSortingString({sorting_string: null, gridType: 'Reqs'}))
            dispatch(onSetActivitiesIsDataLoading(true))
        } else if (currentGridTab === 'Properties') {
            dispatch(onSetActivitiesCreationSortingString({sorting_string: null, gridType: 'Properties'}))
            dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Properties'}))
            dispatch(onSetActivitiesIsPropsLoading(true))
        } else {
            dispatch(onSetActivitiesCreationSortingString({sorting_string: null, gridType: 'Surfaces'}))
            dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Surfaces'}))
            dispatch(onSetActivitiesIsSurfacesLoading(true))
        }
        dispatch(ResetAllGridConfigsThunk({
            p_agent_ref: Number(userData.user_ref),
            p_row_req: null,
            grid_type: currentGridTab === 'Reqs' ? 'ACTIVITY_REQ_DATA' : currentGridTab === 'Properties' ? 'ACTIVITY_PROP_DATA' : 'ACTIVITY_SURF_DATA'
        }))
            .then(() => {
                if (currentGridTab === 'Reqs') {
                    if (companyRef) {
                        dispatch(GetActivitiesRequirements({
                            p_agent_ref: Number(userData.user_ref),
                            p_company_ref: companyRef,
                            p_sort_order: null
                        }))

                    } else {
                        dispatch(GetActivitiesRequirements({
                            p_agent_ref: Number(userData.user_ref),
                            p_company_ref: null,
                            p_sort_order: null
                        }))
                    }
                } else if (currentGridTab === 'Properties') {
                    if (!allPropertiesShown && companyRef) {
                        dispatch(GetActivitiesPropertiesThunk({
                            reqData: {
                                p_agent_ref: Number(userData.user_ref),
                                p_company_ref: companyRef,
                                p_sort_order: null,
                                p_status_idx: Number(currentStatus)
                            }, allPropertiesShown
                        }))
                    } else if (allPropertiesShown && allPropertiesShown !== null) {
                        dispatch(GetActivitiesPropertiesThunk({
                            reqData: {
                                p_agent_ref: Number(userData.user_ref),
                                p_company_ref: null,
                                p_sort_order: null,
                                p_status_idx: Number(currentStatus)
                            }, allPropertiesShown
                        }))

                    }
                } else {

                    if (!allSurfacesShown && companyRef) {
                        dispatch(GetActivitiesSurfacesThunk({
                            reqData: {
                                p_agent_ref: Number(userData.user_ref),
                                p_company_ref: companyRef,
                                p_sort_order: null,
                                p_status_idx: currentStatus
                            }, allSurfacesShown
                        }))
                    } else if (allSurfacesShown && allSurfacesShown !== null) {
                        dispatch(GetActivitiesSurfacesThunk({
                            reqData: {
                                p_agent_ref: Number(userData.user_ref),
                                p_company_ref: null,
                                p_sort_order: null,
                                p_status_idx: currentStatus
                            }, allSurfacesShown
                        }))
                    }


                }
            })
        dispatch(onSetPropertiesGridLoaderMessage('Reset data is in progress'))
    }
    const onSaveGridConfigs = () => {
        // dispatch(onSetActivitiesIsDataLoading(true))
        let hiddenColumnsResult;
        let shownColumnsResult;
        if (hiddenColumns.length) {
            hiddenColumnsResult = hiddenColumns.map((yItem: any, index: number) => {
                let xMatch = gridColumns.find((xItem: any) => xItem.headerName === yItem.headerName);
                // @ts-ignore
                xMatch = {...xMatch, COL_WIDTH: 0, COL_POS: shownColumns.length + index}
                return xMatch ? {...yItem, ...xMatch} : yItem;
            });
        } else if (!hiddenColumns.length) {
            hiddenColumnsResult = []
        }
        if (shownColumns.length) {
            shownColumnsResult = shownColumns.map((yItem: any) => {
                let xMatch = gridColumns.find((xItem: any) => xItem.headerName === yItem.headerName);
                // @ts-ignore
                xMatch = {...xMatch, COL_WIDTH: yItem.width}
                return xMatch ? {...yItem, ...xMatch} : yItem;
            });
        } else if (!shownColumns.length) {
            shownColumnsResult = shownColumns
        }
        const shownColumnsResultFinal = shownColumnsResult?.map((g: any, index: number) => {
            return {
                COL_NAME: g.field,
                COL_OPTIONS: g.col_options,
                COL_POS: index,
                COL_TITLE: g.headerName,
                COL_TYPE: g.dataType,
                COL_VALUE: g.col_value,
                COL_WIDTH: g.COL_WIDTH
            }
        })
        const hiddenColumnsResultFinal = hiddenColumnsResult?.map((g: any) => {
            return {
                COL_NAME: g.field,
                COL_OPTIONS: g.col_options,
                COL_POS: g.COL_POS,
                COL_TITLE: g.headerName,
                COL_TYPE: g.dataType,
                COL_VALUE: g.col_value,
                COL_WIDTH: g.COL_WIDTH
            }
        })

        function mergeArrays(hiddenColumns: any, shownColumns: any) {
            const result = [];
            for (const hiddenColumn of hiddenColumns) {
                const matchingObjS = shownColumns.find((objS: any) => objS.COL_NAME === hiddenColumn.COL_NAME);
                if (matchingObjS) {
                    hiddenColumn.COL_WIDTH = matchingObjS.COL_WIDTH;
                }
                result.push(hiddenColumn);
            }
            for (const shownColumn of shownColumns) {
                if (!result.find(obj => obj.COL_NAME === shownColumn.COL_NAME)) {
                    result.push(shownColumn);
                }
            }
            return result;
        }

        const sortConditionStrArray = Object.keys(localSortModel).map(key => {
            // @ts-ignore
            return {"field": localSortModel[key].field, "sort": localSortModel[key].sort};
        });
        const sortConditionStr = sortConditionStrArray?.map((item: any) => `${item.field} ${item.sort.toUpperCase()}`).join(', ');
        const req = {
            p_agent_ref: Number(userData.user_ref),
            p_grid_columns: mergeArrays(hiddenColumnsResultFinal, shownColumnsResultFinal),
            p_row_req: null,
            p_sort_order: sortConditionStr && sortConditionStr !== '' ? sortConditionStr : currentGridTab === 'Reqs' ? 'REF DESC' : currentGridTab === 'Properties' ? 'PROP_REF DESC' : 'SURF_REF_DESC',
            grid_type: currentGridTab === 'Reqs' ? 'ACTIVITY_REQ_DATA' : currentGridTab === 'Properties' ? 'ACTIVITY_PROP_DATA' : 'ACTIVITY_SURF_DATA',
        }
        gridApi.updateGridColumnData(req)
            .then(() => {
                message.success('Grid configs were saved')
                // if (currentGridTab === 'Reqs') {
                //     if (companyRef) {
                //         dispatch(GetActivitiesRequirements({
                //             p_agent_ref: Number(userData.user_ref),
                //             p_company_ref: companyRef,
                //             p_sort_order: null
                //         }))
                //
                //     } else {
                //         dispatch(GetActivitiesRequirements({
                //             p_agent_ref: Number(userData.user_ref),
                //             p_company_ref: null,
                //             p_sort_order: null
                //         }))
                //     }
                // } else if (currentGridTab === 'Properties') {
                //     if (!allPropertiesShown && companyRef) {
                //         dispatch(GetActivitiesPropertiesThunk({
                //             reqData: {
                //                 p_agent_ref: Number(userData.user_ref),
                //                 p_company_ref: companyRef,
                //                 p_sort_order: null,
                //                 p_status_idx: Number(currentStatus)
                //             }, allPropertiesShown
                //         }))
                //     } else if (allPropertiesShown && allPropertiesShown !== null) {
                //         dispatch(GetActivitiesPropertiesThunk({
                //             reqData: {
                //                 p_agent_ref: Number(userData.user_ref),
                //                 p_company_ref: null,
                //                 p_sort_order: null,
                //                 p_status_idx: Number(currentStatus)
                //             }, allPropertiesShown
                //         }))
                //
                //     }
                // } else {
                //
                //     if (!allSurfacesShown && companyRef) {
                //         dispatch(GetActivitiesSurfacesThunk({
                //             reqData: {
                //                 p_agent_ref: Number(userData.user_ref),
                //                 p_company_ref: companyRef,
                //                 p_sort_order: null,
                //                 p_status_idx: currentStatus
                //             }, allSurfacesShown
                //         }))
                //     } else if (allSurfacesShown && allSurfacesShown !== null) {
                //         dispatch(GetActivitiesSurfacesThunk({
                //             reqData: {
                //                 p_agent_ref: Number(userData.user_ref),
                //                 p_company_ref: null,
                //                 p_sort_order: null,
                //                 p_status_idx: currentStatus
                //             }, allSurfacesShown
                //         }))
                //     }
                //
                //
                // }
            })
            .catch((e) => {
                console.log(e)
            })
        // setLocalSortModel({})
        //
        // if (currentGridTab === 'Reqs') {
        //     dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Reqs'}))
        //     dispatch(onSetActivitiesCreationSortingString({sorting_string: null, gridType: 'Reqs'}))
        //     dispatch(onSetActivitiesIsDataLoading(true))
        // } else if (currentGridTab === 'Properties') {
        //     dispatch(onSetActivitiesCreationSortingString({sorting_string: null, gridType: 'Properties'}))
        //     dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Properties'}))
        //     dispatch(onSetActivitiesIsPropsLoading(true))
        // } else {
        //     dispatch(onSetActivitiesCreationSortingString({sorting_string: null, gridType: 'Surfaces'}))
        //     dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: 'Surfaces'}))
        //     dispatch(onSetActivitiesIsSurfacesLoading(true))
        // }
        // dispatch(setActivitiesCreationGridShownColumns({columns: []}))
        // dispatch(onSetPropertiesGridLoaderMessage('Saving data is in progress'))
    }


    const onSetFiltersShown = () => {
        setFiltersShown(!filtersShown)
    }

    const handleFiltersShownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation(); // Prevent event bubbling to document click event listener
        onSetFiltersShown();
    };

    const handleStateChange = (params: any) => {
        // Access the grid state
        dispatch(onChangeActivitiesGridColumnsPosition({columns: params.columns.all, isCreationGrid: true}))
        dispatch(onChangeActivitiesGridColumnsWidth({columns: params.columns.lookup, isCreationGrid: true}))
    };


    const onResetSorting = () => {
        // setLocalSortModel({})
        dispatch(onSetActivitiesCreationLocalSortModel({sort_model: [], gridType: currentGridTab}))
    }


    const onChangeGridMode = (gridType: TabName, currentMode: string) => {
        dispatch(setActivitiesPropertiesGridError(false))
        dispatch(setActivitiesSurfacesGridError(false))
        dispatch(onChangeActivitiesGridMode({gridType, currentMode}))
        setActiveMode({ gridType, currentMode });
    }

    console.log(activeMode)


    const onAddressChange = (value: string) => {
        dispatch(onChangeActivitiesPropertiesSearchValue(value))
    }



    const onSearchAddress = () => {
        if(currentGridTab === 'Properties'){
            dispatch(setActivitiesProperties([]))
            dispatch(GetActivitiesPropertiesThunk({
                reqData: {
                    p_agent_ref: Number(userData.user_ref),
                    p_sort_order: null,
                    p_status_idx: Number(currentStatus),
                    p_street_address: propertiesSearchValue
                }, allPropertiesShown
            }))
        }
        else if(currentGridTab === 'Surfaces'){
            dispatch(setActivitiesSurfaces([]))
            dispatch(GetActivitiesSurfacesThunk({
                reqData: {
                    p_agent_ref: Number(userData.user_ref),
                    p_sort_order: null,
                    p_status_idx: Number(currentStatus),
                    p_street_address: propertiesSearchValue
                }, allSurfacesShown
            }))
        }

    }

    return (
        <div>
            <PageTitle backLinktext='Activities' isBackButton={true} backLink={'/activitiesGrid'}/>
            <div className={s.inner} style={{
                width: '100%'
            }}>
                <div
                    className={s.inner__content}
                    style={{
                        marginTop: '0',
                        overflow: 'hidden'
                    }}
                >
                    <div
                        className={commonS.tableInner}
                        style={{
                            padding: '9px 0 16px 12px',
                            width: '100%'
                        }}
                    >
                        <div className={s.headerButtons}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                {
                                    currentGridTab === 'Reqs'
                                    &&
                                    <h1 className={s.tableTitle}>
                                        {`List of Reqs for agent ${userData.email}`}
                                    </h1>
                                }
                                {
                                    currentGridTab === 'Properties'
                                    &&
                                    <h1 className={s.tableTitle}>
                                        {
                                            activitiesModes[0].currentMode === 1
                                                ? `List of Properties for contact ${modalFormFields.contacts ? modalFormFields.contacts.split('|')[0] : ''}`
                                                : `List of Properties for agent ${userData.email}`
                                        }
                                    </h1>
                                }
                                {
                                    currentGridTab === 'Surfaces'
                                    &&
                                    <h1 className={s.tableTitle}>
                                        {
                                            activitiesModes[1].currentMode === 1
                                                ? `List of Surfaces for contact ${modalFormFields.contacts ? modalFormFields.contacts.split('|')[0] : ''}`
                                                : `List of Surfaces for agent ${userData.email}`
                                        }
                                    </h1>
                                }
                            </div>
                            <div style={{
                                marginRight: '16px'
                            }}>
                                <PropertiesHeaderButtons
                                    onSetFiltersShown={handleFiltersShownClick}
                                    onResetConfigs={onResetConfigs}
                                    onSaveGridConfigs={onSaveGridConfigs}
                                    isDisabledSaveBtn={false}
                                    gridType={'Activities Grid/Create/Update'}
                                    resetFilters={() => {
                                    }}
                                />
                            </div>
                        </div>
                        {
                            currentGridTab === 'Properties' && activitiesModes[0].currentMode === 2
                            &&
                            <div
                                className={s.searchInner}
                                style={{
                                    marginBottom: '15px'
                                }}
                            >
                                <div className={s.inner__inputBox}>
                                    <Input
                                        onChange={(e) => onAddressChange(e.target.value)}
                                        onPressEnter={onSearchAddress}
                                        className={s.shownSearchInput}
                                        placeholder={'Search by address'}
                                        value={propertiesSearchValue !== null ? propertiesSearchValue : undefined}
                                        prefix={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                style={{
                                                    marginRight: '8px'
                                                }}
                                            >
                                                <path
                                                    d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                                                    stroke="#667085" strokeWidth="1.66667" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                            </svg>}
                                    />
                                </div>

                                <button onClick={onSearchAddress}>Search</button>
                            </div>
                        }
                        {
                            currentGridTab === 'Surfaces' && activitiesModes[1].currentMode === 2
                            &&
                            <div
                                className={s.searchInner}
                                style={{
                                    marginBottom: '15px'
                                }}
                            >
                                <div className={s.inner__inputBox}>
                                    <Input
                                        onChange={(e) => onAddressChange(e.target.value)}
                                        onPressEnter={onSearchAddress}
                                        className={s.shownSearchInput}
                                        placeholder={'Search by address'}
                                        value={propertiesSearchValue !== null ? propertiesSearchValue : undefined}
                                        prefix={
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                                fill="none"
                                                style={{
                                                    marginRight: '8px'
                                                }}
                                            >
                                                <path
                                                    d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                                                    stroke="#667085" strokeWidth="1.66667" strokeLinecap="round"
                                                    strokeLinejoin="round"/>
                                            </svg>}
                                    />
                                </div>

                                <button onClick={onSearchAddress}>Search</button>
                            </div>
                        }

                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '10px'
                        }}>
                            <PropertiesSearchInput
                                searchTerm={searchTerm}
                                setSearchTerm={(searchTerm: string) => setSearchTerm(searchTerm)}
                                handleSearchInputChange={handleSearchInputChange}
                                isActivityReq={true}
                            />
                        </div>
                        <div style={{
                            display: 'flex',
                            marginBottom: '25px'
                        }}>
                            <Select
                                style={{width: 105, marginRight: '12px'}}
                                className={'mapSelect'}
                                placeholder={'Status'}
                                suffixIcon={<svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.30026 8.3L3.70026 5.7C3.38359 5.38333 3.31292 5.021 3.48826 4.613C3.66292 4.20433 3.97526 4 4.42526 4H9.57526C10.0253 4 10.3376 4.20433 10.5123 4.613C10.6876 5.021 10.6169 5.38333 10.3003 5.7L7.70026 8.3C7.60026 8.4 7.49192 8.475 7.37526 8.525C7.25859 8.575 7.13359 8.6 7.00026 8.6C6.86692 8.6 6.74192 8.575 6.62526 8.525C6.50859 8.475 6.40026 8.4 6.30026 8.3Z"
                                        fill={'#191559'}/>
                                </svg>}
                                onChange={handleStatusChange}
                                defaultValue={'Active'}
                                options={selectMapStatuses}
                            />
                            {
                                currentGridTab === 'Properties' && activitiesModes[0].currentMode === 1
                                &&
                                <Select
                                    style={{width: 105, marginRight: '12px'}}
                                    className={'mapSelect'}
                                    placeholder={'Function'}
                                    defaultValue={'Function'}
                                    suffixIcon={<svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6.30026 8.3L3.70026 5.7C3.38359 5.38333 3.31292 5.021 3.48826 4.613C3.66292 4.20433 3.97526 4 4.42526 4H9.57526C10.0253 4 10.3376 4.20433 10.5123 4.613C10.6876 5.021 10.6169 5.38333 10.3003 5.7L7.70026 8.3C7.60026 8.4 7.49192 8.475 7.37526 8.525C7.25859 8.575 7.13359 8.6 7.00026 8.6C6.86692 8.6 6.74192 8.575 6.62526 8.525C6.50859 8.475 6.40026 8.4 6.30026 8.3Z"
                                            fill={'#191559'}/>
                                    </svg>}
                                    onChange={handleFunctionChange}
                                    options={[
                                        { label: 'Function', value: 'function', disabled: true },
                                        ...selectReqFunctions
                                    ]}
                                />
                            }
                            {
                                currentGridTab === 'Surfaces' && activitiesModes[1].currentMode === 1
                                &&
                                <Select
                                    style={{width: 105, marginRight: '12px'}}
                                    className={'mapSelect'}
                                    placeholder={'Function'}
                                    defaultValue={'Function'}
                                    suffixIcon={<svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M6.30026 8.3L3.70026 5.7C3.38359 5.38333 3.31292 5.021 3.48826 4.613C3.66292 4.20433 3.97526 4 4.42526 4H9.57526C10.0253 4 10.3376 4.20433 10.5123 4.613C10.6876 5.021 10.6169 5.38333 10.3003 5.7L7.70026 8.3C7.60026 8.4 7.49192 8.475 7.37526 8.525C7.25859 8.575 7.13359 8.6 7.00026 8.6C6.86692 8.6 6.74192 8.575 6.62526 8.525C6.50859 8.475 6.40026 8.4 6.30026 8.3Z"
                                            fill={'#191559'}/>
                                    </svg>}
                                    onChange={handleFunctionChange}
                                    options={[
                                        { label: 'Function', value: 'function', disabled: true },
                                        ...selectReqFunctions
                                    ]}
                                />
                            }

                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingRight: '15px',
                            alignItems: 'center'
                        }}>
                            <div className={s.tabButtons} style={{
                                width: 'fit-content',
                            }}>
                                <button
                                    className={currentGridTab === 'Reqs' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : s.tabButtons__button}
                                    onClick={() => onChangeGridTab('Reqs')}
                                    style={{position: "relative"}}
                                >
                                    Reqs
                                </button>
                                {/*{*/}
                                {/*    isPropsLoading*/}
                                {/*        ?*/}
                                {/*        <Tooltip text="Please wait, the properties are loading"*/}
                                {/*                 classname={'activitiesTabsTooltip'}>*/}
                                <button
                                    className={currentGridTab === 'Properties' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : s.tabButtons__button}
                                    onClick={() => onChangeGridTab('Properties')}
                                    style={{position: "relative"}}
                                    // disabled={isPropsLoading}
                                >
                                    Properties
                                    {isPropsLoading ? (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: '-9px',
                                                    right: '-15px',
                                                    bottom: 0,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    // background: 'rgba(255, 255, 255, 0.8)',
                                                    zIndex: 1000,
                                                }}
                                            >
                                                <FacebookCircularProgress size={15}/>
                                            </div>
                                        )
                                        :
                                        null
                                    }
                                </button>
                                {/*</Tooltip>*/}


                                {/*}*/}
                                <button
                                    className={currentGridTab === 'Surfaces' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : s.tabButtons__button}
                                    onClick={() => onChangeGridTab('Surfaces')}
                                    style={{position: "relative"}}
                                >
                                    Surfaces
                                    {isSurfacesLoading
                                        ?
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: '-9px',
                                                right: '-15px',
                                                bottom: 0,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                // background: 'rgba(255, 255, 255, 0.8)',
                                                zIndex: 1000,
                                            }}
                                        >
                                            <FacebookCircularProgress size={15}/>
                                        </div>
                                        :
                                        null}
                                </button>
                            </div>

                            <PropertiesFilterButtons
                                gridType={'Activities Grid/Create/Update'}
                                shownColumns={shownColumns}
                                onResetFilters={() => {}}
                                onResetSorting={onResetSorting}
                            />
                        </div>
                        {
                            currentGridTab !== 'Reqs'
                            &&
                            <div className={s.modeButtons}>
                                <Tooltip text={currentGridTab === 'Properties'
                                    ? 'List of properties with direct connection by Property Contacts without requirement connection'
                                    : 'List of surfaces with connection by selected contact'
                                }
                                         classname={'activitiesModeTooltip'}>

                                    <div
                                        className={
                                            activitiesModes.find((a) => a.gridType === currentGridTab)?.currentMode === 1
                                                ? `${s.modeButtons__button} ${s.modeButtons__button_active}`
                                                : `${s.modeButtons__button}`
                                        }
                                        onClick={() => onChangeGridMode(currentGridTab as TabName, 'Function')}
                                        style={{
                                            borderBottomLeftRadius: activitiesModes.find((a) => a.gridType === currentGridTab)?.currentMode === 1 ? '8px' : "0px",
                                            borderTopLeftRadius: activitiesModes.find((a) => a.gridType === currentGridTab)?.currentMode === 1 ? '8px' : "0px",
                                            height: '97%',
                                            borderRight: '1px solid #D0D5DD',
                                            marginTop: '1px'
                                        }}
                                    >
                                        Function
                                    </div>
                                </Tooltip>
                                <Tooltip text={currentGridTab === 'Properties'
                                    ? 'List of properties with connection by agent'
                                    : 'List of surfaces with connection by agent'
                                }
                                         classname={'activitiesModeTooltip'}>

                                    <div
                                        className={
                                            activitiesModes.find((a) => a.gridType === currentGridTab)?.currentMode === 2
                                                ? `${s.modeButtons__button} ${s.modeButtons__button_active}`
                                                : `${s.modeButtons__button}`
                                        }
                                        onClick={() => onChangeGridMode(currentGridTab as TabName, 'Agent')}
                                        style={{
                                            borderBottomRightRadius: activitiesModes.find((a) => a.gridType === currentGridTab)?.currentMode === 2 ? '8px' : "0px",
                                            borderTopRightRadius: activitiesModes.find((a) => a.gridType === currentGridTab)?.currentMode === 2 ? '8px' : "0px",
                                            height: '97%',
                                            marginTop: '1px'
                                        }}
                                    >
                                        Agent
                                    </div>
                                </Tooltip>
                            </div>
                        }
                        <Box sx={{position: 'relative', width: '100%'}}>
                            <PropertiesViewOptions
                                checked={checked}
                                filtersShown={filtersShown}
                                setChecked={(checkedArray: string[]) => setChecked(checkedArray)}
                                setFilterShown={(isShown: boolean) => setFiltersShown(isShown)}
                                gridType={'Activities Grid/Create/Update'}
                            />
                            <>
                                {
                                    currentGridTab === 'Reqs' && isDataLoading && !reqRows.length
                                    ||
                                    currentGridTab === 'Properties' && !propRows.length && isPropsLoading
                                    ||
                                    currentGridTab === 'Surfaces' && isSurfacesLoading && !surfRows.length
                                        ?
                                        <div style={{
                                            height: gridContentHeight - 90,
                                            width: '400px',
                                            margin: '0 auto',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center'
                                        }}>
                                            <div style={{
                                                height: '300px',
                                                width: '100%',
                                                textAlign: 'center'
                                            }}>
                                                {/* @ts-ignore */}
                                                <Lottie config={{animationData: animatedLoader, loop: true, height: '60px !important'}}
                                                />
                                            </div>
                                        </div>
                                        :

                                        gridRows.length
                                            ?
                                            <DataGridPro
                                                getRowClassName={getRowClassName}
                                                rows={gridRowsPaginated}
                                                columns={shownColumns.map((column: any, index: number) => ({
                                                    ...column,
                                                    renderCell: (params: any) =>
                                                        <CustomCell field={column.field}
                                                                    value={params.value}
                                                                    params={params}
                                                                    properties={gridRowsPaginated}
                                                                    key={index}
                                                                    dataType={column.dataType}

                                                        />
                                                }))}
                                                apiRef={apiRef}
                                                autoHeight={true}
                                                rowHeight={44}
                                                getRowId={e => e.id}
                                                sortModel={Object.values(localSortModel)}
                                                sortingOrder={['desc', 'asc']}
                                                onSortModelChange={handleSortModelChange}
                                                onRowDoubleClick={onRowClick}
                                                disableColumnSelector={true}
                                                onStateChange={handleStateChange}
                                                {...gridRowsPaginated}
                                                sx={{
                                                    borderRight: 'none',
                                                    borderLeft: 'none',
                                                    borderBottom: 'none',
                                                    borderRadius: '0px',
                                                    // minHeight: '600px',
                                                    // height: height > 950 ? `${gridContentHeight}px !important` : height > 900 ? `${gridContentHeight - 150}px !important` : `${gridContentHeight + 150}px !important`,
                                                    "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                                                        opacity: 0
                                                    },
                                                    "& .MuiDataGrid-cellContent": {
                                                        fontFamily: 'Inter, sans-serif',
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                        lineHeight: '20px',
                                                        color: '#111827'
                                                    },
                                                    "& .MuiDataGrid-columnHeaderTitle": {
                                                        fontFamily: 'Inter, sans-serif',
                                                        fontWeight: 600,
                                                        color: '#6B7280',
                                                        fontSize: '12px',
                                                        lineHeight: '20px',
                                                    },
                                                    "& .MuiDataGrid-row:hover": {
                                                        backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                                    },
                                                    "& .MuiDataGrid-row.Mui-selected": {
                                                        backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                                    },
                                                    "& .MuiDataGrid-row.Mui-selected:hover": {
                                                        backgroundColor: 'rgba(23, 85, 231, 0.12)'
                                                    },
                                                    "& .MuiDataGrid-cell:focus": {
                                                        outline: 'none'
                                                    },
                                                    "& .MuiDataGrid-toolbarContainer": {
                                                        color: '#454545',
                                                        position: 'absolute',
                                                        top: '-50px',
                                                        right: '0px'
                                                    },
                                                    "& .MuiTablePagination-displayedRows": {
                                                        margin: '0',
                                                        fontFamily: 'Inter, sans-serif !important',
                                                        fontWeight: 400,
                                                        color: '#6B7280',
                                                        fontSize: '12px',
                                                        lineHeight: '20px',
                                                    },
                                                    "& .MuiPagination-root": {
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        padding: '15px 0px'
                                                    },
                                                    "& .MuiButtonBase-root.Mui-selected": {
                                                        backgroundColor: '#eff1f3',
                                                        borderRadius: '0px'
                                                    },
                                                    "& .MuiButtonBase-root.MuiPaginationItem-root": {
                                                        width: '40px',
                                                        height: '40px',
                                                        margin: 0,
                                                        borderRight: '1px solid #D0D5DD',
                                                        borderLeft: '1px solid #D0D5DD',
                                                        borderTop: 'none',
                                                        borderBottom: 'none',
                                                        fontFamily: 'Inter, sans-serif !important',
                                                        fontWeight: 500,
                                                        color: '#1D2939',
                                                        fontSize: '14px',
                                                        lineHeight: '20px',
                                                        borderRadius: '0px'
                                                    },
                                                    "& .MuiPagination-ul": {
                                                        border: '1px solid #D0D5DD',
                                                        borderRadius: '8px'
                                                    },
                                                    "& .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-previousNext": {
                                                        borderRadius: '0px',
                                                        border: 'none'
                                                    },
                                                    "& .MuiDataGrid-columnHeaders": {
                                                        backgroundColor: '#FCFDFE'
                                                    },
                                                    "& .MuiDivider-root": {
                                                        display: 'none',
                                                        height: '0',
                                                        width: '0',
                                                        opacity: '0 !important',
                                                        borderColor: '#fff '
                                                    },
                                                    "& .MuiBox-root": {
                                                        height: 'auto'
                                                    },
                                                    "& .MuiDataGrid-cell": {
                                                        borderBottom: 'none'
                                                    },
                                                    "& .MuiDataGrid-virtualScroller": {
                                                        overflowY: 'scroll !important',
                                                        overflowX: 'scroll',
                                                        height: `${gridContentHeight - 220}px !important`,
                                                        minHeight: 'auto',
                                                    },
                                                    "& .MuiDataGrid-virtualScrollerContent": {},
                                                }}
                                                components={{
                                                    Footer: () =>
                                                        <PropertiesGridFooter
                                                            rows={gridRows}
                                                            rowsPerPageOptions={rowsPerPageOptions}
                                                            handlePageChange={handlePageChange}
                                                            handleRowsPerPageChange={handleRowsPerPageChange}
                                                            rowsPerPage={rowsPerPage}
                                                            page={page}
                                                            activityCreationGridType={currentGridTab}
                                                        />,
                                                    ColumnMenu: (props) => {
                                                        return (
                                                            <GridColumnMenu
                                                                hideMenu={() => {
                                                                }}
                                                                currentColumn={
                                                                    {
                                                                        field: props.currentColumn.field,
                                                                        filterable: false,
                                                                        hideable: false,
                                                                    }
                                                                }
                                                                open={true}
                                                            />
                                                        )
                                                    },
                                                    Row: CustomGridRow,
                                                }}
                                            />
                                            :
                                            <div style={{
                                                height: `${gridContentHeight - 90}px`,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                                {
                                                    currentGridTab === 'Properties' && activityPropertiesGridError && activitiesModes[0].currentMode === 2
                                                        ?
                                                        <Empty description={'No data found, please try again with another address'}/>
                                                        :
                                                        currentGridTab === 'Properties' && activitiesModes[0].currentMode === 2 && !activityPropertiesGridError
                                                            ?
                                                            <Empty description={'Start by entering an address and press Search to display properties'}/>
                                                            :
                                                            currentGridTab === 'Surfaces' && activitySurfacesGridError && activitiesModes[1].currentMode === 2
                                                                ?
                                                                <Empty description={'No data found, please try again with another address'}/>
                                                                :
                                                                currentGridTab === 'Surfaces' && activitiesModes[1].currentMode === 2  && !activitySurfacesGridError
                                                                    ?
                                                                    <Empty description={'Start by entering an address and press Search to display properties'}/>
                                                                    :
                                                                    <Empty/>

                                                }

                                            </div>
                                }
                            </>
                        </Box>

                    </div>

                </div>
                <CommonActivityModal
                    modalTitle={`Activity ${editMode ? currentRowValues.REF! : ''}`}
                    gridType={"Activity"}
                    height={gridContentHeight}
                    onCloseModal={onCloseModal}
                    width={'650px'}
                >
                    <div>
                        <div className={s.tabButtons}>
                            <button
                                onClick={() => dispatch(onSetCurrentModalTab('General'))}
                                className={currentTab === 'General' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : s.tabButtons__button}
                            >
                                General
                            </button>
                            {
                                checkedReqIds.length || checkedPropertiesIds.length || checkedSurfaceIds.length
                                    ?
                                    <button
                                        onClick={() => dispatch(onSetCurrentModalTab('Existing Links'))}
                                        className={currentTab === 'Existing Links' ? `${s.tabButtons__button} ${s.tabButtons__button_active}` : s.tabButtons__button}
                                    >
                                        Record links
                                    </button>
                                    :
                                    null
                            }
                            {
                                currentRowValues.WE_TYPE_POSITION !== null
                                &&
                                <p className={s.badge}>{currentRowValues.WE_TYPE_POSITION}</p>
                            }
                        </div>
                        {
                            currentTab === 'General'
                                ?
                                <ActivitiesGridForm withButtons={false} editMode={editMode} maxWidthInputs={true}/>
                                :
                                checkedReqIds.length || checkedPropertiesIds.length || checkedSurfaceIds.length
                                    ?
                                    <div>
                                        <>
                                            {checkedReqIds.length
                                                ?
                                                <div className={propertiesStyles.messageInner__properties}>
                                                    <p>Requirements:</p>
                                                    <div className={propertiesStyles.messageInner__propertiesInner}>
                                                        {checkedReqIds.map((p: number) => {
                                                            return (
                                                                <div className={propertiesStyles.messageInner__property}
                                                                     key={p}>


                                                                    <span>{p}</span>
                                                                    <button
                                                                        onClick={() => dispatch(onDeleteCheckedReq(p))}
                                                                        type={'button'}
                                                                        disabled={currentRowValues?.ACT_TYPE === 'Documentation'}
                                                                        style={{
                                                                            cursor: currentRowValues?.ACT_TYPE === 'Documentation' ? 'not-allowed' : 'pointer'
                                                                        }}
                                                                    >
                                                                        <svg width="12" height="12" viewBox="0 0 12 12"
                                                                             fill="none"
                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                            <g clipPath="url(#clip0_1387_36685)">
                                                                                <path
                                                                                    d="M6.46967 5.99974L9.233 3.2364C9.28761 3.17264 9.31614 3.09061 9.3129 3.00672C9.30966 2.92283 9.27488 2.84325 9.21552 2.78388C9.15616 2.72452 9.07658 2.68974 8.99268 2.6865C8.90879 2.68326 8.82677 2.71179 8.763 2.7664L5.99967 5.52974L3.23633 2.76307C3.17356 2.7003 3.08843 2.66504 2.99967 2.66504C2.9109 2.66504 2.82577 2.7003 2.763 2.76307C2.70023 2.82584 2.66497 2.91097 2.66497 2.99974C2.66497 3.0885 2.70023 3.17364 2.763 3.2364L5.52967 5.99974L2.763 8.76307C2.7281 8.79295 2.69976 8.82972 2.67976 8.87108C2.65975 8.91243 2.64851 8.95748 2.64673 9.00338C2.64496 9.04929 2.65269 9.09507 2.66945 9.13784C2.68621 9.18062 2.71163 9.21947 2.74412 9.25195C2.7766 9.28444 2.81545 9.30986 2.85822 9.32661C2.901 9.34337 2.94678 9.35111 2.99268 9.34933C3.03859 9.34756 3.08363 9.33632 3.12499 9.31631C3.16634 9.2963 3.20312 9.26796 3.233 9.23307L5.99967 6.46974L8.763 9.23307C8.82677 9.28768 8.90879 9.31621 8.99268 9.31297C9.07658 9.30973 9.15616 9.27496 9.21552 9.21559C9.27488 9.15623 9.30966 9.07665 9.3129 8.99275C9.31614 8.90886 9.28761 8.82684 9.233 8.76307L6.46967 5.99974Z"
                                                                                    fill={'rgba(0, 0, 0, 0.54)'}/>
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_1387_36685">
                                                                                    <rect width="12" height="12"
                                                                                          fill="white"/>
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>

                                                                    </button>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                :
                                                null}
                                        </>
                                        <>
                                            {checkedPropertiesIds.length
                                                ?
                                                <div className={propertiesStyles.messageInner__properties}>
                                                    <p>Properties:</p>
                                                    <div className={propertiesStyles.messageInner__propertiesInner}>
                                                        {checkedPropertiesIds.map((p: number) => {
                                                            return (
                                                                <div className={propertiesStyles.messageInner__property}
                                                                     key={p}>


                                                                    <span>{p}</span>
                                                                    <button
                                                                        onClick={() => dispatch(onDeleteCheckedProperty({
                                                                            prop_id: p,
                                                                            withoutReq: false
                                                                        }))}
                                                                        type={'button'}
                                                                        disabled={currentRowValues?.ACT_TYPE === 'Documentation'}
                                                                        style={{
                                                                            cursor: currentRowValues?.ACT_TYPE === 'Documentation' ? 'not-allowed' : 'pointer'
                                                                        }}
                                                                    >
                                                                        <svg width="12" height="12" viewBox="0 0 12 12"
                                                                             fill="none"
                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                            <g clipPath="url(#clip0_1387_36685)">
                                                                                <path
                                                                                    d="M6.46967 5.99974L9.233 3.2364C9.28761 3.17264 9.31614 3.09061 9.3129 3.00672C9.30966 2.92283 9.27488 2.84325 9.21552 2.78388C9.15616 2.72452 9.07658 2.68974 8.99268 2.6865C8.90879 2.68326 8.82677 2.71179 8.763 2.7664L5.99967 5.52974L3.23633 2.76307C3.17356 2.7003 3.08843 2.66504 2.99967 2.66504C2.9109 2.66504 2.82577 2.7003 2.763 2.76307C2.70023 2.82584 2.66497 2.91097 2.66497 2.99974C2.66497 3.0885 2.70023 3.17364 2.763 3.2364L5.52967 5.99974L2.763 8.76307C2.7281 8.79295 2.69976 8.82972 2.67976 8.87108C2.65975 8.91243 2.64851 8.95748 2.64673 9.00338C2.64496 9.04929 2.65269 9.09507 2.66945 9.13784C2.68621 9.18062 2.71163 9.21947 2.74412 9.25195C2.7766 9.28444 2.81545 9.30986 2.85822 9.32661C2.901 9.34337 2.94678 9.35111 2.99268 9.34933C3.03859 9.34756 3.08363 9.33632 3.12499 9.31631C3.16634 9.2963 3.20312 9.26796 3.233 9.23307L5.99967 6.46974L8.763 9.23307C8.82677 9.28768 8.90879 9.31621 8.99268 9.31297C9.07658 9.30973 9.15616 9.27496 9.21552 9.21559C9.27488 9.15623 9.30966 9.07665 9.3129 8.99275C9.31614 8.90886 9.28761 8.82684 9.233 8.76307L6.46967 5.99974Z"
                                                                                    fill={'rgba(0, 0, 0, 0.54)'}/>
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_1387_36685">
                                                                                    <rect width="12" height="12"
                                                                                          fill="white"/>
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>

                                                                    </button>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                :
                                                null}
                                        </>
                                        <>
                                            {checkedSurfaceIds.length
                                                ?
                                                <div className={propertiesStyles.messageInner__properties}>
                                                    <p>Surfaces:</p>
                                                    <div className={propertiesStyles.messageInner__propertiesInner}>
                                                        {checkedSurfaceIds.map((p: any) => {
                                                            return (
                                                                <div className={propertiesStyles.messageInner__property}
                                                                     key={p}>


                                                                    <span>{p}</span>
                                                                    <button
                                                                        onClick={() => dispatch(onDeleteCheckedSurface({
                                                                            surface_id: p,
                                                                        }))}
                                                                        type={'button'}
                                                                        disabled={currentRowValues?.ACT_TYPE === 'Documentation'}
                                                                        style={{
                                                                            cursor: currentRowValues?.ACT_TYPE === 'Documentation' ? 'not-allowed' : 'pointer'
                                                                        }}
                                                                    >
                                                                        <svg width="12" height="12" viewBox="0 0 12 12"
                                                                             fill="none"
                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                            <g clipPath="url(#clip0_1387_36685)">
                                                                                <path
                                                                                    d="M6.46967 5.99974L9.233 3.2364C9.28761 3.17264 9.31614 3.09061 9.3129 3.00672C9.30966 2.92283 9.27488 2.84325 9.21552 2.78388C9.15616 2.72452 9.07658 2.68974 8.99268 2.6865C8.90879 2.68326 8.82677 2.71179 8.763 2.7664L5.99967 5.52974L3.23633 2.76307C3.17356 2.7003 3.08843 2.66504 2.99967 2.66504C2.9109 2.66504 2.82577 2.7003 2.763 2.76307C2.70023 2.82584 2.66497 2.91097 2.66497 2.99974C2.66497 3.0885 2.70023 3.17364 2.763 3.2364L5.52967 5.99974L2.763 8.76307C2.7281 8.79295 2.69976 8.82972 2.67976 8.87108C2.65975 8.91243 2.64851 8.95748 2.64673 9.00338C2.64496 9.04929 2.65269 9.09507 2.66945 9.13784C2.68621 9.18062 2.71163 9.21947 2.74412 9.25195C2.7766 9.28444 2.81545 9.30986 2.85822 9.32661C2.901 9.34337 2.94678 9.35111 2.99268 9.34933C3.03859 9.34756 3.08363 9.33632 3.12499 9.31631C3.16634 9.2963 3.20312 9.26796 3.233 9.23307L5.99967 6.46974L8.763 9.23307C8.82677 9.28768 8.90879 9.31621 8.99268 9.31297C9.07658 9.30973 9.15616 9.27496 9.21552 9.21559C9.27488 9.15623 9.30966 9.07665 9.3129 8.99275C9.31614 8.90886 9.28761 8.82684 9.233 8.76307L6.46967 5.99974Z"
                                                                                    fill={'rgba(0, 0, 0, 0.54)'}/>
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_1387_36685">
                                                                                    <rect width="12" height="12"
                                                                                          fill="white"/>
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>

                                                                    </button>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                :
                                                null}
                                        </>
                                    </div>
                                    :
                                    <div className={s.reqGridText}>
                                        Add the Reqs / Properties / Surfaces to the activity.
                                    </div>
                        }
                    </div>


                    <>
                        {
                            editMode
                                ?
                                <div className={s.form__buttons}>
                                    <Button
                                        type='default'
                                        style={{
                                            width: currentRowValues.ACT_TYPE !== 'Documentation' ? '295px' : '100%',
                                            height: '44px',
                                        }}
                                        onClick={onCloseModal}
                                    >
                                        Cancel
                                    </Button>
                                    {
                                        modalFormFields.domain === ''
                                        || modalFormFields.domain === null
                                        || modalFormFields.type === ''
                                        || modalFormFields.type === null
                                        || modalFormFields.kind === ''
                                        || modalFormFields.kind === null
                                        || !isActivityTypeKindWithRightValue
                                            ?
                                            <Tooltip
                                                text={isActivityTypeKindWithRightValue && modalFormFields.domain === ''
                                                || modalFormFields.domain === null
                                                || modalFormFields.type === ''
                                                || modalFormFields.type === null
                                                || modalFormFields.kind === ''
                                                || modalFormFields.kind === null
                                                    ? `Please select the domain , activity type and activity kind in order to save changes.`
                                                    : `Please change your activity kind to the one that is correct with the system kinds`}
                                                classname={'addressButtonsTooltip'}>
                                                <Button
                                                    type='primary'
                                                    htmlType='submit'
                                                    style={{
                                                        height: '44px',
                                                        backgroundColor: 'rgba(7, 7, 250, 0.26)',
                                                        border: '1px solid transparent',
                                                        width: '295px',
                                                        color: "#FFF",
                                                        display: currentRowValues.ACT_TYPE !== 'Documentation' ? 'flex' : 'none',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',

                                                    }}
                                                    onClick={() => onCreateNewActivity(false)}
                                                    onDoubleClick={() => setIsLoading(true)}
                                                    disabled={
                                                        modalFormFields.domain === ''
                                                        || modalFormFields.domain === null
                                                        || modalFormFields.type === ''
                                                        || modalFormFields.type === null
                                                        || modalFormFields.kind === ''
                                                        || modalFormFields.kind === null
                                                        || !isActivityTypeKindWithRightValue
                                                    }
                                                    loading={loading}
                                                >
                                                    Save
                                                </Button>
                                            </Tooltip>
                                            :
                                            <Button
                                                type='primary'
                                                htmlType='submit'
                                                style={{
                                                    height: '44px',
                                                    backgroundColor: '#0707FA',
                                                    color: '#fff',
                                                    border: '1px solid transparent',
                                                    width: '295px',
                                                    display: currentRowValues.ACT_TYPE !== 'Documentation' ? 'flex' : 'none',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginLeft: '15px'

                                                }}
                                                onClick={() => onCreateNewActivity(false)}
                                                // onDoubleClick={() => setIsLoading(true)}
                                                // loading={loading}
                                            >
                                                Save
                                            </Button>
                                    }

                                </div>
                                :

                                <Button
                                    type='primary'
                                    htmlType='submit'
                                    style={{
                                        height: '40px',
                                        backgroundColor: '#0707FA',
                                        border: '1px solid #0707FA',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: '10px'

                                    }}
                                    onClick={() => onCreateNewActivity(false)}
                                    // onDoubleClick={() => setIsLoading(true)}
                                    // loading={loading}
                                >
                                    Add
                                </Button>


                        }

                    </>


                </CommonActivityModal>
            </div>
            {
                popConfirmModalOpened
                &&
                <div className={s.popConfirm}>
                    <div className={s.popConfirm__content} style={{
                        height: 'auto'
                    }}>
                        <div className={s.popConfirm__warningIcon}>
                            <WarningActivityIcon/>
                        </div>
                        <p className={s.popConfirm__deleteTitle}>You have selected req without properties/surfaces</p>
                        <p className={s.popConfirm__deleteText}>Are you sure you want to save this activity? You have
                            requirements without chosen properties or surfaces for them, you can go back
                            and select them or save as it is.</p>
                        <div className={s.popConfirm__deleteBtns}>
                            <Button
                                type='primary'
                                htmlType='submit'
                                style={{
                                    height: '44px',
                                    backgroundColor: '#1755E7',
                                    color: '#fff',
                                    width: '170px',
                                    border: '1px solid #1755E7',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',

                                }}

                                onClick={() => setPopConfirmModalOpened(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='default'
                                htmlType='submit'
                                style={{
                                    width: '170px',
                                    height: '44px',
                                }}
                                onClick={() => onCreateNewActivity(true)}
                            >
                                {!editMode ? 'Add' : 'Save'}
                            </Button>

                        </div>
                    </div>
                </div>
            }
        </div>
    );
};


const CustomCell = React.memo(({
                                   field,
                                   value,
                                   params,
                                   properties,
                                   dataType,

                               }: any) => {
    const dispatch = useAppDispatch()
    const checkedReqIds = useAppSelector(selectActivitiesCreationCheckedReqIds)
    const [disabledCheckbox, setDisabledCheckbox] = useState(false)
    const currentRowValues = useAppSelector(selectActivitiesCurrentRow)
    const checkedProperties = useAppSelector(selectCurrentActivitiesCheckedProperties)
    const checkedSurfaces = useAppSelector(selectActivitiesCheckedSurfaces)
    const currentGridTab = useAppSelector(selectActivitiesCurrentGridTab)
    const checkedSurfaceIds = useAppSelector(selectActivitiesCheckedSurfacesIds)
    const checkedPropertiesIds = useAppSelector(selectCurrentActivitiesCheckedPropertiesIds)
    if (field === 'REF') {
        const handleCheckProperties = (value: any) => {
            dispatch(onAddActivitiesReqsPropsSurfaces({
                gridTypeByField: params.field,
                gridRow: params.row,
                entityId: params.formattedValue,
                isChecked: value.target.checked,
                contactListId: params.row.CONTACT_LIST
            }))
            setDisabledCheckbox(true)
            dispatch(onSetCurrentModalTab('Existing Links'))
            setTimeout(() => {
                setDisabledCheckbox(false)
            }, 50)
        }

        return (
            <div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        {
                            field === 'REF' && currentGridTab === 'Reqs'
                            &&
                            <>
                                <Checkbox
                                    onChange={handleCheckProperties}
                                    name={value}
                                    checked={checkedReqIds.includes(value)}
                                    disabled={disabledCheckbox || currentRowValues.ACT_TYPE === 'Documentation'}
                                    style={{
                                        marginRight: '8px'
                                    }}
                                />
                                <div>{value}</div>
                            </>

                        }

                    </div>
                </div>
            </div>

        )
    } else if (field === 'PROP_REF') {


        const handleCheckProperties = (value: any) => {
            dispatch(onAddActivitiesReqsPropsSurfaces({
                gridTypeByField: params.field,
                gridRow: params.row,
                entityId: params.formattedValue,
                isChecked: value.target.checked,
                contactListId: params.row.CONTACT_LIST
            }))
            setDisabledCheckbox(true)
            dispatch(onSetCurrentModalTab('Existing Links'))
            setTimeout(() => {
                setDisabledCheckbox(false)
            }, 50)
        }


        return (
            <div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        {
                            field === 'PROP_REF' && currentGridTab === 'Properties'
                            &&
                            checkedProperties.some((obj: any) => obj?.PROP_REF === value && obj?.REQ_REF !== params.row.REQ_REF)
                                ?
                                <>
                                    <Tooltip
                                        text="You can pick only one property with the same property id from different reqs"
                                        classname={'activitiesCheckboxTooltip'}>
                                        <Checkbox
                                            onChange={handleCheckProperties}
                                            name={value}
                                            // checked={isPropertyInObjectsArray(value, params.row.REQ_REF)}
                                            checked={checkedPropertiesIds.includes(value)}
                                            disabled={true}
                                            style={{
                                                marginRight: '8px'
                                            }}
                                        />
                                        <div>{value}</div>
                                    </Tooltip>
                                </>

                                :
                                field === 'PROP_REF' && currentGridTab === 'Properties'
                                    ?
                                    <>
                                        <Checkbox
                                            onChange={handleCheckProperties}
                                            name={value}
                                            // checked={isPropertyInObjectsArray(value, params.row.REQ_REF)}
                                            checked={checkedPropertiesIds.includes(value)}
                                            disabled={
                                                disabledCheckbox
                                                || currentRowValues.ACT_TYPE === 'Documentation'}
                                            style={{
                                                marginRight: '8px'
                                            }}
                                        />
                                        <div>{value}</div>
                                    </>
                                    :
                                    <div>{value}</div>

                        }
                    </div>
                </div>
            </div>

        )
    } else if (field === 'SURF_REF') {


        const handleCheckProperties = (value: any) => {
            dispatch(onAddActivitiesReqsPropsSurfaces({
                gridTypeByField: params.field,
                gridRow: params.row,
                entityId: params.formattedValue,
                isChecked: value.target.checked,
                contactListId: params.row.CONTACT_LIST
            }))
            setDisabledCheckbox(true)
            dispatch(onSetCurrentModalTab('Existing Links'))
            setTimeout(() => {
                setDisabledCheckbox(false)
            }, 50)
        }

        return (
            <div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        {
                            field === 'SURF_REF' && currentGridTab === 'Surfaces'
                            &&
                            checkedSurfaces.some((obj: any) => obj?.SURF_REF === value && obj?.REQ_REF !== params.row.REQ_REF)
                                ?
                                <>
                                    <Tooltip
                                        text="You can pick only one surface with the same property id from different reqs"
                                        classname={'activitiesCheckboxTooltip'}>
                                        <Checkbox
                                            onChange={handleCheckProperties}
                                            name={value}
                                            // checked={isSurfaceInObjectsArray(value, params.row.REQ_REF)}
                                            checked={checkedSurfaceIds.includes(value)}
                                            disabled={true}
                                            style={{
                                                marginRight: '8px'
                                            }}
                                        />
                                        <div>{value}</div>
                                    </Tooltip>
                                </>

                                :
                                field === 'SURF_REF' && currentGridTab === 'Surfaces'
                                    ?
                                    <>
                                        <Checkbox
                                            onChange={handleCheckProperties}
                                            name={value}
                                            checked={checkedSurfaceIds.includes(value)}
                                            disabled={disabledCheckbox
                                                || currentRowValues.ACT_TYPE === 'Documentation'}
                                            style={{
                                                marginRight: '8px'
                                            }}
                                        />
                                        <div>{value}</div>
                                    </>
                                    :
                                    <div>{value}</div>

                        }
                    </div>
                </div>
            </div>

        )
    } else {
        if (dataType === 'class java.math.BigDecimal' && field !== 'PROP_REF' && field !== 'SURF_REF' && field !== 'REQ_REF') {
            const europeanString = value !== null ? value?.toLocaleString('de-DE') : '';
            return <div>{europeanString}</div>;
        } else {
            return <div>{value}</div>;
        }

    }
});


const CustomGridRow = React.memo((props: any) => {
    const dispatch = useAppDispatch()
    const {row} = props;
    const checkedSurfacesIds = useAppSelector(selectActivitiesCheckedSurfacesIds)
    const [disabledCheckbox, setDisabledCheckbox] = useState(false)
    const currentRowValues = useAppSelector(selectActivitiesCurrentRow)
    const handleCheckSurfaces = (value: any) => {
        setDisabledCheckbox(true)
        dispatch(onSetCurrentModalTab('Existing Links'))
        if (value.target.checked === true) {
            dispatch(onCheckSurfacesIds({
                surfaceId: Number(value.target.value.VALUE_01),
                isChecked: value.target.checked
            }))
            dispatch(onCheckSurfaces({surface: value.target.value, isChecked: value.target.checked}))
        } else {
            dispatch(onCheckSurfacesIds({
                surfaceId: Number(value.target.value.VALUE_01),
                isChecked: value.target.checked
            }))
            dispatch(onCheckSurfaces({surface: value.target.value, isChecked: value.target.checked}))
        }
        setTimeout(() => {
            setDisabledCheckbox(false)
        }, 50)
    }

    return (
        <div className={propertiesStyles.customRow}>
            <GridRow {...props} />
            {
                row.isOpened
                    ?
                    <div className={propertiesStyles.surfaceInner}>
                        {row.surfaces.map((surf: any, index: number) => {
                            return (
                                <div key={index} style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                    <Checkbox
                                        onChange={handleCheckSurfaces}
                                        name={row.PROP_ID}
                                        value={surf}
                                        checked={checkedSurfacesIds.includes(Number(surf.VALUE_01))}
                                        style={{
                                            marginRight: '8px'
                                        }}
                                        disabled={disabledCheckbox || currentRowValues.ACT_TYPE === 'Documentation'}
                                    />
                                    <span
                                        className={propertiesStyles.surfaceList}>REF {surf?.VALUE_01} | {surf?.LABEL_02} {surf?.VALUE_02} | {surf?.LABEL_03} {surf?.VALUE_03} | {surf?.LABEL_04} {surf?.VALUE_04} | {surf?.LABEL_05} {surf?.VALUE_05} | {surf?.LABEL_06} {surf?.VALUE_06}</span>
                                </div>
                            )
                        })}

                    </div>
                    :
                    null
            }
        </div>
    );
});


export default OrganizationActivitiesGrid;


