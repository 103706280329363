import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AsyncThunkConfig, RootState} from "./store";
import {AppStatusType, setAppStatus} from "./appStatusReducer";
import {gridApi, mapApi, SimilarAddressProperties} from "../app/api";
import {MapPropertyType} from "../types/mapPropertiesTypes";
import {getAbortController} from "../helpers/AbortControllerManager";
import {setAbortController} from "./propertiesReducer";


interface InitialStateType {
    mapProperties: MapPropertyType[]
    mapPropertiesForMapLayers: any[]
    mapPropertiesStatuses: any[]
    zoomRequestCoordinates: {lattop: number, latbottom: number,
        lantop: number,
        lanbottom: number} | null
    propertyMapId: number
    isFreePosition: boolean,
    mapDataNotFound: boolean
    similarMapProperties: string[]
    isDataLoading: boolean
}

const initialState: InitialStateType = {
    mapProperties: [],
    mapPropertiesForMapLayers: [],
    mapPropertiesStatuses: [],
    zoomRequestCoordinates: null,
    propertyMapId: 0,
    isFreePosition: false,
    mapDataNotFound: false,
    similarMapProperties: [],
    isDataLoading: false
}


export const mapPropertiesSlice = createSlice({
    name: 'mapProperties',
    initialState,
    reducers: {
        onSetMapPropertiesGeoData: (state, action: PayloadAction<any>) => {
            const currentPropertyAddress = action.payload.address.road + ' ' + action.payload.address.house_number
            state.mapPropertiesForMapLayers = state.mapProperties.map((p: MapPropertyType) => p.PROP_ADDR_LONGI === currentPropertyAddress ? {...p, data_geojson: action.payload.geojson} : p)
        },
        onSetMapZoomCoordinates: (state, action: PayloadAction<{lattop: number, latbottom: number,
            lantop: number,
            lanbottom: number}>) => {
            state.zoomRequestCoordinates = action.payload
        },
        onSetPropertyId: (state, action: PayloadAction<number>) => {
            state.propertyMapId = action.payload
        },
        onSetPropertyMapId: (state, action: PayloadAction<number>) => {
            state.mapProperties = state.mapProperties.map((p: any) => p.XREF_PROPERTY === action.payload ? {...p, selected: true} : p)
        },
        onClearSelectedRow: (state, action: PayloadAction<number>) => {
            state.mapProperties = state.mapProperties.map((p: any) => p.XREF_PROPERTY === action.payload ? {...p, selected: false} : p)
        },
        onSetMapProperties: (state) => {
            state.mapProperties = []
        },
        onSetMapPropertiesIsFreePosition: (state, action: PayloadAction<boolean>) => {
            state.isFreePosition = action.payload
        },
        onSetMapDataPropertiesNotFound: (state, action: PayloadAction<boolean>) => {
            state.mapDataNotFound = action.payload
        },
        onSetIsDataLoading: (state, action: PayloadAction<boolean>) => {
            state.isDataLoading = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetMapPropertiesThunk.fulfilled, (state, action) => {
                state.mapProperties = action.payload?.filter((p: MapPropertyType) => p.PROP_ADDR_LATI !== null && p.PROP_ADDR_LONGI !== null).filter((obj: any, index: any, self: any) => {
                    return index === self.findIndex((o: any) =>
                        o.PROP_ADDR_LATI === obj.PROP_ADDR_LATI &&
                        o.PROP_ADDR_LONGI === obj.PROP_ADDR_LONGI
                    );
                });
            })
            .addCase(GetMapPropertiesStatusesThunk.fulfilled, (state, action) => {
                state.mapPropertiesStatuses = action.payload.map(item => {
                    const words = item.AE_LI_VALUE.split(' ');
                    const capitalizedWords = words.map((word : string) => word.charAt(0).toUpperCase() + word.slice(1));
                    item.AE_LI_VALUE = capitalizedWords.join(' ');
                    return item;
                });
            })
            .addCase(GetMapSimilarPropertiesThunk.fulfilled, (state, action) => {
                state.similarMapProperties = action.payload
            })
    }
})

export const {
    onSetMapPropertiesGeoData,
    onSetMapZoomCoordinates,
    onSetPropertyMapId,
    onClearSelectedRow,
    onSetPropertyId,
    onSetMapProperties,
    onSetMapPropertiesIsFreePosition,
    onSetMapDataPropertiesNotFound,
    onSetIsDataLoading
} = mapPropertiesSlice.actions

export const selectMapProperties = (state: RootState): MapPropertyType[] => state.mapProperties.mapProperties
export const selectMapPropertiesForLayers = (state: RootState): any[] => state.mapProperties.mapPropertiesForMapLayers
export const selectMapPropertiesStatuses = (state: RootState): any[] => state.mapProperties.mapPropertiesStatuses
export const selectMapPropertiesZoomCoordinates = (state: RootState): {lattop: number, latbottom: number,
    lantop: number,
    lanbottom: number} | null => state.mapProperties.zoomRequestCoordinates
export const selectPropertyMapId = (state: RootState): number => state.mapProperties.propertyMapId
export const selectAddressMapIsFreePosition = (state: RootState): boolean => state.mapProperties.isFreePosition
export const selectMapDataPropertiesNotFound = (state: RootState): boolean => state.mapProperties.mapDataNotFound
export const selectSimilarMapProperties = (state: RootState): string[] => state.mapProperties.similarMapProperties
export const selectIsDataLoading = (state: RootState): boolean => state.mapProperties.isDataLoading

export const GetMapPropertiesThunk = createAsyncThunk<MapPropertyType[], {searchData: any, search_value?: string}, AsyncThunkConfig>(
    'mapProperties/getCompanyByRef',
    async (reqData, thunkAPI) => {
        thunkAPI.dispatch(setAppStatus(AppStatusType.idle))
        const abortCtrl = getAbortController();
        thunkAPI.dispatch(setAbortController(abortCtrl));
        thunkAPI.dispatch(onSetMapDataPropertiesNotFound(false))
        thunkAPI.dispatch(onSetIsDataLoading(true))
        try {
            const {status, data} = await gridApi.getMapTabProperties(reqData.searchData, {signal: abortCtrl.signal})

            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
                if (data.resultSetList === null && reqData.search_value){
                    thunkAPI.dispatch(onSetMapDataPropertiesNotFound(true))
                }
                return data.resultSetList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            if (error.message === "canceled") {

            }
            else{
                return thunkAPI.rejectWithValue(error?.response?.data?.message)
            }
        } finally {
            thunkAPI.dispatch(setAppStatus(AppStatusType.idle))
            thunkAPI.dispatch(onSetIsDataLoading(false))
        }
    }
)

export const GetMapPropertiesStatusesThunk = createAsyncThunk<any[], void, AsyncThunkConfig>(
    'mapProperties/getMapPropertiesStatuses',
    async (search_value, thunkAPI) => {
        try {
            const {status, data} = await gridApi.getMapTabStatuses()
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.resultSetList, {appStatus: AppStatusType.idle})
                return data.resultSetList
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetMapSimilarPropertiesThunk = createAsyncThunk<any[], SimilarAddressProperties, AsyncThunkConfig>(
    'mapProperties/getMapSimilarProperties',
    async (reqData, thunkAPI) => {
        try {
            const {status, data} = await mapApi.getSimilarMapProperties(reqData)
            if (status === 200 && data) {
                thunkAPI.fulfillWithValue(data.addresses, {appStatus: AppStatusType.idle})
                return data.addresses
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)


export default mapPropertiesSlice.reducer