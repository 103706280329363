import React, {useEffect, useRef, useState} from 'react';
import s from "../PropertyFilterButton/PropertyFilterButton.module.scss";
import {DatePicker, Input, Select} from "antd";
import 'react-infinite-calendar/styles.css';
import {
    GetAllPropertiesWithoutREQGridThunk,
    GetAllPropertiesWithREQGridThunk,
    onChangeColumnForFilterConditionValue,
    onChangeColumnForFilterEndValue,
    onChangeColumnForFilterStartValue,
    onChangeColumnForFilterValue,
    onFilterPropertiesGrid,
    onFilterPropertiesWithReqGrid,
    onFilterSurfacesGrid,
    onFilterUpcomingPropertiesGrid,
    onSetDefaultPropContactsRows,
    onSetFiltersButtonsModalOpened,
    onSetFiltersButtonsSelected,
    onSetIsDataUpdatedWithFilter, onSetPropContactsRemarkEditMode,
    onSetProperties,
    onSetPropertiesWithReq,
    onSetSurfaces,
    onSetTypeColumnFilterValue,
    onSetUpcomingProperties, selectBrokeragePropertiesDefaultColumnValues, selectContactPropertiesLastSearchedAddress,
    selectContactPropertiesSearchedAddress, selectPropContactsCurrentPropId, selectPropContactsRemarkEditMode,
    selectPropertiesColumnsForFilters,
    selectPropertiesGridDefaultColumnsValues,
    selectPropertiesGridFiltersForArray,
    selectPropertiesWithReqColumnsForFilters,
    selectPropertiesWithReqGridFiltersForArray,
    selectSurfacesColumnsForFilters,
    selectSurfacesDefaultGridColumns,
    selectSurfacesGridFiltersForArray,
    selectSurfaceTypeColumnFilterValue,
    selectUpcomingPropertiesColumnsForFilters, selectUpcomingPropertiesDefaultColumnValues,
    selectUpcomingPropertiesGridFiltersForArray,
} from "../../../store/propertiesReducer";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import moment from 'moment';
import {
    ActivitiesTypeKind,
    onChangeActivitiesGridFilterConditionValue,
    onChangeActivitiesGridFilterEndValue,
    onChangeActivitiesGridFilterStartValue,
    onChangeActivitiesGridFilterValue,
    onSetActivitiesGridData,
    onSetActivitiesGridFiltersButtonsModalOpened,
    onSetActivitiesGridFiltersButtonsSelected,
    onSetFiltersForActivitiesGrid,
    selectActivitiesGridDefaultRows,
    selectActivitiesGridFilterColumns,
    selectActivitiesGridFiltersForColumns, selectCurrentActivityKind, selectCurrentActivityType,
    selectFiltersActivitiesTypeKind,
    selectFiltersActivitiesTypes,
    setActivitiesCurrentKind,
    setActivitiesCurrentType
} from "../../../store/activitesGrid";
import {gridDataHelper} from "../../../helpers/localStorageHelper";
import {
    onChangeRequirementsGridFilterConditionValue, onChangeRequirementsGridFilterEndValue,
    onChangeRequirementsGridFilterStartValue,
    onChangeRequirementsGridFilterValue,
    onSetFiltersForRequirementsGrid,
    onSetRequirements,
    onSetRequirementsGridFiltersButtonsModalOpened,
    onSetRequirementsGridFiltersButtonsSelected,
    selectRequirementGridColumnsForFilters, selectRequirementsDefaultGridColumns,
    selectRequirementsGridFiltersForArray
} from "../../../store/requirementsReducer";
import {
    onChangeAttrCodesGridFilterConditionValue,
    onChangeAttrCodesGridFilterStartValue,
    onChangeAttrCodesGridFilterValue,
    onChangeAuditGridFilterConditionValue,
    onChangeAuditGridFilterEndValue,
    onChangeAuditGridFilterStartValue,
    onChangeAuditGridFilterValue,
    onSetAttrCodesCurrentAddrStreet,
    onSetAttrCodesCurrentDomain,
    onSetAttrCodesCurrentPosition,
    onSetAttrCodesGridFiltersButtonsModalOpened,
    onSetAttrCodesGridFiltersButtonsSelected,
    onSetAttrCodesReqs,
    onSetAuditGridFiltersButtonsModalOpened,
    onSetAuditGridFiltersButtonsSelected,
    onSetBrokeragePropsCurrentStatus,
    onSetFiltersForAttrCodesGrid,
    onSetFiltersForAuditGrid,
    onSetLastUpcomingPropsCurrentStatus,
    onSetPropsAndSurfacesCurrentStatus,
    onSetUpcomingPropsCurrentStatus,
    selectActivityAttributeCodeDefaultRows,
    selectAttrCodesAgentPositions,
    selectAttrCodesColumnsForFilters,
    selectAttrCodesCurrentDomain,
    selectAttrCodesCurrentPosition,
    selectAttrCodesFiltersForArray,
    selectAuditDefaultColumns,
    selectAuditGridColumnsForFilters,
    selectAuditGridFiltersForArray,
    selectBrokeragePropsCurrentStatus,
    selectDynamicInputWidth,
    selectLatestUpcomingPropsCurrentStatus,
    selectPropsAndSurfacesCurrentPosition,
    selectUpcomingPropsCurrentStatus,
    setUserActivities
} from "../../../store/parametersReducer";
import {
    onChangeBrokerageReqsGridFilterConditionValue,
    onChangeBrokerageReqsGridFilterEndValue,
    onChangeBrokerageReqsGridFilterStartValue, onChangeBrokerageReqsGridFilterValue,
    onSetBrokerageReqs,
    onSetBrokerageReqsGridFiltersButtonsModalOpened,
    onSetBrokerageReqsGridFiltersButtonsSelected,
    onSetFiltersForBrokerageReqsGrid,
    selectBrokerageReqsColumnsForFilters, selectBrokerageReqsDefaultColumns,
    selectBrokerageReqsGridFiltersForArray
} from "../../../store/brokerageReqsReducer";
import {selectMapPropertiesStatuses} from "../../../store/propertiesMap";
import {selectUserData} from "../../../store/userReducer";
import {getAbortController} from "../../../helpers/AbortControllerManager";
import PrimaryButton from "../../common/Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../common/Buttons/SecondaryButton/SecondaryButton";
import {selectSurfaceUsagesTypes} from "../../../store/utilityReducer";
import {sessionStorageGridFilters} from "../../../helpers/sessionStorageHelper";
import 'react-calendar/dist/Calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import {contactsApi} from "../../../app/api";
import TextArea from 'antd/lib/input/TextArea';
type PropertyFilterButtonPropsType = {
    textValue: string,
    gridType: string,
    isMobile?: boolean
    currentGridProperties?: any[]
    setLoadedRows?: (loadedRows: any[]) => void
}
type Handler = (event: MouseEvent | TouchEvent) => void;


const PropertyFilterModal = ({textValue, gridType, isMobile, currentGridProperties}: PropertyFilterButtonPropsType) => {
    const [inputValue, setInputValue] = useState('')
    const [selectedValue, setSelectedValue] = useState('equals')
    const [selectedDate, setSelectedDate] = useState('')
    const [dateCondition, setDateCondition] = useState('onDate')
    // const [currentDomain, setCurrentDomain] = useState<string | undefined>(undefined)
    const currentDomain = useAppSelector(selectAttrCodesCurrentDomain)
    const currentPosition = useAppSelector(selectAttrCodesCurrentPosition)
    const dispatch = useAppDispatch()
    //property grid data
    const propertiesGridColumnFilters = useAppSelector(selectPropertiesGridFiltersForArray)
    let propertiesColumnsForFilters = useAppSelector(selectPropertiesColumnsForFilters)
    //all properties grid data
    const upcomingPropertiesGridColumnFilters = useAppSelector(selectUpcomingPropertiesGridFiltersForArray)
    let upcomingPropertiesColumnsForFilters = useAppSelector(selectUpcomingPropertiesColumnsForFilters)
    const brokeragePropertiesGridColumnFilters = useAppSelector(selectPropertiesWithReqGridFiltersForArray)
    let brokeragePropertiesColumnsForFilters = useAppSelector(selectPropertiesWithReqColumnsForFilters)
    const upcomingPropertiesDefaultGridColumns = useAppSelector(selectUpcomingPropertiesDefaultColumnValues)
    const brokeragePropertiesDefaultGridColumns = useAppSelector(selectBrokeragePropertiesDefaultColumnValues)
    //
    let activitiesGridColumnsForFilters = useAppSelector(selectActivitiesGridFilterColumns)
    let activitiesColumnsForFilters = useAppSelector(selectActivitiesGridFiltersForColumns)
    //surfaces grid data
    let surfacesColumnsForFilters = useAppSelector(selectSurfacesColumnsForFilters)
    let surfacesGridColumnFilters = useAppSelector(selectSurfacesGridFiltersForArray)
    const defaultPropertiesGridColumns = useAppSelector(selectPropertiesGridDefaultColumnsValues)
    const defaultActivitiesGridRows = useAppSelector(selectActivitiesGridDefaultRows)
    const defaultSurfacesGridColumns = useAppSelector(selectSurfacesDefaultGridColumns)
    //req grid data
    const requirementsGridColumnFilters = useAppSelector(selectRequirementsGridFiltersForArray)
    let requirementsColumnsForFilters = useAppSelector(selectRequirementGridColumnsForFilters)
    const defaultRequirementGridColumns = useAppSelector(selectRequirementsDefaultGridColumns)
    //audit grid data
    const auditsGridColumnFilters = useAppSelector(selectAuditGridFiltersForArray)
    let auditColumnsForFilters = useAppSelector(selectAuditGridColumnsForFilters)
    const defaultAuditGridColumns = useAppSelector(selectAuditDefaultColumns)
    //brokerage reqs data
    const brokerageReqsGridColumnFilters = useAppSelector(selectBrokerageReqsGridFiltersForArray)
    let brokerageReqsColumnsForFilters = useAppSelector(selectBrokerageReqsColumnsForFilters)
    const defaultBrokerageReqsGridColumns = useAppSelector(selectBrokerageReqsDefaultColumns)
    //attr codes grid data
    const attrCodesGridColumnFilters = useAppSelector(selectAttrCodesFiltersForArray)
    let attrCodesColumnsForFilters = useAppSelector(selectAttrCodesColumnsForFilters)
    const defaultAttrCodesGridColumns = useAppSelector(selectActivityAttributeCodeDefaultRows)
    const mapStatuses = useAppSelector(selectMapPropertiesStatuses)
    const ref = useRef<HTMLDivElement>(null);
    const agentPositions = useAppSelector(selectAttrCodesAgentPositions)
    const userData = useAppSelector(selectUserData)
    const upcomingPropsStatus = useAppSelector(selectUpcomingPropsCurrentStatus)
    const brokeragePropsStatus = useAppSelector(selectBrokeragePropsCurrentStatus)
    const propsAndSurfacesStatus = useAppSelector(selectPropsAndSurfacesCurrentPosition)
    const currentStatus = gridType === 'All Properties/Upcoming' ? upcomingPropsStatus : gridType === 'All Properties/REQ' ? brokeragePropsStatus : (gridType === 'Properties' || gridType === 'Surfaces') ? propsAndSurfacesStatus : null
    const activitiesTypes = useAppSelector(selectFiltersActivitiesTypes)
    const activitiesKinds = useAppSelector(selectFiltersActivitiesTypeKind)
    const currentActivityType = useAppSelector(selectCurrentActivityType)
    const currentActivityKind = useAppSelector(selectCurrentActivityKind)
    const contactPropsSearchedAddress = useAppSelector(selectContactPropertiesSearchedAddress)
    const surfaceUsagesTypes = useAppSelector(selectSurfaceUsagesTypes)
    const surfaceTypeColumnFilterValue = useAppSelector(selectSurfaceTypeColumnFilterValue)
    const dynamicInputWidth = useAppSelector(selectDynamicInputWidth)
    const remarksEditMode = useAppSelector(selectPropContactsRemarkEditMode)
    const propContactsCurrentPropId = useAppSelector(selectPropContactsCurrentPropId)
    const domainNames = [
        {value: 'default', label: 'Select domain'},
        {value: 'CD', label: 'ČINŽOVNÍ DŮM'},
        {value: 'RS', label: 'E&V-PRAGUE RESIDENTIAL'},
        {value: 'RR', label: 'E&V-PRAGUE RENTAL'},
        {value: 'OR', label: 'OFFICE-RENT'},
    ]

    const selectMapStatuses = gridType !== 'All Properties/Upcoming' ? mapStatuses.map((status: any) => ({
        value: `${status.AE_NO_INDEX} `,
        label: `${status.AE_LI_VALUE}`,
    })) : mapStatuses.map((status: any) => ({
        value: `${status.AE_NO_INDEX} `,
        label: `${status.AE_LI_VALUE}`,
    })).concat({
        value: 'All',
        label: `All`,
    })

    const activitiesTypesValues = activitiesTypes.map((type: ActivitiesTypeKind) => ({
        value: `${type.AE_LI_VALUE} `,
        label: `${type.AE_LI_VALUE}`,
    }))


    const surfaceTypesValues = surfaceUsagesTypes.map((type: { id: number, value: string }) => ({
        value: `${type.value} `,
        label: `${type.value}`,
    }))

    const activitiesKindsValues = activitiesKinds.map((type: ActivitiesTypeKind) => ({
        value: `${type.VALUE} `,
        label: `${type.VALUE}`,
    }))


    const currentColumn =
        gridType === 'Properties' ? propertiesColumnsForFilters.find((c: any) => c.title === textValue)
            : gridType === 'Activities Grid' ? activitiesGridColumnsForFilters.find((c: any) => c.title === textValue)
                : gridType === 'Surfaces' ? surfacesColumnsForFilters.find((c: any) => c.title === textValue)
                    : gridType === 'Requirements' ? requirementsColumnsForFilters.find((c: any) => c.title === textValue)
                        : gridType === 'Audit' ? auditColumnsForFilters.find((c: any) => c.title === textValue)
                            : gridType === 'Brokerage/Requirements' ? brokerageReqsColumnsForFilters?.find((c: any) => c.title === textValue)
                                : gridType === 'AttrCodes' ? attrCodesColumnsForFilters?.find((c: any) => c.title === textValue)
                                    : gridType === 'All Properties/Upcoming' ? upcomingPropertiesColumnsForFilters?.find((c: any) => c.title === textValue)
                                        : gridType === 'All Properties/REQ' ? brokeragePropertiesColumnsForFilters?.find((c: any) => c.title === textValue)
                                            : []


    // useEffect for tracking outside the filters modal click to close it , when user click outside the filters modal
    useEffect(() => {
        if (!isMobile) {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
        // eslint-disable-next-line
    }, [isMobile]);
    // Function for tracking outside the filters modal click to close it , when user click outside the filters modal
    const handleClickOutside: Handler = (event) => {
        if (!isMobile) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                // Check if the event occurred within the Select dropdown or selector
                if (
                    (event.target as HTMLElement).closest('.ant-select-dropdown') ||
                    (event.target as HTMLElement).closest('.ant-select-selector') ||
                    (event.target as HTMLElement).closest('.ant-picker-dropdown') // Add this line to include DatePicker
                ) {
                    return;
                }

                // Close the modal if the click is outside both the Select and the div
                dispatch(onSetFiltersButtonsModalOpened({
                    headerName: currentColumn.headerName,
                    isModalOpened: false,
                    gridType: gridType
                }));
                dispatch(onChangeColumnForFilterConditionValue({
                    title: currentColumn.headerName,
                    value: '',
                    gridType: gridType
                }));
                dispatch(onSetActivitiesGridFiltersButtonsModalOpened({
                    headerName: currentColumn.headerName,
                    isModalOpened: false
                }));
                dispatch(onChangeActivitiesGridFilterConditionValue({
                    title: currentColumn.headerName,
                    value: ''
                }));
                dispatch(onSetRequirementsGridFiltersButtonsModalOpened({
                    headerName: currentColumn.headerName,
                    isModalOpened: false
                }));
                dispatch(onChangeRequirementsGridFilterConditionValue({
                    title: currentColumn.headerName,
                    value: ''
                }));
                dispatch(onSetAuditGridFiltersButtonsModalOpened({
                    headerName: currentColumn.headerName,
                    isModalOpened: false
                }));
                dispatch(onChangeAuditGridFilterConditionValue({
                    title: currentColumn.headerName,
                    value: ''
                }));
                dispatch(onSetBrokerageReqsGridFiltersButtonsModalOpened({
                    headerName: currentColumn.headerName,
                    isModalOpened: false
                }));
                dispatch(onChangeBrokerageReqsGridFilterConditionValue({
                    title: currentColumn.headerName,
                    value: ''
                }));
                dispatch(onSetAttrCodesGridFiltersButtonsModalOpened({
                    headerName: currentColumn.headerName,
                    isModalOpened: false
                }));
                dispatch(onChangeAttrCodesGridFilterConditionValue({
                    title: currentColumn.headerName,
                    value: ''
                }));
            }
        }
    };
    const onSaveFilter = (property: any, value: any, type: any, startValue?: any, endValue?: any) => {
        const newFilter = {
            property,
            value,
            type,
            condition: selectedValue,
            dateCondition: dateCondition,
            date: selectedDate,
            startValue,
            endValue
        }
        // Function for converting grid numbers to euroFormat
        function convertToNumber(euroFormatStr: any) {
            if (typeof euroFormatStr === 'string') {
                const standardFormatStr = euroFormatStr.replace('.', '').replace(',', '.');
                return parseFloat(standardFormatStr)
            } else {
                return euroFormatStr
            }
        }

        if (!value) {
            if (property === 'DOMAIN_CD') {
                dispatch(onChangeAttrCodesGridFilterValue({
                    title: currentColumn.headerName,
                    value: currentColumn.value!
                }))
                dispatch(onSetAttrCodesCurrentDomain(currentColumn.value!))
                console.log(currentColumn.value!)
            }
            if (property === 'POSITION') {
                dispatch(onChangeAttrCodesGridFilterValue({title: currentColumn.headerName, value: currentPosition!}))
            }
            if(property === 'ACT_KIND'){
                dispatch(setActivitiesCurrentKind(currentActivityKind!!))
                dispatch(onChangeAttrCodesGridFilterValue({title: currentColumn.headerName, value: currentActivityKind!}))
            }
            if(property === 'ACT_TYPE'){
                dispatch(setActivitiesCurrentType(currentActivityType!!))
                dispatch(onChangeAttrCodesGridFilterValue({title: currentColumn.headerName, value: currentActivityType!}))
            }
        } else {
            if (property === 'DOMAIN_CD') {
                console.log(value)
                dispatch(onChangeAttrCodesGridFilterValue({title: currentColumn.headerName, value: value!}))
                dispatch(onSetAttrCodesCurrentDomain(value!))
            }
            if (property === 'POSITION') {
                dispatch(onChangeAttrCodesGridFilterValue({title: currentColumn.headerName, value: currentPosition!}))
            }
            if (property === 'STATUS') {
                dispatch(onChangeColumnForFilterValue({
                    title: currentColumn.headerName,
                    value: Number(value) === 1 ? 'Active' : Number(value) === 2 ? 'Passive' : 'All',
                    gridType: gridType
                }))
                if (gridType === 'All Properties/Upcoming') {
                    dispatch(onSetLastUpcomingPropsCurrentStatus(currentStatus))
                    dispatch(onSetUpcomingPropsCurrentStatus(value))
                    // if(lastSearchedAddress !== contactPropsSearchedAddress){
                        if (contactPropsSearchedAddress !== '') {
                            dispatch(GetAllPropertiesWithoutREQGridThunk({
                                requestData: {
                                    p_agent_ref: Number(userData.user_ref),
                                    p_function: null,
                                    p_sort_order: null,
                                    p_address: contactPropsSearchedAddress,
                                    p_status: Number(currentStatus)
                                },
                            }))
                        } else {
                            dispatch(GetAllPropertiesWithoutREQGridThunk({
                                requestData: {
                                    p_agent_ref: Number(userData.user_ref),
                                    p_function: null,
                                    p_sort_order: null,
                                    p_address: null,
                                    p_status: Number(currentStatus)
                                },
                            }))
                        }
                    // }
                    // if(lastUpcomingStatus !== null && lastUpcomingStatus !== 'All' && lastSearchedAddress !== contactPropsSearchedAddress) {
                    //     if (contactPropsSearchedAddress !== '') {
                    //         dispatch(GetAllPropertiesWithoutREQGridThunk({
                    //             requestData: {
                    //                 p_agent_ref: Number(userData.user_ref),
                    //                 p_function: null,
                    //                 p_sort_order: null,
                    //                 p_address: contactPropsSearchedAddress,
                    //                 p_status: Number(currentStatus)
                    //             },
                    //         }))
                    //     } else {
                    //         dispatch(GetAllPropertiesWithoutREQGridThunk({
                    //             requestData: {
                    //                 p_agent_ref: Number(userData.user_ref),
                    //                 p_function: null,
                    //                 p_sort_order: null,
                    //                 p_address: null,
                    //                 p_status: Number(currentStatus)
                    //             },
                    //         }))
                    //     }
                    // }
                } else if (gridType === 'All Properties/REQ') {
                    dispatch(onSetBrokeragePropsCurrentStatus(value))
                    dispatch(GetAllPropertiesWithREQGridThunk({
                        requestData: {
                            p_agent_ref: Number(userData.user_ref),
                            p_status: Number(value),
                        },
                    }))
                        .then(() => {
                            dispatch(onSetIsDataUpdatedWithFilter(true))
                        })

                }

            }

            if(remarksEditMode){
                contactsApi.updateContactRemark(propContactsCurrentPropId, value)
                    .then(() => {
                        const modifiedRows = currentGridProperties?.map((p) => p.PE_REF === propContactsCurrentPropId ? {
                            ...p,
                            PE_REMARKS: value
                        } : p)!!
                        dispatch(onSetUpcomingProperties({properties: modifiedRows}))
                        dispatch(onSetDefaultPropContactsRows(modifiedRows))
                    })
                    .then(() => {
                            dispatch(onSetFiltersButtonsModalOpened({
                                headerName: currentColumn.headerName,
                                isModalOpened: false,
                                gridType: 'All Properties/Upcoming'
                            }))
                            dispatch(onChangeColumnForFilterValue({
                                title: currentColumn.headerName,
                                value: null,
                                gridType: 'All Properties/Upcoming'
                            }))
                            dispatch(onSetPropContactsRemarkEditMode(false))
                    })
            }
            else{
                const filtersForArray: any =
                    gridType === 'Properties'
                        ? [...propertiesGridColumnFilters.filter((c: any) => c.property !== newFilter.property), newFilter]
                        : gridType === 'Activities Grid' ? [...activitiesColumnsForFilters.filter((c: any) => c.property !== newFilter.property), newFilter]
                            : gridType === 'Surfaces' ? [...surfacesGridColumnFilters.filter((c: any) => c.property !== newFilter.property), newFilter]
                                : gridType === 'Requirements' ? [...requirementsGridColumnFilters.filter((c: any) => c.property !== newFilter.property), newFilter]
                                    : gridType === 'Audit' ? [...auditsGridColumnFilters.filter((c: any) => c.property !== newFilter.property), newFilter]
                                        : gridType === 'Brokerage/Requirements' ? [...brokerageReqsGridColumnFilters.filter((c: any) => c.property !== newFilter.property), newFilter]
                                            : gridType === 'AttrCodes' ? [...attrCodesGridColumnFilters.filter((c: any) => c.property !== newFilter.property), newFilter]
                                                : gridType === 'All Properties/Upcoming' ? [...upcomingPropertiesGridColumnFilters.filter((c: any) => c.property !== newFilter.property), newFilter]
                                                    : gridType === 'All Properties/REQ' ? [...brokeragePropertiesGridColumnFilters.filter((c: any) => c.property !== newFilter.property), newFilter]
                                                        : []


                //Grid filtering logic

                let arrayForFiltering =
                    gridType === 'Properties' ? defaultPropertiesGridColumns
                        : gridType === 'Activities Grid' ? defaultActivitiesGridRows
                            : gridType === 'Surfaces' ? defaultSurfacesGridColumns
                                : gridType === 'Requirements' ? defaultRequirementGridColumns
                                    : gridType === 'Audit' ? defaultAuditGridColumns
                                        : gridType === 'Brokerage/Requirements' ? defaultBrokerageReqsGridColumns
                                            : gridType === 'AttrCodes' ? defaultAttrCodesGridColumns
                                                : gridType === 'All Properties/Upcoming' ? upcomingPropertiesDefaultGridColumns
                                                    : gridType === 'All Properties/REQ' ? brokeragePropertiesDefaultGridColumns
                                                        : []


                filtersForArray.forEach((a: any) => {
                        if (a.type === 'class java.lang.String' && a.property === "STATUS") {
                            arrayForFiltering = arrayForFiltering.filter((obj: any) => {
                                const objValue = obj[a.property]?.toLowerCase() || null;
                                // if (Number(a.value) === 1) {
                                //     // Filter as if 'active'
                                //     return objValue === 'active';
                                // } else if (Number(a.value)  === 2) {
                                //     // Filter as if 'passive'
                                //     return objValue === 'passive';
                                // } else {
                                // Keep original filtering condition
                                return objValue === null || objValue === 'active' || objValue === 'passive';
                                // }
                            });
                        }
                        if (a.type === 'class java.lang.String' && a.property === "ACTIVITY_EXISTED") {
                            arrayForFiltering = arrayForFiltering.filter((p: any) => p[a.property] !== null).filter((obj: any) =>
                                obj[a.property] !== null && a.value === 'yes' ? Number(obj[a.property]) === 1
                                    : obj[a.property] !== null && a.value === 'Yes' ? Number(obj[a.property]) === 1
                                        : obj[a.property] !== null && a.value === 'no' ? Number(obj[a.property]) === 0
                                            : obj[a.property] !== null && a.value === 'No' ? Number(obj[a.property]) === 0
                                                : null);
                        }
                        if (a.type === 'class java.lang.String' && a.property !== "ACTIVITY_EXISTED" && a.property !== "POSITION" && a.property !== "STATUS") {
                            if (a.value.trim().endsWith('%')) {
                                arrayForFiltering = arrayForFiltering.filter((p: any) => p[a.property] !== null).filter((obj: any) => obj[a.property]!.toLowerCase().startsWith(a.value!.slice(0, -1).toLowerCase()));
                            } else {
                                arrayForFiltering = arrayForFiltering.filter((p: any) => p[a.property] !== null).filter((obj: any) => obj[a.property]!.toLowerCase().startsWith(a.value!.trim().toLowerCase()) || obj[a.property]!.toLowerCase().includes(a.value!.trim().toLowerCase()));
                            }
                        }
                        if (a.type === 'class java.lang.String' && a.property === "POSITION" && a.value === 'no position') {
                            arrayForFiltering = arrayForFiltering.filter((obj: any) => obj['POSITION'] === null);
                        }
                        if (a.type === 'class java.lang.String' && a.property === "POSITION" && a.value !== 'no position') {
                            arrayForFiltering = arrayForFiltering.filter((p: any) => p[a.property] !== null).filter((obj: any) => obj[a.property]!.toLowerCase().startsWith(a.value!.toLowerCase()) || obj[a.property]!.toLowerCase().includes(a.value!.toLowerCase()));
                        }
                        if (a.type === 'class java.math.BigDecimal') {
                            arrayForFiltering = arrayForFiltering.filter((obj: any) =>
                                obj[a.property] !== null && a.condition === 'less' ? convertToNumber(obj[a.property]) < convertToNumber(a.value)
                                    : obj[a.property] !== null && a.condition === 'equals' ? convertToNumber(obj[a.property]) === convertToNumber(a.value)
                                        : obj[a.property] !== null && a.condition === 'higher' ? convertToNumber(obj[a.property]) > convertToNumber(a.value)
                                            : obj[a.property] !== null && a.condition === 'between' ? convertToNumber(obj[a.property]) >= convertToNumber(a.startValue) && convertToNumber(obj[a.property]) <= convertToNumber(a.endValue) : null);
                        }
                        if (a.type === 'class java.time.LocalDateTime' || a.type === "class oracle.sql.TIMESTAMP") {
                            arrayForFiltering = arrayForFiltering.filter((obj: any) => {
                                const date = moment(obj[a.property]);
                                return a.dateCondition === 'onDate' ? date.isSame(a.value, "day") : a.dateCondition === 'before' ? date.isBefore(a.value, "day") : date.isAfter(a.value, "day");
                            });
                        }
                        if (gridType === 'Requirements') {
                            dispatch(onSetRequirements({requirementsRows: arrayForFiltering}))
                        } else if (gridType === 'Activities Grid') {
                            dispatch(onSetActivitiesGridData({properties: arrayForFiltering}))
                        } else if (gridType === 'Audit') {
                            dispatch(setUserActivities(arrayForFiltering))
                        } else if (gridType === 'Brokerage/Requirements') {
                            dispatch(onSetBrokerageReqs(arrayForFiltering))
                        } else if (gridType === 'AttrCodes') {
                            if (property === 'DOMAIN_CD') {
                                dispatch(onSetAttrCodesReqs(arrayForFiltering.map((a: any, index: number) => a && {
                                    ...a,
                                    ORDER_NUM: index + 1
                                })))
                            } else {
                                dispatch(onSetAttrCodesReqs(arrayForFiltering.map((a: any, index: number) => a && {
                                    ...a,
                                    ORDER_NUM: index + 1
                                })))
                            }
                        } else if (gridType === 'Properties') {
                            dispatch(onSetProperties({properties: arrayForFiltering, gridType: gridType}))
                        } else if (gridType === 'Surfaces') {
                            dispatch(onSetSurfaces({properties: arrayForFiltering, gridType: gridType}))
                        } else if (gridType === 'All Properties/Upcoming') {
                            dispatch(onSetUpcomingProperties({properties: arrayForFiltering, gridType: gridType}))
                        } else if (gridType === 'All Properties/REQ') {
                            dispatch(onSetPropertiesWithReq({properties: arrayForFiltering, gridType: gridType}))
                        }
                        // dispatch(onSetProperties({properties: arrayForFiltering, gridType: gridType}))
                        // dispatch(onSetActivitiesGridData({properties: arrayForFiltering}))
                    }
                )
                if (gridType === 'Requirements') {
                    // requirements grid
                    dispatch(onSetFiltersForRequirementsGrid({gridFilters: filtersForArray}))
                    dispatch(onSetRequirementsGridFiltersButtonsModalOpened({
                        headerName: currentColumn.headerName,
                        isModalOpened: false
                    }))
                    dispatch(onSetRequirementsGridFiltersButtonsSelected({
                        headerName: currentColumn.headerName,
                        isFilterSelected: true
                    }))
                    sessionStorageGridFilters.setRequirementsGridFilters(filtersForArray)
                } else if (gridType === 'Activities Grid') {
                    // activities grid
                    dispatch(onSetFiltersForActivitiesGrid({gridFilters: filtersForArray}))
                    dispatch(onSetActivitiesGridFiltersButtonsModalOpened({
                        headerName: currentColumn.headerName,
                        isModalOpened: false
                    }))
                    dispatch(onSetActivitiesGridFiltersButtonsSelected({
                        headerName: currentColumn.headerName,
                        isFilterSelected: true
                    }))
                    sessionStorageGridFilters.setActivitiesGridFilters(filtersForArray)
                } else if (gridType === 'Audit') {
                    // audit grid
                    dispatch(onSetFiltersForAuditGrid({gridFilters: filtersForArray}))
                    dispatch(onSetAuditGridFiltersButtonsModalOpened({
                        headerName: currentColumn.headerName,
                        isModalOpened: false
                    }))
                    dispatch(onSetAuditGridFiltersButtonsSelected({
                        headerName: currentColumn.headerName,
                        isFilterSelected: true
                    }))
                    sessionStorageGridFilters.setAuditGridFilters(filtersForArray)
                } else if (gridType === 'Brokerage/Requirements') {
                    // brokerage/requirements grid
                    dispatch(onSetFiltersForBrokerageReqsGrid({gridFilters: filtersForArray}))
                    dispatch(onSetBrokerageReqsGridFiltersButtonsModalOpened({
                        headerName: currentColumn.headerName,
                        isModalOpened: false
                    }))
                    dispatch(onSetBrokerageReqsGridFiltersButtonsSelected({
                        headerName: currentColumn.headerName,
                        isFilterSelected: true
                    }))
                    sessionStorageGridFilters.setBrokeragePropertiesGridFilters(filtersForArray)
                } else if (gridType === 'AttrCodes') {
                    // attr codes grid
                    dispatch(onSetFiltersForAttrCodesGrid({gridFilters: filtersForArray}))
                    dispatch(onSetAttrCodesGridFiltersButtonsModalOpened({
                        headerName: currentColumn.headerName,
                        isModalOpened: false
                    }))
                    dispatch(onSetAttrCodesGridFiltersButtonsSelected({
                        headerName: currentColumn.headerName,
                        isFilterSelected: true
                    }))
                    gridDataHelper.setAttrGridFilters(filtersForArray)
                } else if (gridType === 'Properties') {
                    dispatch(onFilterPropertiesGrid({gridFilters: filtersForArray, gridType: gridType}))
                    dispatch(onSetFiltersButtonsModalOpened({
                        headerName: currentColumn.headerName,
                        isModalOpened: false,
                        gridType: gridType
                    }))
                    dispatch(onSetFiltersButtonsSelected({
                        headerName: currentColumn.headerName,
                        isFilterSelected: true,
                        gridType: gridType
                    }))
                    sessionStorageGridFilters.setPropertiesGridFilters(filtersForArray)
                }
                else if (gridType === 'Surfaces') {
                    dispatch(onFilterSurfacesGrid({gridFilters: filtersForArray, gridType: gridType}))
                    dispatch(onSetFiltersButtonsModalOpened({
                        headerName: currentColumn.headerName,
                        isModalOpened: false,
                        gridType: gridType
                    }))
                    dispatch(onSetFiltersButtonsSelected({
                        headerName: currentColumn.headerName,
                        isFilterSelected: true,
                        gridType: gridType
                    }))
                    sessionStorageGridFilters.setSurfacesGridFilters(filtersForArray)
                }
                else if (gridType === 'All Properties/Upcoming') {
                    dispatch(onFilterUpcomingPropertiesGrid({gridFilters: filtersForArray, gridType: gridType}))
                    dispatch(onSetFiltersButtonsModalOpened({
                        headerName: currentColumn.headerName,
                        isModalOpened: false,
                        gridType: gridType
                    }))
                    dispatch(onSetFiltersButtonsSelected({
                        headerName: currentColumn.headerName,
                        isFilterSelected: true,
                        gridType: gridType
                    }))
                    sessionStorageGridFilters.setContactPropertiesGridFilters(filtersForArray)
                }
                else if (gridType === 'All Properties/REQ') {
                    dispatch(onFilterPropertiesWithReqGrid({gridFilters: filtersForArray, gridType: gridType}))
                    dispatch(onSetFiltersButtonsModalOpened({
                        headerName: currentColumn.headerName,
                        isModalOpened: false,
                        gridType: gridType
                    }))
                    dispatch(onSetFiltersButtonsSelected({
                        headerName: currentColumn.headerName,
                        isFilterSelected: true,
                        gridType: gridType
                    }))
                    sessionStorageGridFilters.setPropertiesWithReqGridFilters(filtersForArray)
                }

            }


            // else {
            //     //properties grid/all propertied grid
            //     dispatch(onFilterPropertiesGrid({gridFilters: filtersForArray, gridType: gridType}))
            //     dispatch(onSetFiltersButtonsModalOpened({
            //         headerName: currentColumn.headerName,
            //         isModalOpened: false,
            //         gridType: gridType
            //     }))
            //     dispatch(onSetFiltersButtonsSelected({
            //         headerName: currentColumn.headerName,
            //         isFilterSelected: true,
            //         gridType: gridType
            //     }))
            //     gridDataHelper.setPropertiesGridFilters(filtersForArray)
            // }
        }


    }


    const onInputChange = (value: any) => {
        if (currentColumn.headerName === 'Addr_street') {
            dispatch(onSetAttrCodesCurrentAddrStreet(value.target.value))
        }
        if (gridType === 'Requirements') {
            dispatch(onChangeRequirementsGridFilterValue({title: currentColumn.headerName, value: value.target.value}))
        } else if (gridType === 'Activities Grid') {
            dispatch(onChangeActivitiesGridFilterValue({title: currentColumn.headerName, value: value.target.value}))
        } else if (gridType === 'Audit') {
            // audit grid
            dispatch(onChangeAuditGridFilterValue({title: currentColumn.headerName, value: value.target.value}))
        } else if (gridType === 'Brokerage/Requirements') {
            // brokerage/reqs grid
            dispatch(onChangeBrokerageReqsGridFilterValue({title: currentColumn.headerName, value: value.target.value}))
        } else if (gridType === 'AttrCodes') {
            // attr codes grid
            dispatch(onChangeAttrCodesGridFilterValue({title: currentColumn.headerName, value: value.target.value}))
            // console.log(value.target.value)
        }
        else {
            //properties grid/all propertied grid
            dispatch(onChangeColumnForFilterValue({
                title: currentColumn.headerName,
                value: value.target.value,
                gridType: gridType
            }))
        }
    }
    // console.log(currentColumn?.headerName)
    const onInputStartsChange = (value: any) => {
        if (gridType === 'Requirements') {
            dispatch(onChangeRequirementsGridFilterStartValue({
                title: currentColumn.headerName,
                value: value.target.value
            }))
        } else if (gridType === 'Activities Grid') {
            dispatch(onChangeActivitiesGridFilterStartValue({
                title: currentColumn.headerName,
                value: value.target.value
            }))
        } else if (gridType === 'Audit') {
            // audit grid
            dispatch(onChangeAuditGridFilterStartValue({
                title: currentColumn.headerName,
                value: value.target.value
            }))
        } else if (gridType === 'Brokerage/Requirements') {
            // brokerage/reqs grid
            dispatch(onChangeBrokerageReqsGridFilterStartValue({
                title: currentColumn.headerName,
                value: value.target.value
            }))
        } else if (gridType === 'AttrCodes') {
            // brokerage/reqs grid
            dispatch(onChangeAttrCodesGridFilterStartValue({
                title: currentColumn.headerName,
                value: value.target.value
            }))
        } else {
            //properties grid/all propertied grid
            dispatch(onChangeColumnForFilterStartValue({
                title: currentColumn.headerName,
                value: value.target.value,
                gridType: gridType
            }))
        }
    }

    const onInputEndChange = (value: any) => {
        if (gridType === 'Requirements') {
            dispatch(onChangeRequirementsGridFilterEndValue({
                title: currentColumn.headerName,
                value: value.target.value
            }))
        } else if (gridType === 'Activities Grid') {
            dispatch(onChangeActivitiesGridFilterEndValue({title: currentColumn.headerName, value: value.target.value}))
        } else if (gridType === 'Audit') {
            // audit grid
            dispatch(onChangeAuditGridFilterEndValue({
                title: currentColumn.headerName,
                value: value.target.value
            }))
        } else if (gridType === 'Brokerage/Requirements') {
            // brokerage/reqs grid
            dispatch(onChangeBrokerageReqsGridFilterEndValue({
                title: currentColumn.headerName,
                value: value.target.value
            }))
        } else {
            //properties grid/all propertied grid
            dispatch(onChangeColumnForFilterEndValue({
                title: currentColumn.headerName,
                value: value.target.value,
                gridType: gridType
            }))
        }
    }

    const onSelectValue = (value: any) => {


        setSelectedValue(value)

        if (currentColumn.headerName === 'Domain_CD') {
            dispatch(onChangeAttrCodesGridFilterValue({title: currentColumn.headerName, value: value}))
            // dispatch(onSetAttrCodesCurrentDomain(value))
        } else if (currentColumn.headerName === 'Position') {
            dispatch(onChangeColumnForFilterConditionValue({
                title: currentColumn.headerName,
                value: value,
                gridType: gridType
            }))
            dispatch(onSetAttrCodesCurrentPosition(value))
        } else if (currentColumn.headerName === 'Status' && gridType === 'All Properties/Upcoming') {
            const controller = getAbortController();
            controller.abort();
            dispatch(onSetUpcomingPropsCurrentStatus(value))


        } else if (currentColumn.headerName === 'status' && gridType === 'All Properties/REQ') {
            dispatch(onSetBrokeragePropsCurrentStatus(value))
        } else if (currentColumn.headerName === 'Status' && (gridType === 'Surfaces' || gridType === 'Properties')) {
            dispatch(onSetPropsAndSurfacesCurrentStatus(value))
        } else if (currentColumn.headerName === 'Type' && currentColumn.title === 'PU_TYPE') {
            dispatch(onSetTypeColumnFilterValue(value))
            dispatch(onChangeColumnForFilterValue({
                title: currentColumn.headerName,
                value: value.trim(),
                gridType: gridType
            }))
        } else if (currentColumn.headerName === 'Type') {
            dispatch(setActivitiesCurrentType(value))
            dispatch(onChangeActivitiesGridFilterValue({
                title: currentColumn.headerName,
                value: value.trim()
            }))
        } else if (currentColumn.headerName === 'Kind') {
            dispatch(setActivitiesCurrentKind(value))
            dispatch(onChangeActivitiesGridFilterValue({
                title: currentColumn.headerName,
                value: value.trim()
            }))
        } else if (gridType === 'Requirements') {
            dispatch(onChangeRequirementsGridFilterConditionValue({
                title: currentColumn.headerName,
                value: value
            }))
        } else if (gridType === 'Activities Grid') {
            dispatch(onChangeActivitiesGridFilterConditionValue({
                title: currentColumn.headerName,
                value: value
            }))
        } else if (gridType === 'Audit') {
            // audit grid
            dispatch(onChangeAuditGridFilterConditionValue({
                title: currentColumn.headerName,
                value: value
            }))
        } else if (gridType === 'Brokerage/Requirements') {
            // brokerage/reqs grid
            dispatch(onChangeBrokerageReqsGridFilterConditionValue({
                title: currentColumn.headerName,
                value: value
            }))
        } else if (gridType === 'AttrCodes' && currentColumn.headerName !== 'Domain_CD' && currentColumn.headerName !== 'Position') {
            // AttrCodes grid
            dispatch(onChangeAttrCodesGridFilterConditionValue({
                title: currentColumn.headerName,
                value: value
            }))
        } else {
            //properties grid/all propertied grid
            dispatch(onChangeColumnForFilterConditionValue({
                title: currentColumn.headerName,
                value: value,
                gridType: gridType
            }))
        }
    }

    function onDateChange(date: any, dateString: any) {
        // function onDateChange(date: any, dateString: any) {
        // console.log(value)
        // // Reformat the date if it comes in an incorrect format
        // // const [year, day, month] = String(value).split('-');
        // // const reformattedDate = `${year}-${month}-${day}`;
        // //
        // // if (!moment(reformattedDate, 'YYYY-MM-DD', true).isValid()) {
        // //     console.error("Invalid date value:", value);
        // //     return;
        // // }
        // setCalendarVisible(false)
        const dateStr  = dateString
        setSelectedDate(date)

        if (gridType === 'Requirements') {
            dispatch(onChangeRequirementsGridFilterValue({title: currentColumn.headerName, value: dateStr }));
        } else if (gridType === 'Activities Grid') {
            dispatch(onChangeActivitiesGridFilterValue({title: currentColumn.headerName, value: dateStr }));
        } else if (gridType === 'Audit') {
            dispatch(onChangeAuditGridFilterValue({title: currentColumn.headerName, value: dateStr }));
        } else if (gridType === 'Brokerage/Requirements') {
            dispatch(onChangeBrokerageReqsGridFilterValue({title: currentColumn.headerName, value: dateStr }));
        } else {
            dispatch(onChangeColumnForFilterValue({
                title: currentColumn.headerName,
                value: dateStr ,
                gridType: gridType
            }));
        }
    }

    const onSelectDateValue = (value: any) => {
        setDateCondition(value)
    }

    const onSetModalClosed = () => {
        if (!currentColumn?.isFilterSelected) {
            setInputValue('')
        }
        if (gridType === 'Requirements') {
            dispatch(onSetRequirementsGridFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: false
            }))
            dispatch(onChangeRequirementsGridFilterConditionValue({title: currentColumn.headerName, value: ''}))
        } else if (gridType === 'Activities Grid') {
            dispatch(onSetActivitiesGridFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: false
            }))

            dispatch(onChangeActivitiesGridFilterConditionValue({title: currentColumn.headerName, value: ''}))
        } else if (gridType === 'Audit') {
            // audit grid
            dispatch(onSetAuditGridFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: false
            }))
            dispatch(onChangeAuditGridFilterConditionValue({title: currentColumn.headerName, value: ''}))
        } else if (gridType === 'Brokerage/Requirements') {
            // brokerage/reqs grid
            dispatch(onSetBrokerageReqsGridFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: false
            }))
            dispatch(onChangeBrokerageReqsGridFilterConditionValue({title: currentColumn.headerName, value: ''}))
        } else if (gridType === 'AttrCodes') {
            // AttrCodes grid
            dispatch(onSetAttrCodesGridFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: false
            }))
            dispatch(onChangeAttrCodesGridFilterConditionValue({title: currentColumn.headerName, value: ''}))
        } else {
            //properties grid/all propertied grid
            dispatch(onSetFiltersButtonsModalOpened({
                headerName: currentColumn.headerName,
                isModalOpened: false,
                gridType: gridType
            }))
            dispatch(onChangeColumnForFilterConditionValue({
                title: currentColumn.headerName,
                value: '',
                gridType: gridType
            }))
            dispatch(onSetPropContactsRemarkEditMode(false))
        }
    }

    return (
        <div style={{
            minWidth: '105px',
            height: '100%',
            position: "relative"
        }}

        >
            {currentColumn?.isModalBtnOpened
                &&
                <>
                    {
                        currentColumn.headerName === 'Status' || currentColumn.headerName === 'status'
                            ?
                            <div className={`${s.btnInner__modal} propertyModal`} ref={ref}>
                                {
                                    !isMobile
                                    &&
                                    <p>Status</p>
                                }
                                <Select
                                    placeholder={'Select status'}
                                    onChange={onSelectValue}
                                    value={currentStatus !== null ? Number(currentStatus!!.trim()) === 1 ? 'Active' : Number(currentStatus!!.trim()) === 2 ? 'Passive' : 'All' : undefined}
                                    defaultValue={currentStatus !== null ? Number(currentStatus!!.trim()) === 1 ? 'Active' : Number(currentStatus!!.trim()) === 2 ? 'Passive' : 'All' : undefined}
                                    style={{
                                        marginBottom: '12px',
                                        height: '40px'
                                    }}
                                    className={`${s.select} filtersSelect`}
                                >
                                    {
                                        selectMapStatuses.map((d: { value: string, label: string }) => {
                                            if (d.value === 'default') {
                                                return (
                                                    <option
                                                        key={d.value}
                                                        value={d.value}
                                                        disabled selected
                                                    >
                                                        {d.label}
                                                    </option>
                                                )
                                            } else {

                                            }
                                            return (
                                                <option
                                                    key={d.value}
                                                    value={d.value}
                                                >
                                                    {d.label}
                                                </option>
                                            )
                                        })
                                    }
                                </Select>
                                <div className={s.buttons}>
                                    {
                                        !isMobile
                                        &&
                                        <div onClick={onSetModalClosed}>
                                            <SecondaryButton text={'Cancel'} width={'100%'} isWhiteBg={true}
                                                             height={'40px'}/>
                                        </div>
                                    }
                                    <div
                                        onClick={() => {
                                            onSaveFilter(textValue, currentStatus, currentColumn.dataType)
                                        }}
                                    >
                                        <PrimaryButton text={'Apply'} isAddButton={false} width={'100%'}
                                                       height={'40px'}/>
                                    </div>
                                </div>
                            </div>

                            :
                            currentColumn.headerName === 'Domain_CD'
                                ?
                                <div className={`${s.btnInner__modal} propertyModal`} ref={ref}>
                                    {
                                        !isMobile
                                        &&
                                        <p>Domain</p>
                                    }
                                    <Select
                                        placeholder={'Select domain'}
                                        onChange={onSelectValue}
                                        // value={currentDomain!}
                                        // defaultValue={currentDomain!}
                                        style={{
                                            marginBottom: '12px',
                                            height: '40px'
                                        }}
                                        className={`${s.select} filtersSelect`}
                                    >
                                        {
                                            domainNames.map((d: { value: string, label: string }) => {
                                                if (d.value === 'default') {
                                                    return (
                                                        <option
                                                            key={d.value}
                                                            value={d.value}
                                                            disabled selected
                                                        >
                                                            {d.label}
                                                        </option>
                                                    )
                                                } else {

                                                }
                                                return (
                                                    <option
                                                        key={d.value}
                                                        value={d.value}
                                                    >
                                                        {d.label}
                                                    </option>
                                                )
                                            })
                                        }
                                    </Select>
                                    <div className={s.buttons}>
                                        {
                                            !isMobile
                                            &&
                                            <div onClick={onSetModalClosed}>
                                                <SecondaryButton text={'Cancel'} width={'100%'} isWhiteBg={true}
                                                                 height={'40px'}/>
                                            </div>
                                        }
                                        <div
                                            onClick={() => {
                                                onSaveFilter(textValue, currentColumn.value, currentColumn.dataType)
                                            }}
                                        >
                                            <PrimaryButton text={'Apply'} isAddButton={false} width={'100%'}
                                                           height={'40px'}/>
                                        </div>
                                    </div>
                                </div>
                                :
                                currentColumn.headerName === 'Position'
                                    ?
                                    <div className={`${s.btnInner__modal} propertyModal`} ref={ref}>
                                        {
                                            !isMobile
                                            &&
                                            <p>{currentColumn.headerName.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())}</p>
                                        }
                                        <Select
                                            placeholder={'Select position'}
                                            onChange={onSelectValue}
                                            value={currentPosition!}
                                            defaultValue={currentPosition!}
                                            style={{
                                                marginBottom: '12px',
                                                height: '40px'
                                            }}
                                            className={`${s.select} filtersSelect`}
                                        >
                                            <option value="" disabled selected>Select position</option>
                                            <option
                                                value={'no position'}
                                            >
                                                {' '}
                                            </option>
                                            {
                                                agentPositions.map((d: string) => {
                                                    return (
                                                        <option
                                                            key={d}
                                                            value={d}
                                                        >
                                                            {d}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </Select>
                                        <div className={s.buttons}>
                                            {
                                                !isMobile
                                                &&
                                                <div onClick={onSetModalClosed}>
                                                    <SecondaryButton text={'Cancel'} width={'100%'} isWhiteBg={true}
                                                                     height={'40px'}/>
                                                </div>
                                            }
                                            <div
                                                onClick={() => {
                                                    onSaveFilter(textValue, currentPosition, currentColumn.dataType)
                                                }}
                                            >
                                                <PrimaryButton text={'Apply'} isAddButton={false} width={'100%'}
                                                               height={'40px'}/>
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    currentColumn.headerName === 'Kind'
                                        ?
                                        <div
                                            className={`${s.btnInner__modal} propertyModal`}
                                            ref={ref}
                                            style={{
                                                width: 'fit-content'
                                            }}
                                        >
                                            {
                                                !isMobile
                                                &&
                                                <p>Kind</p>
                                            }
                                            <Select
                                                placeholder={'Select kind'}
                                                onChange={onSelectValue}
                                                value={currentActivityKind == null ? undefined : currentActivityKind}
                                                defaultValue={currentActivityKind == null ? undefined : currentActivityKind!}
                                                style={{
                                                    marginBottom: '12px',
                                                    height: '40px'
                                                }}
                                                className={`${s.select} filtersSelect filterSelect__mxwidth`}
                                                dropdownMatchSelectWidth={false}
                                            >
                                                {
                                                    activitiesKindsValues.map((d: { value: string, label: string }) => {
                                                        return (
                                                            <option
                                                                key={d.value}
                                                                value={d.value}
                                                            >
                                                                {d.label}
                                                            </option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                            <div className={s.buttons}>
                                                {
                                                    !isMobile
                                                    &&
                                                    <div onClick={onSetModalClosed}>
                                                        <SecondaryButton text={'Cancel'} width={'100%'} isWhiteBg={true}
                                                                         height={'40px'}/>
                                                    </div>
                                                }
                                                <div
                                                    onClick={() => {
                                                        onSaveFilter(textValue, currentActivityKind, currentColumn.dataType)
                                                    }}
                                                >
                                                    <PrimaryButton text={'Apply'} isAddButton={false} width={'100%'}
                                                                   height={'40px'}/>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        currentColumn.headerName === 'Type' && currentColumn.title === 'PU_TYPE'
                                            ?
                                            <div className={`${s.btnInner__modal} propertyModal`} ref={ref}>
                                                {
                                                    !isMobile
                                                    &&
                                                    <p>Type</p>
                                                }
                                                <Select
                                                    placeholder={'Select type'}
                                                    onChange={onSelectValue}
                                                    value={surfaceTypeColumnFilterValue!}
                                                    defaultValue={surfaceTypeColumnFilterValue!}
                                                    style={{
                                                        marginBottom: '12px',
                                                        height: '40px'
                                                    }}
                                                    className={`${s.select} filtersSelect`}
                                                >
                                                    {
                                                        surfaceTypesValues.map((d: { value: string, label: string }) => {
                                                            return (
                                                                <option
                                                                    key={d.value}
                                                                    value={d.value}
                                                                >
                                                                    {d.label}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                                <div className={s.buttons}>
                                                    {
                                                        !isMobile
                                                        &&
                                                        <div onClick={onSetModalClosed}>
                                                            <SecondaryButton text={'Cancel'} width={'100%'}
                                                                             isWhiteBg={true} height={'40px'}/>
                                                        </div>
                                                    }
                                                    <div
                                                        onClick={() => {
                                                            onSaveFilter(textValue, surfaceTypeColumnFilterValue, currentColumn.dataType)
                                                        }}
                                                    >
                                                        <PrimaryButton text={'Apply'} isAddButton={false}
                                                                       width={'100%'} height={'40px'}/>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            currentColumn.headerName === 'Type'
                                                ?
                                                <div className={`${s.btnInner__modal} propertyModal`} ref={ref}>
                                                    {
                                                        !isMobile
                                                        &&
                                                        <p>Type</p>
                                                    }
                                                    <Select
                                                        placeholder={'Select type'}
                                                        onChange={onSelectValue}
                                                        value={currentActivityType == null ? undefined : currentActivityType}
                                                        defaultValue={undefined}
                                                        style={{
                                                            marginBottom: '12px',
                                                            height: '40px'
                                                        }}
                                                        className={`${s.select} filtersSelect`}
                                                        // options={activitiesTypesValues}
                                                    >
                                                        {
                                                            activitiesTypesValues.map((d: { value: string, label: string }) => {
                                                                return (
                                                                    <option
                                                                        key={d.value}
                                                                        value={d.value}
                                                                    >
                                                                        {d.label}
                                                                    </option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                    <div className={s.buttons}>
                                                        {
                                                            !isMobile
                                                            &&
                                                            <div onClick={onSetModalClosed}>
                                                                <SecondaryButton text={'Cancel'} width={'100%'}
                                                                                 isWhiteBg={true} height={'40px'}/>
                                                            </div>
                                                        }
                                                        <div
                                                            onClick={() => {
                                                                onSaveFilter(textValue, currentActivityType, currentColumn.dataType)
                                                            }}
                                                        >
                                                            <PrimaryButton text={'Apply'} isAddButton={false}
                                                                           width={'100%'}
                                                                           height={'40px'}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                currentColumn.dataType === 'class java.math.BigDecimal' ?
                                                    <div className={`${s.btnInner__modal} propertyModal`} ref={ref}>
                                                        {
                                                            !isMobile
                                                            &&
                                                            <p>{currentColumn.headerName.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase()) === 'Domain Cd'
                                                                ? 'Domain' : currentColumn.headerName.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())}</p>
                                                        }
                                                        <Select
                                                            placeholder={'Select filter type'}
                                                            onChange={onSelectValue}
                                                            defaultValue={'equals'}
                                                            value={selectedValue}
                                                            style={{
                                                                marginBottom: '12px',
                                                                height: '40px'
                                                            }}
                                                            className={`${s.select} filtersSelect`}
                                                        >
                                                            <option
                                                                key={currentColumn.headerName}
                                                                value={'less'}
                                                            >
                                                                Less than
                                                            </option>
                                                            <option
                                                                key={currentColumn.headerName}
                                                                value={'equals'}
                                                            >
                                                                Equals
                                                            </option>
                                                            <option
                                                                key={currentColumn.headerName}
                                                                value={'higher'}
                                                            >
                                                                Higher than
                                                            </option>
                                                            <option
                                                                key={currentColumn.headerName}
                                                                value={'between'}
                                                            >
                                                                Between
                                                            </option>
                                                        </Select>
                                                        {selectedValue === 'between'
                                                            ?
                                                            <>
                                                                <Input
                                                                    placeholder={`Value starts with`}
                                                                    onChange={onInputStartsChange}
                                                                    style={{
                                                                        marginBottom: '12px',
                                                                        height: '40px',
                                                                    }}
                                                                    value={currentColumn.startValue}
                                                                />
                                                                <Input
                                                                    placeholder={`Value ends with`}
                                                                    onChange={onInputEndChange}
                                                                    style={{
                                                                        marginBottom: '12px',
                                                                        height: '40px',
                                                                    }}
                                                                    value={currentColumn.endValue}
                                                                />
                                                            </>
                                                            :
                                                            <Input
                                                                placeholder={`Please enter ${currentColumn.headerName.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: any) => char.toUpperCase())}`}
                                                                onChange={onInputChange}
                                                                style={{
                                                                    marginBottom: '12px',
                                                                    height: '40px',
                                                                }}
                                                                value={currentColumn.value}
                                                            />
                                                        }
                                                        <div className={s.buttons}>
                                                            {
                                                                !isMobile
                                                                &&
                                                                <div onClick={onSetModalClosed}>
                                                                    <SecondaryButton text={'Cancel'} width={'100%'}
                                                                                     isWhiteBg={true} height={'40px'}/>
                                                                </div>
                                                            }
                                                            <div
                                                                onClick={() => {
                                                                    onSaveFilter(textValue, currentColumn.value, currentColumn.dataType, currentColumn.startValue, currentColumn.endValue)
                                                                }}
                                                            >
                                                                <PrimaryButton text={'Apply'} isAddButton={false}
                                                                               width={'100%'} height={'40px'}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    currentColumn.dataType === 'class java.lang.String'
                                                        ?
                                                        <div className={`${s.btnInner__modal} propertyModal`} ref={ref}>
                                                            {
                                                                !isMobile
                                                                &&
                                                                <p>{currentColumn.headerName === 'activity_existed'
                                                                    ? 'ACTIVITY'.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: any) => char.toUpperCase())
                                                                    : currentColumn.headerName.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase()) === 'Domain Cd'
                                                                        ? 'Domain' : currentColumn.headerName.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())}</p>
                                                            }
                                                            {
                                                                currentColumn.headerName === 'Person Remarks'
                                                                ?
                                                                    <TextArea
                                                                        placeholder={`Please enter ${currentColumn.headerName === 'activity_existed'
                                                                            ? 'ACTIVITY'.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: any) => char.toUpperCase())
                                                                            : currentColumn.headerName.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase()) === 'Domain Cd'
                                                                                ? 'Domain' : currentColumn.headerName.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())}`}
                                                                        onChange={onInputChange}
                                                                        style={{
                                                                            marginBottom: '12px',
                                                                            minHeight: '40px',
                                                                            minWidth: '300px'
                                                                        }}
                                                                        value={currentColumn.value}
                                                                        // autoSize={{ minRows: 1, maxRows: 12 }}
                                                                    />
                                                                    :
                                                                    <Input
                                                                        placeholder={`Please enter ${currentColumn.headerName === 'activity_existed'
                                                                            ? 'ACTIVITY'.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: any) => char.toUpperCase())
                                                                            : currentColumn.headerName.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase()) === 'Domain Cd'
                                                                                ? 'Domain' : currentColumn.headerName.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())}`}
                                                                        onChange={onInputChange}
                                                                        style={{
                                                                            marginBottom: '12px',
                                                                            height: '40px',
                                                                            // minWidth: dynamicInputWidth > 700 ? 670 : dynamicInputWidth,
                                                                            // maxWidth: '700px'
                                                                        }}
                                                                        value={currentColumn.value}

                                                                    />

                                                            }

                                                            <div className={s.buttons}>
                                                                {
                                                                    !isMobile
                                                                    &&
                                                                    <div onClick={onSetModalClosed}>
                                                                        <SecondaryButton text={'Cancel'} width={'100%'}
                                                                                         isWhiteBg={true}
                                                                                         height={'40px'}/>
                                                                    </div>
                                                                }
                                                                <div
                                                                    onClick={() => {
                                                                        onSaveFilter(textValue, currentColumn.value, currentColumn.dataType)
                                                                    }}
                                                                >
                                                                    <PrimaryButton text={'Apply'} isAddButton={false}
                                                                                   width={'100%'} height={'40px'}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :

                                                        currentColumn.dataType === 'class java.time.LocalDateTime' || currentColumn.dataType === "class oracle.sql.TIMESTAMP"
                                                            ?
                                                            <div className={`${s.btnInner__modal} propertyModal`}
                                                                 ref={ref}>
                                                                {
                                                                    !isMobile
                                                                    &&
                                                                    <p>{currentColumn.headerName.toLowerCase().replace(/_/g, ' ').replace(/\b\w/g, (char: string) => char.toUpperCase())}</p>
                                                                }
                                                                <Select
                                                                    placeholder={'Select filter type'}
                                                                    onChange={onSelectDateValue}
                                                                    defaultValue={'equals'}
                                                                    value={dateCondition}
                                                                    style={{
                                                                        marginBottom: '12px',
                                                                        height: '40px'
                                                                    }}
                                                                    className={`${s.select} filtersSelect`}
                                                                >
                                                                    <option
                                                                        key={'before'}
                                                                        value={'before'}
                                                                    >
                                                                        Before selected time
                                                                    </option>
                                                                    <option
                                                                        key={'onDate'}
                                                                        value={'onDate'}
                                                                    >
                                                                        On selected time
                                                                    </option>
                                                                    <option
                                                                        key={'after'}
                                                                        value={'after'}
                                                                    >
                                                                        After selected time
                                                                    </option>
                                                                </Select>
                                                                {/*// @ts-ignore*/}
                                                                <DatePicker
                                                                    placeholder={'Select date'}
                                                                    style={{
                                                                        marginBottom: '12px'
                                                                    }}
                                                                    onChange={onDateChange}
                                                                />
                                                                {/*<div style={{ position: 'relative', display: 'inline-block' }}>*/}
                                                                {/*    <Input*/}
                                                                {/*        value={currentColumn.value}*/}
                                                                {/*        placeholder="Select date"*/}
                                                                {/*        onClick={toggleCalendar}*/}
                                                                {/*        suffix={<CalendarOutlined onClick={toggleCalendar} />}*/}
                                                                {/*        readOnly*/}
                                                                {/*    />*/}
                                                                {/*    {isCalendarVisible && (*/}
                                                                {/*        <div style={{ position: 'absolute', zIndex: 1000 }}>*/}
                                                                {/*            <InfiniteCalendar*/}
                                                                {/*                selected={selectedDate}*/}
                                                                {/*                onSelect={onDateChange}*/}
                                                                {/*                width={300}*/}
                                                                {/*                height={180}*/}
                                                                {/*                theme={{*/}
                                                                {/*                    selectionColor: '#1890ff',*/}
                                                                {/*                    textColor: {*/}
                                                                {/*                        default: '#333',*/}
                                                                {/*                        active: '#fff',*/}
                                                                {/*                    },*/}
                                                                {/*                    weekdayColor: '#f0f0f0',*/}
                                                                {/*                    headerColor: '#1890ff',*/}
                                                                {/*                    floatingNav: {*/}
                                                                {/*                        background: '#1890ff',*/}
                                                                {/*                        color: '#fff',*/}
                                                                {/*                        chevron: '#fff',*/}
                                                                {/*                    },*/}
                                                                {/*                    todayColor: '#52c41a',*/}
                                                                {/*                }}*/}
                                                                {/*                locale={{*/}
                                                                {/*                    weekdays: ["S", "M", "T", "W", "T", "F", "S"],*/}
                                                                {/*                }}*/}
                                                                {/*                displayOptions={{*/}
                                                                {/*                    showTodayHelper: false*/}
                                                                {/*                }}*/}
                                                                {/*            />*/}
                                                                {/*        </div>*/}
                                                                {/*    )}*/}
                                                                {/*</div>*/}
                                                                <div className={s.buttons}>
                                                                    {
                                                                        !isMobile
                                                                        &&
                                                                        <div onClick={onSetModalClosed}>
                                                                            <SecondaryButton text={'Cancel'}
                                                                                             width={'100%'}
                                                                                             isWhiteBg={true}
                                                                                             height={'40px'}/>
                                                                        </div>
                                                                    }
                                                                    <div
                                                                        onClick={() => {
                                                                            onSaveFilter(textValue, currentColumn.value, currentColumn.dataType)
                                                                        }}
                                                                    >
                                                                        <PrimaryButton text={'Apply'}
                                                                                       isAddButton={false}
                                                                                       width={'100%'} height={'40px'}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                    }
                </>
            }
        </div>
    );
};


export default PropertyFilterModal;

