import {Collapse, Empty, Input} from 'antd';
import React, {useEffect, useState} from 'react';
import PageWithSidebar from "../PageWithSidebar/PageWithSidebar";
import {Tab, Tabs, Typography} from '@mui/material';
import Box from "@mui/material/Box";
import {CaretRightOutlined} from '@ant-design/icons';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    FaqsType,
    GetFaqsThunk,
    onSetFaqs,
    SearchFaqsThunk,
    selectFaqsArray,
    selectIsFaqsLoading
} from "../../store/parametersReducer";
import s from './FAQs.module.scss'
import searchIcon from "../../img/icons/searchTable.svg"
import {Lottie} from "@crello/react-lottie";
import animatedLoader from "../../img/Loader.json";
import {FacebookCircularProgress} from "../AllPropertiesAddressForm/AllPropertiesAddressForm";

const {Panel} = Collapse;


const FaQs = () => {
    return (
        <PageWithSidebar>
            <FaQsPageComponent/>
        </PageWithSidebar>
    )
}

const FaQsPageComponent = () => {
    const [tab, setTab] = React.useState(0);
    const dispatch = useAppDispatch()
    const faqs = useAppSelector(selectFaqsArray)
    const [currentQuestion, setCurrentQuestion] = useState('')
    const isFaqsLoading = useAppSelector(selectIsFaqsLoading)

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue)
    };

    useEffect(() => {
        dispatch(GetFaqsThunk({category: tab === 0 ? 'Admin' : 'User'}))
            .then(() => {
                if (currentQuestion !== '') {
                    setTimeout(() => {
                        dispatch(SearchFaqsThunk({activity_ref: 0, question: currentQuestion, withLoader: true}))
                    }, 50)

                }
            })
    }, [dispatch, tab])


    const getItems = (data: FaqsType[], panelStyle: any) => {
        return data.map((item, index) => ({
            key: item.id.toString(),
            label: `${item.question}`,
            children: <p>{item.answer}</p>,
            style: panelStyle,
        }));
    };

    const panelStyle = {
        marginBottom: 24,
        background: '',
        borderRadius: '',
        border: 'none',
    };

    const items = getItems(faqs, panelStyle);

    const onSearchQuestions = (question: string) => {
        dispatch(SearchFaqsThunk({activity_ref: 0, question: question, withLoader: false}))
        setCurrentQuestion(question)
    }

    return (
        <div>
            <div className={s.header}>
                <h1 className={s.header__title}>Frequently Asked Questions</h1>
                <h2 className={s.header__subtitle}>Have any questions?</h2>
                <div>
                    <Input
                        className={s.header__search}
                        placeholder={'Type any question here'}
                        onChange={(e) => onSearchQuestions(e.target.value)}
                        suffix={<img src={searchIcon} alt="search"/>}
                        allowClear
                    />
                </div>
            </div>
            <Tabs
                value={tab}
                onChange={handleChangeTab}
                sx={{
                    marginBottom: '20px',
                    marginTop: '40px',
                    '& .MuiTabs-flexContainer': {
                        justifyContent: 'center',
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#0707FA',
                    },
                    '& .MuiButtonBase-root.MuiTab-root': {
                        minWidth: '65px'
                    }
                }}
            >
                <Tab
                    label="Admin"
                    sx={{
                        color: '#667085',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '20px',
                        textTransform: 'inherit',
                        padding: '0 0px 10px 0px',
                        width: 'fit-content',
                        '&.Mui-selected': {
                            color: '#0707FA',
                            fontWeight: 500,
                            width: 'fit-content',
                        },
                    }}
                />
                <Tab
                    label="User"
                    sx={{
                        color: '#667085',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '20px',
                        textTransform: 'inherit',
                        padding: '0 0px 10px 0px',
                        width: 'fit-content',
                        '&.Mui-selected': {
                            color: '#0707FA',
                            fontWeight: 500,
                            width: 'fit-content',
                        },

                    }}
                />
            </Tabs>
            <TabPanel value={tab} index={0} sx={{height: '100%'}}>
                {
                    isFaqsLoading
                        ?
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: 'rgba(255, 255, 255, 0.8)',
                                zIndex: 1000,
                            }}
                        >
                            <FacebookCircularProgress/>
                        </div>
                        :
                        faqs.length
                            ?
                            <div>
                                <Collapse
                                    bordered={false}
                                    defaultActiveKey={['1']}
                                    expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 270 : 90}
                                                                                    size={14}/>}

                                >
                                    {items.map(item => (
                                        <Panel
                                            header={item.label}
                                            key={item.key}
                                            className={'FaqPanel'}
                                            style={{
                                                borderRadius: '4px',
                                                background: '#FFF',
                                                boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
                                                marginBottom: '16px',
                                                flexDirection: 'row-reverse',
                                            }}>
                                            {item.children}
                                        </Panel>
                                    ))}
                                </Collapse>
                            </div>
                            :
                            <div style={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <Empty/>
                            </div>

                }
            </TabPanel>
            <TabPanel value={tab} index={1} sx={{height: '100%'}}>
                {
                    isFaqsLoading
                        ?
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: 'rgba(255, 255, 255, 0.8)',
                                zIndex: 1000,
                            }}
                        >
                            <FacebookCircularProgress/>
                        </div>
                        :
                        faqs.length
                            ?
                            <div>
                                <Collapse
                                    bordered={false}
                                    defaultActiveKey={['2']}
                                    expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 270 : 90}
                                                                                    size={14}/>}

                                >
                                    {items.map(item => (
                                        <Panel
                                            header={item.label}
                                            key={item.key}
                                            className={'FaqPanel'}
                                            style={{
                                                borderRadius: '4px',
                                                background: '#FFF',
                                                boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
                                                marginBottom: '16px',
                                                flexDirection: 'row-reverse',
                                            }}>
                                            {item.children}
                                        </Panel>
                                    ))}
                                </Collapse>
                            </div>
                            :
                            <div style={{
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <Empty/>
                            </div>
                }

            </TabPanel>

        </div>
    );
};


function TabPanel(props: any) {
    const {children, value, index, ...other} = props;
    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            sx={{
                width: '77%',
                margin: '0 auto',
                height: '50vh'
            }}
        >
            <Box sx={{
                height: '100%'
            }}>{children}</Box>
        </Typography>
    );
}

export default FaQs;